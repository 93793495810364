"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ShipmentItem = void 0;
//TODO Наследовать от Model
//TODO Добавить конвертацию из json
var ShipmentItem = /** @class */function () {
  function ShipmentItem(id, bagId) {
    this.id = id;
    this.bagId = bagId;
  }
  ShipmentItem.prototype.getId = function () {
    return this.id;
  };
  ShipmentItem.prototype.getBagId = function () {
    return this.bagId;
  };
  return ShipmentItem;
}();
exports.ShipmentItem = ShipmentItem;