import React from "react";
import PropTypes from "prop-types";
import HeaderComponent from "../components/header/HeaderComponent";

const PageTemplate = ({ children } : any) => {
  return (
    <div>
      <HeaderComponent />
      <div className="container">{children}</div>
    </div>
  );
};

PageTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
export default PageTemplate;
