import React from "react";
import {Growl} from "primereact/growl";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import PartnerPicker from "./partials/PartnerPicker";
import {
    AuthorizedRequestData,
    Bind,
    CountableResponse, deleteUserRequest,
    ICountableResponse,
    IPartner, Logger, postUserRequest,
    requestPartners, resetAccountPass, UserRequestData
} from "@shift-mono/common";
import ContentLoaderComponent from "../../../common/ContentLoaderComponent";
import {getAccessToken} from "../../../../helpers/RequestHelpers";
import {connect} from "react-redux";
import {InputMask} from "primereact/inputmask";
import {Checkbox} from "primereact/checkbox";

interface IAddAccountComponentProps {
    getToken: () => Promise<string>;
}

interface IAddAccountComponentState {
    login: string;
    password: string;
    phone: string;
    email: string;
    currentPartnerId: string | undefined;

    firstName: string;
    secondName: string;
    thirdName: string;

    isB2B: boolean;

    availablePartners: IPartner[];
}

class AddAccountComponent extends React.Component<IAddAccountComponentProps, IAddAccountComponentState> {
    growl: any = undefined;
    defaultPhone: string = "+7(___) ___-____";

    defaultState = {
        login: this.defaultPhone,
        password: "",
        phone: this.defaultPhone,
        email: "",

        firstName: "",
        secondName: "",
        thirdName: "",

        isB2B: false,

        currentPartnerId: undefined,
    }


    constructor(props: IAddAccountComponentProps) {
        super(props);

        this.state = {
            ...this.defaultState,
            availablePartners: [],
        }
    }

    saveButtonHandler() {
        this.saveButtonRequest();
    }

    @Bind()
    async saveButtonRequest() {
        try {
            const token = await this.props.getToken();

            if (!this.validateForm()) {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Не все поля заполнены!"
                });
                return;
            }

            const phoneNumberFromState = this.state.phone.trim() !== "" ? this.state.phone : "";
            const email = this.state.email.trim() !== "" ? this.state.email : undefined;
            const firstName = this.state.firstName.trim() !== "" ? this.state.firstName : undefined;
            const secondName = this.state.secondName.trim() !== "" ? this.state.secondName : undefined;
            const thirdName = this.state.thirdName.trim() !== "" ? this.state.thirdName : undefined;

            const login = this.state.isB2B
                ? this.state.login
                : this.state.login.replace(/[ _()-]/g, "")

            const phoneRegex = /\+7[0-9]{10}/;
            let phone: string | undefined = phoneNumberFromState.replace(/[ _()-]/g, "");
            phone = phoneRegex.exec(phone) ? phone : undefined;

            const requestData = new UserRequestData({
                accessToken: token,
                login: login,
                phoneNumber: phone,
                email: email,
                firstName: firstName,
                secondName: secondName,
                thirdName: thirdName,
                partnerId: this.state.currentPartnerId,
                isB2bAccount: this.state.isB2B
            });

            const accountId = await postUserRequest(requestData);
            if (accountId) {
                const reqData = new AuthorizedRequestData(token);
                const passwordAdded =await resetAccountPass(accountId as string, this.state.password, reqData)
                if (passwordAdded) {
                    this.growl.show({
                        severity: "success",
                        summary: "Успешно",
                        detail: "Аккаунт добавлен"
                    });

                    this.clearForm();
                } else {
                    await deleteUserRequest(accountId as string, reqData)
                    this.growl.show({
                        severity: "error",
                        summary: "Ошибка",
                        detail: "Ошибка создания"
                    });
                }


            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Ошибка создания"
                });
            }
        } catch (err) {
            Logger.d(err);
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка создания"
            });
        }
    }

    @Bind()
    clearForm() {
        this.setState({
            ...this.defaultState
        });
    }

    @Bind()
    clearLogin(isB2B?: boolean) {
        let defaultLogin = "";
        if (!isB2B) {
            defaultLogin = this.state.isB2B ? "" : this.defaultPhone;
        } else {
            defaultLogin = isB2B ? "" : this.defaultPhone;
        }
        this.setState({
            login: defaultLogin
        })
    }

    validateForm() {
        if (this.state.login.trim() === "") {
            return false;
        }

        const phoneRegex = /\+7[0-9]{10}/;
        const login = this.state.login.replace(/[ _()-]/g, "");
        if (!this.state.isB2B && !(phoneRegex.exec(login))) {
            return false;
        }

        if (this.state.password.trim() === "") {
            return false;
        }

        return true;
    }

    @Bind()
    async partnersRequest(): Promise<ICountableResponse<IPartner[]>> {
        const token = await this.props.getToken();
        const requestData = new AuthorizedRequestData(token);

        try {
            return requestPartners(requestData);
        } catch (err) {
            return new CountableResponse([], 0);
        }
    }

    @Bind()
    partnersRequestResult(partners: ICountableResponse<IPartner[]>) {
        this.setState({
            availablePartners: partners.getData()
        })
    }

    renderPartnerPicker() {
        return <>
            <div className="row m-1">
                <label className="col-lg-4">B2B аккаунт</label>
                <Checkbox
                    className="col-lg-4 mt-1"
                    onChange={e => {
                        this.setState({isB2B: e.checked})
                        this.clearLogin(e.checked)
                    }}
                    checked={this.state.isB2B}
                />
            </div>
            {!this.state.isB2B ? <></> :
                <div className="row m-1">
                    <label className="col-lg-4">Партнер:</label>
                    <PartnerPicker
                        className="col-lg-4 ml-3 mr-3"
                        currentPartner={
                            this.state.availablePartners
                                .filter((agent) => {
                                    return agent.getId() === this.state.currentPartnerId
                                })
                                .pop()
                        }
                        availablePartners={this.state.availablePartners}
                        currentPartnerId={this.state.currentPartnerId}
                        changeHandler={(partner) => {
                            this.setState({
                                currentPartnerId: partner.hasOwnProperty('id') ? undefined : partner!.getId(),
                            })
                        }}/>
                </div>
            }
        </>
    }

    renderUserFullName() {
        return <>
            <div className="row m-1">
                <label className="col-lg-4">Имя</label>
                <InputText
                    className="col-lg-4 ml-3 mr-3"
                    value={this.state.firstName}
                    placeholder="Имя"
                    onChange={(e: any) =>
                        this.setState({firstName: e.target.value})
                    }
                />
            </div>
            <div className="row m-1">
                <label className="col-lg-4">Фамилия</label>
                <InputText
                    className="col-lg-4 ml-3 mr-3"
                    value={this.state.secondName}
                    placeholder="Фамилия"
                    onChange={(e: any) =>
                        this.setState({secondName: e.target.value})
                    }
                />
            </div>
            <div className="row m-1">
                <label className="col-lg-4">Отчество</label>
                <InputText
                    className="col-lg-4 ml-3 mr-3"
                    value={this.state.thirdName}
                    placeholder="Отчество"
                    onChange={(e: any) =>
                        this.setState({thirdName: e.target.value})
                    }
                />
            </div>
        </>
    }

    render() {
        return <>
            <Growl ref={el => (this.growl = el)}/>
            <ContentLoaderComponent<ICountableResponse<IPartner[]>>
                contentRequest={this.partnersRequest}
                resultCallback={this.partnersRequestResult}
            >
                <form
                    className="card col-12 mt-2 p-2"
                    onSubmit={(e: any) => {
                        e.preventDefault();
                    }}
                >
                    <div className="row m-1">
                        <label className="col-lg-4">Логин*</label>
                        {this.state.isB2B ?
                            <InputText
                                className="col-lg-4 ml-3 mr-3"
                                value={this.state.login}
                                placeholder="Логин"
                                onChange={(e: any) => this.setState({login: e.target.value})}
                                name="login"
                            />
                            : <InputMask
                                className="col-lg-4 ml-3 mr-3"
                                value={this.state.login}
                                mask="+7(999) 999-9999"
                                placeholder="Логин"
                                onChange={(e: any) => {
                                    this.setState({login: e.target.value})
                                }
                                }
                                name="login"
                            />
                        }

                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Пароль*</label>
                        <InputText
                            className="col-lg-4 ml-3 mr-3"
                            value={this.state.password}
                            placeholder="Пароль"
                            onChange={(e: any) =>
                                this.setState({password: e.target.value})
                            }
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Телефон</label>
                        <InputMask
                            className="col-lg-4 ml-3 mr-3"
                            placeholder="Телефон"
                            mask="+7(999) 999-9999"
                            value={this.state.phone}
                            onChange={
                                (e) => this.setState({phone: e.value})
                            }
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Email</label>
                        <InputText
                            className="col-lg-4 ml-3 mr-3"
                            value={this.state.email}
                            placeholder="Email"
                            keyfilter={"email"}
                            onChange={(e: any) =>
                                this.setState({email: e.target.value})
                            }
                        />
                    </div>
                    {this.renderUserFullName()}
                    {this.renderPartnerPicker()}
                    <div className="row m-1">
                        <div className="col-12">
                            <p>* - Обязательные поля</p>
                        </div>
                    </div>
                    <div className="row m-1 ml-2">
                        <Button
                            label={"Добавить"}
                            onClick={() => this.saveButtonHandler()}
                        />
                    </div>
                </form>
            </ContentLoaderComponent>
        </>
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(AddAccountComponent);