export const returnOrderStatus = (status: string) => {
  let orderStatusRus: string = "";
  switch (status) {
    case "Payed":
      orderStatusRus = "Оплачен";
      break;
    case "New":
      orderStatusRus = "Оплачен";
      break;
    case "Pending":
      orderStatusRus = "Заказ ожидает оплаты";
      break;
    case "CourierDelivery":
      orderStatusRus = "Ожидает сдачи багажа";
      break;
    case "TransferredDelivery":
      orderStatusRus = "Передано в доставку";
      break;
    case "WaitingDelivery":
      orderStatusRus = "Ожидает вручения";
      break;
    case "Complete":
      orderStatusRus = "Завершен";
      break;
    case "Issued":
      orderStatusRus = "Неизвестен";
      break;
    case "Cancelled":
      orderStatusRus = "Отменен";
      break;
    case "ErrorPayments":
      orderStatusRus = "Ошибка платежа";
      break;
    case "Storage":
      orderStatusRus = "Хранение";
      break;
    case "Processing":
      orderStatusRus = "Обрабатывается";
      break;
  }
  return orderStatusRus;
};
export const returnUserName = (user: any) => {
  if (user && user !== undefined && user.firstName !== undefined) {
    return {
      userName: user.firstName + " " + user.secondName + " " + user.thirdName,
      phoneNumber: user.phoneNumber,
    };
  } else {
    return { userName: "Нет данных", phoneNumber: "Нет данных" };
  }
};
