import React, { useMemo, useState } from "react";
import RouteURLs from "../../../actions/routesURL";
import ContentLoaderComponent from "../../common/ContentLoaderComponent";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { DataView } from "primereact/dataview";
import { getAccessToken } from "../../../helpers/RequestHelpers";
import "./NewsListComponentStyle.scss";
import {
  requestNews,
  INews,
  CountableResponse,
  ICountableResponse,
  AuthorizedRequestData,
} from "@shift-mono/common";
import NewsFilterComponent from "./NewsFilterComponent";
import useQuery from "../../../hooks/useQuery";

interface INewsListComponentProps {
  getToken: () => Promise<string>;
}

function NewsListComponent(props: INewsListComponentProps) {
  const [news, setNews] = useState<INews[]>([]);
  const [newsCount, setNewsCount] = useState<number>(0);
  const paginationListRows:number = 10;
  const dataViewLayout:string = "list";

  const query = useQuery();
  const queryObject: any = query.getObject();
  const checkPage = (object: { page: any }) => {
    switch (object?.page) {
      case 1:
      case undefined:
        return 0;

      default:
        return (object?.page - 1) * 10;
    }
  };
  const checkTitle = (object: { title: any }) => {
    return object?.title??"";
  };
  const checkCreatedOnTo = (object: { createdOnTo: any }) => {
    return object?.createdOnTo;
  };
  const checkCreatedOnFrom = (object: { createdOnFrom: any }) => {
    return object?.createdOnFrom;
  };
  const checkSortDate = (object: { sortDate: any }) => {
    return object?.sortDate??"desc";
  };
  const queryPage = useMemo(() => checkPage(queryObject), [queryObject]);
  const [firstPaginationIndex, setFirstPaginationIndex] =
    useState<number>(queryPage);
  const title = useMemo(() => checkTitle(queryObject), [queryObject]);
  const createdOnTo = useMemo(() => checkCreatedOnTo(queryObject), [queryObject]);
  const createdOnFrom = useMemo(() => checkCreatedOnFrom(queryObject), [queryObject]);
  const sortDate = useMemo(() => checkSortDate(queryObject), [queryObject]);

  const getNewsRequest = async () => {
    const token = await props.getToken();

    try {
      const requestData = new AuthorizedRequestData(token);
      return await requestNews(requestData);
    } catch (err) {
      return new CountableResponse<INews[]>([], 0);
    }
  };

  const newsRequestResult = (news: ICountableResponse<INews[]>) => {
    setNews(news.getData());
    setNewsCount(news.getObjectsCount());
  };

  const itemTemplate = (newsData: INews, layout: string) => {
    if (!newsData) {
      return <></>;
    }
    if (layout === "list") {
      const maxTitleLength = 200;
      const maxContentLength = 800;
      return (
        <div className="row m-1 news-list-item">
          <div className="d-lg-block col-lg-3 d-flex justify-content-center">
            <img
              src={newsData.getImageUrl()}
              alt=""
              className={"news-list-item__image"}
            />
          </div>
          <div className="col-lg-9">
            <p>
              <b>Заглавие: </b>
              {newsData.getTitle().substring(0, maxTitleLength)}
            </p>
            <p>
              <b>Описание: </b>
              {newsData.getContent().substring(0, maxContentLength)}
            </p>
            <p>
              <b>Теги: </b>
              {newsData.getTags().reduce((result, currentItem) => {
                return (result += currentItem + ", ");
              }, "")}
            </p>
            <Link to={RouteURLs.news + "/" + newsData.getId()}>
              <Button
                className="news-list-item__detail-button"
                label="Подробнее"
              />
            </Link>
          </div>
        </div>
      );
    }
    if (layout === "grid") {
      return (
        <div className="p-col-12 p-md-3">
          <div>{newsData.getTitle()}</div>
        </div>
      );
    }
  };

  const filterNews: any = async (value?: any) => {
    if (value) {
      Object.keys(value).forEach((key:any) => {
        query.delete(key);
      });
      Object.keys(value).forEach(function(key:any){
        if (!["", null].includes(value[key])) {
          query.set(key, value[key]);
        }
      });
      window.history.pushState(null, "", `?${query.toString()}`);
    }
    const startIndex = firstPaginationIndex;
    const endIndex = paginationListRows;
    const token = await props.getToken();
    const request_data = value
      ? new AuthorizedRequestData(
          token,
          {},
          {
            sortDate:sortDate,
            ...Object.keys(value).reduce((acc: any, key: string): Object => {
              if (["", null].includes(value[key]) || key==='page') {
                return acc;
              }

              acc[key] = value[key];
              return acc;
            }, {}),
             skip: startIndex,
            limit: endIndex,
          }
        )
      : new AuthorizedRequestData(
          token,
          {},
          {
            title: title,
          }
        );
    try {
      const newsResponse = await requestNews(request_data);
      setFirstPaginationIndex(startIndex);
      setNews(newsResponse.getData());
      setNewsCount(newsResponse.getObjectsCount());
    } catch (err) {
      return new CountableResponse<INews[]>([], 0);
    }
  };

  const onPage = async (event: any) => {

    const startIndex = event.first;
    query.set("page", event.originalEvent.page+1);
    window.history.pushState(null, "", `?${query.toString()}`);

    const endIndex = paginationListRows;

    const token = await props.getToken();
    const request_data = new AuthorizedRequestData(
      token,
      {},
      {
        ...Object.keys(queryObject).reduce((acc: any, key: string): Object => {
          if (["", null].includes(queryObject[key]) || key==='page') {
            return acc;
          }

          acc[key] = queryObject[key];
          return acc;
        }, {}),
        skip: startIndex,
        limit: endIndex,
      }
    );
    try {
      const newsResponse = await requestNews(request_data);
      setFirstPaginationIndex(startIndex);
      setNews(newsResponse.getData());
      setNewsCount(newsResponse.getObjectsCount());
    } catch (err) {
      return new CountableResponse<INews[]>([], 0);
    }
  };

  return (
    <>
      <ContentLoaderComponent<ICountableResponse<INews[]>>
        contentRequest={true ? () => filterNews(queryObject) : getNewsRequest}
        resultCallback={true ? () => {} : newsRequestResult}
      >
        <div className="row">
          <div className="col-lg-12">
            <h3>Новости</h3>
          </div>
        </div>
        <NewsFilterComponent
          sortDate={sortDate}
          title={title}
          createdOnTo={createdOnTo}
          createdOnFrom={createdOnFrom}
          callbackHandler={(value) => {
            filterNews(value);
          }}
        />
        <div className="row">
          <div className="col-lg-12 mb-4">
            <DataView
              value={news}
              layout={dataViewLayout}
              itemTemplate={itemTemplate}
              paginator={true}
              totalRecords={newsCount}
              rows={paginationListRows}
              first={firstPaginationIndex}
              lazy={true}
              onPage={onPage}
            />
          </div>
        </div>
      </ContentLoaderComponent>
    </>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getToken: () => {
      return dispatch(getAccessToken());
    },
  };
};

export default connect(null, mapDispatchToProps)(NewsListComponent);
