"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WrongArgumentError = void 0;
var WrongArgumentError = /** @class */function (_super) {
  __extends(WrongArgumentError, _super);
  function WrongArgumentError(message) {
    var _newTarget = this.constructor;
    var _this = _super.call(this, message) || this;
    Object.setPrototypeOf(_this, _newTarget.prototype);
    _this.name = WrongArgumentError.name;
    return _this;
  }
  return WrongArgumentError;
}(Error);
exports.WrongArgumentError = WrongArgumentError;