import React from "react";
import { Dropdown } from "primereact/dropdown";
import { Bind } from "@shift-mono/common";

interface IDropDownItem {
    title: string;
    value: boolean | undefined;
}

interface IClientStatusDropdownProps {
    statusValue: boolean | undefined;
    changeStatusHandler: (status: boolean | undefined) => void;
    strictMode?: boolean;
}

interface IClientStatusDropdownState {
    currentStatusItem: any
}

export default class ClientStatusDropdown extends React.Component<IClientStatusDropdownProps, IClientStatusDropdownState> {
    constructor(props: IClientStatusDropdownProps) {
        super(props)
        this.state = {
            currentStatusItem: props.statusValue
        }
    }
    getClientStatusDropdownOptions(): IDropDownItem[] {
        const result: IDropDownItem[] = [
            { title: "Агент", value: true },
            { title: "Пользователь", value: false },
        ];

        if (!this.props.strictMode) {
            result.push({ title: "Все", value: undefined });
        }

        return result;
    }
    @Bind()
    changeClientStatusHandler(e: any) {
        this.props.changeStatusHandler(e.value);
    }
    componentWillReceiveProps(nextProps: Readonly<IClientStatusDropdownProps>, nextContext: any): void {
        if (nextProps.statusValue !== this.state.currentStatusItem) {
            this.setState({ currentStatusItem: nextProps.statusValue })
        }
    }
    render() {
        return <Dropdown
        className={"col-lg-5"}
        value={this.state.currentStatusItem}
        options={this.getClientStatusDropdownOptions()}
        onChange={(e) => {
            this.props.changeStatusHandler(e.value);
        }}
        optionLabel="title"
    />
    }
}