import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type EnhancementURLSearchParamsType = URLSearchParams & { getObject: Function };

class EnhancementURLSearchParams extends URLSearchParams {
  getObject() {
    const queryObject: any = {};
    this.forEach(function (value, key) {
      queryObject[key] = value;
    });
    return queryObject;
  }
}

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(
    (): EnhancementURLSearchParamsType => new EnhancementURLSearchParams(search),
    [search]
  );
};

export default useQuery;
