import React from "react";
import DestinationListComponent from "./DestinationListComponent";
import AddDestinationFormComponent from "./AddDestinationFormComponent";
import TabMenuComponent, {ITabItem} from "../../common/TabMenu/TabMenuComponent";
import DirectionComponent from "../direction/DirectionComponent";
import DestinationImportComponent from "./DirectionsImportComponent";

interface IDestinationComponentProps {}
interface IDestinationComponentState {
  items: ITabItem[];
}

export default class DestinationComponent extends React.Component<
  IDestinationComponentProps,
  IDestinationComponentState
> {
  constructor(props: IDestinationComponentProps) {
    super(props);
    this.state = {
      items: [
        {
          id: 1,
          label: "Города",
          component: this.renderDestinationList(),
        },
        {
          id: 2,
          label: "Направления",
          component: <DirectionComponent/>,
        },
        {
          id: 3,
          label: "Импорт направлений",
          component: <DestinationImportComponent/>,
        }
      ],
    };
  }

  renderDestinationList() {
    return (
        <>
          <div className="row">
            <div className="col-lg-6">
              <DestinationListComponent />
            </div>
            <div className="col-lg-6">
              <AddDestinationFormComponent />
            </div>
          </div>
        </>
    );
  }

  render() {
    return (<>
      <TabMenuComponent
          items={this.state.items}
      />
    </>)
  }
}
