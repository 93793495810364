import React from "react";
import {Bind, IPartner} from "@shift-mono/common";
import {Dropdown} from "primereact/dropdown";

interface IPartnerPickerProps extends React.HTMLAttributes<HTMLDivElement> {
    availablePartners: IPartner[],
    changeHandler: (selectedPartner: any ) => void;
    currentPartnerId?: string;
    currentPartner:IPartner | undefined
}

interface IPartnerPickerState {
    currentPartnerItem:any
}

interface DropdownItem {
    title: string,
    value: IPartner | undefined,
    id: string,
}

export default class PartnerPicker extends React.Component<IPartnerPickerProps, IPartnerPickerState> {
    constructor(props: IPartnerPickerProps) {
        super(props)
        this.state = {
            currentPartnerItem: props.currentPartner
        }
    }
    @Bind()
    getOptions(): DropdownItem[] {
        return [...this.props.availablePartners, undefined]
            .map((item) => {
                return this.getOptionItemFor(item)
            })
    }

    getOptionItemFor(partner: IPartner | undefined) {
        return partner
            ? {title: partner.getTitle(), value: partner, id: partner.getId()}
            : {title: "Нет партнера", value: partner, id: "-1"}
    }
    componentWillReceiveProps(nextProps: Readonly<IPartnerPickerProps>, nextContext: any): void {
        if (nextProps.currentPartner !== this.state.currentPartnerItem) {
            this.setState({ currentPartnerItem: nextProps.currentPartner })
        }
    }
    render() {
        return <Dropdown
            className={this.props.className ? this.props.className : ""}
            optionLabel={"title"}
            value={this.state.currentPartnerItem??{title: "Нет партнера", value: undefined, id: "-1"}}
            options={this.getOptions()}
            onChange={(e) => {
                this.props.changeHandler(e.value);
            }}
            // dataKey={"id"}
            placeholder="Выберите партнера"/>
    }
}