import { loginUser, logoutUser } from "../actions/actionCreators";
import { isDevMode } from "./AppHelpers";
import { IDataResponse, IToken, refreshTokenRequest } from "@shift-mono/common";

export const getAccessToken = () => {
  return (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      const logout = () => {
        dispatch(logoutUser());
        reject();
      };
      try {
        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");
        const expiresDate = localStorage.getItem("expiresDate");

        if (
          accessToken === null ||
          (expiresDate !== null
            ? Number.isNaN(Number.parseInt(expiresDate!))
              ? true
              : Number.parseInt(expiresDate!) < Date.now()
            : true)
        ) {
          if (refreshToken === null) {
            if (isDevMode()) {
              console.log("Data for refresh token data is missing!");
            }
            logout();
          }

          const response: IDataResponse<IToken | undefined> =
            await refreshTokenRequest(refreshToken!);
          console.log("response", response);
          const token = response.getData();
          if (!token) {
            if (isDevMode()) {
              console.log("Can't refresh token!");
            }
            logout();
          }
          dispatch(loginUser(token!));
          resolve(token!.getAccessToken());
        } else {
          resolve(accessToken);
        }
      } catch (err) {
        if (isDevMode()) {
          console.log(err);
        }
        logout();
      }
    });
  };
};

const saveFileFromResponse = (
  data: any,
  filename: any,
  mime: any = undefined,
  bom: any = undefined
) => {
  var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
  var blob = new Blob(blobData, { type: mime || "application/octet-stream" });
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    var blobURL = window.URL.createObjectURL(blob);
    var tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
};

export { saveFileFromResponse };
