import React from "react";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {
    AuthorizedRequestData,
    Bind,
    IPartner,
    IUser, requestAccountsForPartner,
    WrongArgumentError
} from "@shift-mono/common";
import {isDevMode} from "../../../../../helpers/AppHelpers";
import {getAccessToken} from "../../../../../helpers/RequestHelpers";
import {connect} from "react-redux";

interface IPartnerFormComponentProps {
    saveCallback: (data: IPartnerFormData) => void;
    currentPartner?: IPartner;
    availableAccounts: IUser[];
    getToken: () => Promise<string>;
    ref: (item: any) => any;
}

interface IPartnerFormComponentState extends IPartnerFormData {
    currentPartnerInit: boolean
}

export interface IPartnerFormData {
    title: string,
    accountId: string | undefined,
}

class PartnerFormComponent extends React.Component<IPartnerFormComponentProps, IPartnerFormComponentState> {
    private defaultState = {
        title: "",
        accountId: undefined,
        currentPartnerInit: false
    };

    constructor(props: IPartnerFormComponentProps) {
        super(props);

        this.state = this.defaultState;
    }

    @Bind()
    clearForm() {
        this.setState(this.defaultState);
    }

    @Bind()
    saveButtonHandler() {
        if (this.props.saveCallback) {
            this.props.saveCallback({
                title: this.state.title,
                accountId: this.state.accountId
            });
        }
    }

    async requestAccount(partner: IPartner): Promise<IUser | undefined> {
        const token = await this.props.getToken();
        const requestData = new AuthorizedRequestData(token);
        const user = (await requestAccountsForPartner(partner.getId(), requestData))
            .getData()
            .pop();
        return user;
    }

    @Bind()
    async fillAccount(partner: IPartner) {
        const user = await this.requestAccount(partner);
        this.setState({
            accountId: user ? user.getId() : undefined
        });
    }

    fillStateFromPartner(partner: IPartner, state: IPartnerFormComponentState) {
        try {
            state.title = partner.getTitle();
            this.fillAccount(partner)
            state.currentPartnerInit = true;
        } catch (err) {
            if (err instanceof WrongArgumentError) {
                if (isDevMode()) {
                    console.log(err.message);
                }
            } else {
                throw err;
            }
        }
    }

    shouldComponentUpdate(
        nextProps: Readonly<IPartnerFormComponentProps>,
        nextState: Readonly<IPartnerFormComponentState>,
        nextContext: any): boolean {
        if (!this.state.currentPartnerInit &&
            nextProps.currentPartner !== undefined) {
            this.fillStateFromPartner(nextProps.currentPartner, nextState)
        }
        return true;
    }

    render() {
        const isDetailPage = !!this.props.currentPartner;

        return <>
            <form
                className="card mt-2 p-2"
                onSubmit={(e: any) => {
                    e.preventDefault();
                }}
            >
                <div className="row m-1">
                    <label className="col-lg-4">Название партнера</label>
                    <InputText
                        className="col-lg-4"
                        value={this.state.title}
                        placeholder=""
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            this.setState({title: e.target.value})
                        }
                    />
                </div>
                {/*{!isDetailPage ? <></> : (*/}
                {/*    <div className="row m-1">*/}
                {/*        <label className="col-lg-4">Связанный аккаунт</label>*/}
                {/*        <AccountPicker*/}
                {/*            className="col-lg-4"*/}
                {/*            availableAccounts={this.props.availableAccounts}*/}
                {/*            changeHandler={(selectedAccount) => {*/}

                {/*                this.setState({accountId: selectedAccount? selectedAccount!.getId() : undefined})*/}
                {/*            }}*/}
                {/*            currentAccountId={this.state.accountId}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*)}*/}

                <div className="row m-1 ml-2">
                    <Button
                        label={isDetailPage ? "Сохранить" : "Добавить"}
                        onClick={() => {
                            this.saveButtonHandler();
                        }}
                    />
                </div>
            </form>
        </>;
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(PartnerFormComponent);