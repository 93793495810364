import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button} from "primereact/button";
import {DataView} from "primereact/dataview";
import {Dropdown} from "primereact/dropdown";
import {
    changeNotificationStatus,
    removeAllNotification,
    removeNotification
} from "../../../actions/creators/notificationCreators";
import URLs from "../../../actions/routesURL";
import "./NotificationListComponentStyle.scss";
import {
    INotification,
    Notification,
    NotificationObjectType,
    NotificationType,
    NotificationStatus, Bind
} from "@shift-mono/common";

interface INotificationListComponentProps {
    notifications: INotification[];
    removeNotification: (id: string) => void;
    removeAllNotifications: () => void;
    changeNotificationStatus: (id: string, status: NotificationStatus) => void;
    history?: any;
}

interface INotificationListComponentState {
    sortKey: string | null;
    sortOrder: number | null;
    sortField: string | null;
}

class NotificationListComponent extends React.Component<INotificationListComponentProps, INotificationListComponentState> {
    constructor(props: INotificationListComponentProps) {
        super(props);

        this.state = {
            sortKey: null,
            sortOrder: 1,
            sortField: "status",
        };
    }
    @Bind()
    removeNotification(id: string) {
        this.props.removeNotification(id);
    }

    renderNotificationContent(notification: INotification) {
        notification.getDate().getDay();
        return <div className="notification-list__item__content">
            {(notification.getStatus() === NotificationStatus.New ?
                <span className={"notification-list__item__new"}>NEW</span> :
                "")}
            {(notification.getDate() ?
                <>
                    <span className="notification-list__item__label">Дата: </span>
                    <span>{notification.getDate() ? new Date(notification.getDate()).toDateString() : ""}</span>
                </> : <></>)}

            <span className="notification-list__item__label">Сообщение:</span> {notification.getTitle()}

            {notification.getDescription() !== null ?
                <><span
                    className="notification-list__item__label"> Описание:</span> {notification.getDescription()}</> :
                <></>}
        </div>
    }
    @Bind()
    itemTemplate(notification: INotification, layout: string) {
        if (!notification) {
            return
        }
        if (layout === "list") {
            return <div
                className={`notification-list__item ${(
                        notification.getObjectType() !== null &&
                        notification.getObjectType() === NotificationObjectType.Order &&
                        notification.getObjectId() !== null
                    ) ?
                        "notification-list__item__clickable" :
                        ""
                    } ${(
                        notification.getType() !== null && 
                        notification.getType() === NotificationType.Warning
                    )? "notification-list__item__warning": ""}
                `}
                onClick={() => {
                    if (notification.getObjectType() !== null &&
                        notification.getObjectType() === NotificationObjectType.Order &&
                        notification.getObjectId() !== null
                    ) {
                        this.props.history.push(URLs.orders + "/" + notification.getObjectId());
                    }
                    this.props.changeNotificationStatus(notification.getId(), NotificationStatus.Regular);
                }}
            >
                {this.renderNotificationContent(notification)}
                <div className="notification-list__item__action">
                    <Button
                        type="button"
                        icon="pi pi-times"
                        className="p-button-danger float-right"
                        onClick={(event) => {
                            this.removeNotification(notification.getId());
                            event.stopPropagation();
                        }}
                    />
                </div>
            </div>
        }
    }
    @Bind()
    renderHeader() {
        const sortOptions = [
            {label: "Статусу", value: '_status'},
            {label: "Дате", value: '_date'},
        ];

        return <>
            <div className="notification-list__header">
                <div className="row">
                    <div className="col-2">
                        <Dropdown
                            placeholder="Сортировать по"
                            options={sortOptions}
                            value={this.state.sortKey}
                            onChange={this.onSortChange}
                        />
                    </div>
                    <div className="col-2 offset-8 d-flex flex-row-reverse">
                        <Button label="Очистить" className="p-button-danger" onClick={() => {
                            this.props.removeAllNotifications();
                        }}/>
                    </div>
                </div>
            </div>
        </>
    }
    @Bind()
    onSortChange(event: any) {
        const value = event.value;

        switch (value) {
            case "_date":
                this.setState({
                    sortKey: value,
                    sortOrder: -1,
                    sortField: value,
                });
                break;
            case "_status":
                this.setState({
                    sortKey: value,
                    sortOrder: 1,
                    sortField: value,
                });
                break;
        }
    }

    render() {
        const header = this.renderHeader();
        return <>
            <DataView
                className="notification-list"
                value={this.props.notifications}
                itemTemplate={this.itemTemplate}
                header={header}
                emptyMessage="Список уведомлений пуст"

                sortOrder={this.state.sortOrder ? this.state.sortOrder : undefined}
                sortField={this.state.sortField ? this.state.sortField : undefined}

            />
        </>
    }
}

const mapStateToProps = (state: any) => {
    return {
        notifications: state.notification.map((item: any) => {
            return Notification.from(item)
        }),
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        removeNotification: (id: string) => {
            dispatch(removeNotification(id))
        },
        removeAllNotifications: () => {
            dispatch(removeAllNotification())
        },
        changeNotificationStatus: (id: string, status: NotificationStatus) => {
            dispatch(changeNotificationStatus(id, status));
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationListComponent));