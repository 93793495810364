"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Discount = void 0;
var Currency_1 = require("./Currency");
var Model_1 = require("./Model");
var errors_1 = require("../errors");
var Discount = /** @class */function (_super) {
  __extends(Discount, _super);
  function Discount(args) {
    var _this = _super.call(this) || this;
    _this.arguments = args;
    return _this;
  }
  Discount.fromJson = function (data) {
    var requiredProps = ["id", "code", "value", "currency"];
    var verifyResult = Discount.verifyRequiredProperties(data, requiredProps);
    if (!verifyResult.isAllPropsExist) {
      throw new errors_1.MissingPropertiesError("Missing props: ".concat(verifyResult.missingProps.toString(), " in ").concat(this.constructor.name, " json data."));
    }
    var currentCurrency = (0, Currency_1.getCurrency)(data.currency);
    return new Discount({
      id: data.id ? data.id : "",
      code: data.code ? data.code : "",
      limited: data.limited ? data.limited : false,
      countPerAccount: data.countPerAccount ? data.countPerAccount : 0,
      createdDt: data.createdOn ? new Date(data.createdOn) : new Date(),
      startDt: data.startDt ? new Date(data.startDt) : undefined,
      endDt: data.endDt ? new Date(data.endDt) : undefined,
      value: data.value ? data.value : 0,
      currency: currentCurrency,
      referralId: data.referralId ? data.referralId : undefined,
      comment: data.comment ? data.comment : ""
    });
  };
  Discount.prototype.getId = function () {
    return this.arguments.id;
  };
  Discount.prototype.getCode = function () {
    return this.arguments.code;
  };
  Discount.prototype.getLimited = function () {
    return this.arguments.limited;
  };
  Discount.prototype.getCountPerAccount = function () {
    return this.arguments.countPerAccount;
  };
  Discount.prototype.getCreatedDt = function () {
    return this.arguments.createdDt;
  };
  Discount.prototype.getStartDt = function () {
    return this.arguments.startDt;
  };
  Discount.prototype.getEndDt = function () {
    return this.arguments.endDt;
  };
  Discount.prototype.getValue = function () {
    return this.arguments.value;
  };
  Discount.prototype.getCurrency = function () {
    return this.arguments.currency;
  };
  Discount.prototype.getreferralId = function () {
    return this.arguments.referralId;
  };
  Discount.prototype.getComment = function () {
    return this.arguments.comment;
  };
  return Discount;
}(Model_1.Model);
exports.Discount = Discount;