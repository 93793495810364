import React from "react";
import qs from "query-string";
import {TabMenu} from "primereact/tabmenu";
import {withRouter} from "react-router";
import {Bind} from "@shift-mono/common";

export interface ITabItem {
    id: number;
    label: string;
    component: any;
}

interface ITabMenuComponentProps {
    items: ITabItem[],
    location: any,
    history: any,
    match: any
}

interface ITabMenuComponentState {
    activeItem: ITabItem | undefined
}

class TabMenuComponent extends React.Component<ITabMenuComponentProps, ITabMenuComponentState> {
    pageParamKey = "_p";

    constructor(props: ITabMenuComponentProps) {
        super(props);

        const pageId = this.getPageIdFromURL();
        let defaultItem = props.items.find((item) => {
            return item.id === pageId
        });
        if (defaultItem === undefined && props.items.length > 0) {
            defaultItem = props.items[0]
        }

        this.state = {
            activeItem: defaultItem
        };
    }

    @Bind()
    setPageIdToURL(pageIndex: number) {
        const params = qs.parse(this.props.location.search);

        params[this.pageParamKey] = `${pageIndex}`;
        this.props.history.push({
            pathname: `${this.props.location.pathname}`,
            search: qs.stringify(params)
        });
    }

    @Bind()
    getPageIdFromURL(): number | undefined {
        const params: any = qs.parse(this.props.location.search);
        if (params[this.pageParamKey]) {
            return Number.parseInt(params[this.pageParamKey]);
        }
        return undefined;
    }

    @Bind()
    renderItemComponent(){
        return this.state.activeItem ? this.state.activeItem.component : <></>
    }

    render() {
        return <>
            <TabMenu model={this.props.items}
                     activeItem={this.state.activeItem}
                     onTabChange={({value}) => {
                         this.setPageIdToURL(value.id);
                         this.setState({activeItem: value})
                     }}
            />
            {this.renderItemComponent()}
        </>
    }
}

export default withRouter(TabMenuComponent);