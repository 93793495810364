import React from "react";
import ContentLoaderComponent from "../../../common/ContentLoaderComponent";
import MultiLanguageTextInputComponent from "../../../common/MultiLanguageTextInput/MultiLanguageTextInputComponent";
import {Button} from "primereact/button";
import {connect} from "react-redux";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {getAccessToken} from "../../../../helpers/RequestHelpers";
import {getServiceTypeTitle} from "../../../../helpers/ServiceHelpers";
import {TypedInputComponent, IOutput} from "./TypedInputComponent";
import {
    ServiceType,
    ServicePaymentType,
    IDirectionCost,
    IMonthStorageCost,
    Currency,
    IService,
    OtherService,
    StorageService,
    DeliveryService,
    LuggageService,
    ICountableResponse,
    CountableResponse,
    ILocalizedField,
    IDestination,
    Direction,
    IDirection,
    AuthorizedRequestData,
    requestDirections,
    requestDestinations, Bind,
} from "@shift-mono/common";
import {Checkbox} from "primereact/checkbox";

interface IServiceFormComponentProps {
    getToken: () => Promise<string>;
    saveCallback?: (data: IServiceFormData) => void;
    ref?: (item: any) => any;
    currentService?: IService | undefined;
}

interface IServiceFormComponentState {
    firstInit: boolean;

    availableDeliveryDirection: IDirection[];
    availableDestinations: IDestination[];

    currentServiceType: IServiceTypeDropdownItem | null;
    availableServiceTypes: IServiceTypeDropdownItem[];

    servicePaymentType: IPaymentTypeDropdownItem | null;
    availableServicePaymentTypes: IPaymentTypeDropdownItem[];

    serviceName: string;
    localizedName: ILocalizedField[];
    cost: number;
    costPerDirectionList: IDirectionCost[];
    costPerStorageMonths: IMonthStorageCost[];
    showInCarousel: boolean;
    discountApplicable: boolean;
    height:number,
    width:number,
    weight:number,
    length:number
}

interface IServiceTypeDropdownItem {
    title: string;
    type: ServiceType;
}

interface IPaymentTypeDropdownItem {
    title: string;
    type: ServicePaymentType;
}

export interface IServiceFormData {
    serviceName: string;
    localizedName: ILocalizedField[];
    currency: Currency;
    serviceType: ServiceType | null;
    paymentType: ServicePaymentType | null;
    cost: number;
    costPerDirectionList: IDirectionCost[];
    costPerStorageMonths: IMonthStorageCost[];
    showInCarousel: boolean;
    discountApplicable: boolean;
    height:number,
    width:number,
    weight:number,
    length:number
}

class ServiceFormComponent extends React.Component<IServiceFormComponentProps,
    IServiceFormComponentState> {
    private typedInputRef: any = undefined;
    private multiLangInputRef: any = undefined;

    constructor(props: IServiceFormComponentProps) {
        super(props);

        this.state = {
            firstInit: true,

            availableDeliveryDirection: [],
            availableDestinations: [],

            currentServiceType: {title: "Прочее", type: ServiceType.Other},
            availableServiceTypes: this.getAllAvailableServiceTypes(),

            servicePaymentType: null,
            availableServicePaymentTypes: this.getAllAvailableServicePaymentTypes(),

            serviceName: "",
            localizedName: [],
            cost: 0,
            costPerDirectionList: [],
            costPerStorageMonths: [],
            showInCarousel: false,
            discountApplicable: true,
            height:0,
            width:0,
            weight:0,
            length:0,
        };

        this.typedInputRef = React.createRef();
    }
    @Bind()
    getAllAvailableServiceTypes(): IServiceTypeDropdownItem[] {
        return [
            {
                title: getServiceTypeTitle(ServiceType.Luggage),
                type: ServiceType.Luggage
            },
            {
                title: getServiceTypeTitle(ServiceType.Move),
                type: ServiceType.Move
            },
            {
                title: getServiceTypeTitle(ServiceType.Store),
                type: ServiceType.Store
            },
            {title: getServiceTypeTitle(ServiceType.Other), type: ServiceType.Other}
        ];
    }

    getServiceTypeDropdownItemBy(service_type: ServiceType | null) {
        const filtered_types = this.getAllAvailableServiceTypes().filter(
            (service_type_dropdown_item: IServiceTypeDropdownItem) => {
                return service_type_dropdown_item.type === service_type;
            }
        );
        return filtered_types.length > 0 ? filtered_types[0] : null;
    }
    @Bind()
    getAllAvailableServicePaymentTypes(): IPaymentTypeDropdownItem[] {
        return [
            {title: "Разовая", type: ServicePaymentType.OneTime},
            {title: "Помесячная", type: ServicePaymentType.Monthly}
        ];
    }

    getPaymentTypeDropdownItemBy(payment_type: ServicePaymentType | null) {
        const filtered_types = this.getAllAvailableServicePaymentTypes().filter(
            (payment_type_dropdown_item: IPaymentTypeDropdownItem) => {
                return payment_type_dropdown_item.type === payment_type;
            }
        );
        return filtered_types.length > 0 ? filtered_types[0] : null;
    }
    @Bind()
    async getDirectionsRequest(): Promise<ICountableResponse<IDirection[]>> {
        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token);
        try {
            return await requestDirections(request_data);
        } catch (err) {
            return new CountableResponse<Direction[]>([],0);
        }
    }
    @Bind()
    directionRequestResult(result: ICountableResponse<IDirection[]>) {
        this.setState({availableDeliveryDirection: result.getData()});
    }
    @Bind()
    async getDestinationRequest(): Promise<ICountableResponse<IDestination[]>> {
        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token);
        return requestDestinations(request_data);
    }
    @Bind()
    destinationRequestResult(destinations: ICountableResponse<IDestination[]>) {
        this.setState({availableDestinations: destinations.getData()});
    }
    @Bind()
    serviceTypeChanged(e: any) {
        this.setState({currentServiceType: e.value});
    }
    @Bind()
    servicePaymentTypeChanged(e: any) {
        this.setState({servicePaymentType: e.value});
    }
    @Bind()
    typedInputChangeHandle(value: IOutput) {
        switch (value.current_type) {
            case ServiceType.Luggage:
            case ServiceType.Move:
                if (value.cost_per_directions_list) {
                    this.setState({
                        cost: 0,
                        costPerDirectionList: value.cost_per_directions_list!,
                        costPerStorageMonths: []
                    });
                }
                break;
            case ServiceType.Store:
                if (value.cost_per_month_list) {
                    this.setState({
                        cost: 0,
                        costPerDirectionList: [],
                        costPerStorageMonths: value.cost_per_month_list!
                    });
                }
                break;
            case ServiceType.Other:
                if (value.cost) {
                    this.setState({
                        cost: value.cost,
                        costPerDirectionList: [],
                        costPerStorageMonths: []
                    });
                }
                break;
        }
    }
    @Bind()
    clearForm() {
        this.setState({
            serviceName: "",
            localizedName: [],
            servicePaymentType: null,
            cost: 0,
            costPerDirectionList: [],
            costPerStorageMonths: [],
            showInCarousel: false,
            discountApplicable: true,
            height:0,
            width:0,
            weight:0,
            length:0
        });

        this.typedInputRef.current.clearForm();
        if (this.multiLangInputRef) {
            this.multiLangInputRef.clear();
        }
    }
    @Bind()
    saveButtonHandle() {
        if (this.props.saveCallback) {
            const serviceFormData = {
                serviceName: this.state.serviceName,
                localizedName: this.state.localizedName,
                currency: Currency.Rub,
                serviceType: this.state.currentServiceType
                    ? this.state.currentServiceType.type
                    : null,
                paymentType: this.state.servicePaymentType
                    ? this.state.servicePaymentType.type
                    : null,
                cost: this.state.cost,
                costPerDirectionList: this.state.costPerDirectionList,
                costPerStorageMonths: this.state.costPerStorageMonths,
                showInCarousel: this.state.showInCarousel,
                discountApplicable: this.state.discountApplicable,
                height:this.state.height,
                width:this.state.width,
                weight:this.state.weight,
                length:this.state.length,
            };
            this.props.saveCallback(serviceFormData);
        }
    }

    fillStateOfService(
        service: IService,
        existState: IServiceFormComponentState
    ) {
        existState.currentServiceType = this.getServiceTypeDropdownItemBy(
            service.getType()
        );
        existState.height=service.getHeigth();
        existState.width=service.getWidth();
        existState.weight=service.getWeight();
        existState.length=service.getLength();
        existState.serviceName = service.getName();
        existState.localizedName = service.getLocalizedName();
        existState.servicePaymentType = this.getPaymentTypeDropdownItemBy(
            service.getPaymentType()
        );
        existState.showInCarousel = service.isShowingInCarousel();
        existState.discountApplicable = service.isDiscountApplicable();

        switch (service.getType()) {
            case ServiceType.Other:
                const otherService = service as OtherService;
                existState.cost = otherService.getCost();
                break;
            case ServiceType.Store:
                const storeService = service as StorageService;
                existState.costPerStorageMonths = storeService.getMonthStorageCost();
                break;
            case ServiceType.Move:
                const deliveryService = service as DeliveryService;
                existState.costPerDirectionList = deliveryService.getDirectionCost();
                break;
            case ServiceType.Luggage:
                const luggageServcie = service as LuggageService;
                existState.costPerDirectionList = luggageServcie.getDirectionCost();
                break;
        }
    }

    shouldComponentUpdate(
        nextProps: IServiceFormComponentProps,
        nextState: IServiceFormComponentState
    ) {
        if (nextProps.currentService !== undefined && this.state.firstInit) {
            this.fillStateOfService(nextProps.currentService, nextState);
            nextState.firstInit = false;
        }

        return true;
    }

    render() {
        return (
            <ContentLoaderComponent<ICountableResponse<IDestination[]>>
                contentRequest={this.getDestinationRequest}
                resultCallback={this.destinationRequestResult}
            >
                <ContentLoaderComponent<ICountableResponse<IDirection[]>>
                    contentRequest={this.getDirectionsRequest}
                    resultCallback={this.directionRequestResult}
                    errorCallback={err => console.log(err)}
                >
                    <form
                        className="card col-12 mt-2 p-2"
                        onSubmit={(e: any) => {
                            e.preventDefault();
                        }}
                    >
                        <div className="row m-1">
                            <label className="col-lg-4">Тип услуги</label>
                            <Dropdown
                                className="col-lg-4"
                                value={this.state.currentServiceType}
                                options={this.state.availableServiceTypes}
                                onChange={this.serviceTypeChanged}
                                placeholder="Тип услуги"
                                optionLabel="title"
                            />
                        </div>
                        <div className="row m-1">
                            <label className="col-lg-4">Имя услуги</label>
                            <InputText
                                className="col-lg-4"
                                value={this.state.serviceName}
                                placeholder="Имя услуги"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    this.setState({serviceName: e.target.value})
                                }
                            />
                        </div>
                        <div className="row m-1">
                            <label className="col-lg-4"/>
                            <MultiLanguageTextInputComponent
                                className="col-lg-4 p-0"
                                ref={el => {
                                    this.multiLangInputRef = el;
                                }}
                                initLocalizedFields={this.state.localizedName}
                                changeLangValueCallback={localizedFields => {
                                    this.setState({localizedName: localizedFields});
                                }}
                            />
                        </div>
                        <div className="row m-1">
                            <label className="col-lg-4">Тип оплаты услуги</label>
                            <Dropdown
                                className="col-lg-4"
                                value={this.state.servicePaymentType}
                                options={this.state.availableServicePaymentTypes}
                                onChange={this.servicePaymentTypeChanged}
                                placeholder="Тип оплаты"
                                optionLabel="title"
                            />
                        </div>
                        <div className="row m-1">
                            <label htmlFor="cb1" className="col-lg-4">
                                Показывать в главном меню приложения
                            </label>
                            <div className="d-flex align-items-center">
                                <Checkbox
                                    className="col-1"
                                    inputId="cb1"
                                    onChange={(e: any) => this.setState({showInCarousel: e.checked})}
                                    checked={this.state.showInCarousel}
                                />
                            </div>
                        </div>
                        <div className="row m-1">
                            <label htmlFor="cb1" className="col-lg-4">
                                Применим для скидок
                            </label>
                            <div className="d-flex align-items-center">
                                <Checkbox
                                    className="col-1"
                                    inputId="cb1"
                                    onChange={(e: any) => this.setState({discountApplicable: e.checked})}
                                    checked={this.state.discountApplicable}
                                />
                            </div>
                        </div>
                        <div className="row m-1">
                            <label className="col-lg-4">Высота</label>
                            <InputText
                                className="col-lg-4"
                                type='number'
                                value={this.state.height}
                                placeholder="Высота"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    this.setState({height:parseInt( e.target.value)})
                                }
                            />
                        </div>
                        <div className="row m-1">
                            <label className="col-lg-4">Ширина</label>
                            <InputText
                                className="col-lg-4"
                                type='number'
                                value={this.state.width}
                                placeholder="Ширина"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    this.setState({width:parseInt( e.target.value)})
                                }
                            />
                        </div>
                        <div className="row m-1">
                            <label className="col-lg-4">Вес</label>
                            <InputText
                                className="col-lg-4"
                                type='number'
                                value={this.state.weight}
                                placeholder="Вес"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    this.setState({weight:parseInt( e.target.value)})
                                }
                            />
                        </div>
                        <div className="row m-1">
                            <label className="col-lg-4">Длина</label>
                            <InputText
                                className="col-lg-4"
                                type='number'
                                value={this.state.length}
                                placeholder="Длина"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    this.setState({length:parseInt( e.target.value)})
                                }
                            />
                        </div>
                        {this.state.currentServiceType ? (
                            <>
                                <TypedInputComponent
                                    type={this.state.currentServiceType.type}
                                    available_delivery_direction={
                                        this.state.availableDeliveryDirection
                                    }
                                    available_destinations={this.state.availableDestinations}
                                    changeHandle={this.typedInputChangeHandle}
                                    ref={this.typedInputRef}
                                    default_cost={this.state.cost}
                                    default_month_storage_cost_list={
                                        this.state.costPerStorageMonths
                                    }
                                    default_price_per_direction={this.state.costPerDirectionList}
                                />
                                <div className="row m-1 ml-2">
                                    <Button
                                        label={
                                            this.props.currentService
                                                ? "Сохранить"
                                                : "Добавить услугу"
                                        }
                                        onClick={() => {
                                            this.saveButtonHandle();
                                        }}
                                    />
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </form>
                </ContentLoaderComponent>
            </ContentLoaderComponent>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(
    ServiceFormComponent
);
