import Papa from "papaparse";
import { isDevMode } from "../../../../helpers/AppHelpers";
import {
    DeliveryService, DirectionCost,
    IService,
    IDirectionCost,
    LuggageService,
    ServiceType,
    IDirection,
    requestDirections,
    postDirectionRequest,
    DirectionRequestData,
    AuthorizedRequestData,
    ICountableResponse,
    CountableResponse,
    IDestination,
    requestDestinations,
    putServiceRequest,
    ServiceRequestData,
    requestDirectionById,
    putDirectionRequest
} from "@shift-mono/common";

export enum ImportLogType {
    InProgress = 0,
    Error = 1,
    Completed = 2,
    Warning = 3,
}

export function importDirectionsAndPrice(
    importFile: File,
    selectedService: IService | undefined,
    importDirectionPricesEnabled: boolean,
    importDeliveryDurationEnabled: boolean,

    importOnlyMaxDurationEnabled: boolean,
    importWithDefaultDurationEnabled: boolean,
    deliveryDefaultDuration: number,

    getToken: () => Promise<string>,
    importLogHandler?: (log: string, type: ImportLogType) => void) {
    // 1) Берем все города которые есть в табличке и проверяем их наличие на сервере
    // 2) Если какого то из городов нет выводим ошибку с просьбой добавить город
    // 3) Если все хорошо и все города существуют, то берем все направления в которых есть эти города
    // 4) Создаем направление если его пока не существует на сервере но оно есть в табличке
    // 5) Берем id всех направлений представленных в табличке и добавляем/изменяем их цены в список выбранной услуги

    const MIN_COLUMN_COUNT = 4;
    const DESTINATION_FROM_COLUMN = 0;
    const DESTINATION_TO_COLUMN = 1;
    const DIRECTION_COST_COLUMN = 2;
    const DELIVERY_DURATION_COLUMN = 3;

    if (!verifyService()) {
        return
    }

    // Парсим файл с направлениями
    Papa.parse(importFile, {
        complete: parseResultHandler
    })

    async function parseResultHandler(results: any) {
        if (results.error && results.error.length > 0) {
            addLogMessage("Файл некорректен: Ошибка парсинга файла", ImportLogType.Error);
            return;
        }
        const directionsArrayFromFile = results.data;

        //Собираем из всех строк файла коллекцию городов
        const fileDestinationsSet = getDestinationTitleSetFromFile(directionsArrayFromFile);
        if (!fileDestinationsSet) {
            return
        }

        //Забираем все города с сервера
        const destinationsResponse = await getDestinationsRequest();
        //Проверяем все ли города из файла есть на сервере
        if (checkExistOfDestinationsOnServer(fileDestinationsSet, destinationsResponse.getData())) {
            addLogMessage("Не все города есть на сервере", ImportLogType.Error);
            return;
        }

        const destinationTitlePerId = getDestinationTitlePerId(fileDestinationsSet, destinationsResponse.getData())
        // const destinationsIdPerTitleMap = getDestinationIdPerTitle(fileDestinationsSet, destinationsResponse.getData())

        //Запрашиваем направления в которых есть наши города
        let directions = await getDirectionsRequest(new Set(destinationTitlePerId.values()));
        //Из полученных направлений составляем map где ключом является id города откуда начинается направление,
        //а значением является set возможных городов
        const directionsMap = composeDirectionsMap(directions.getData());

        const allAdded = await createMissingDirectionsOnServer(directionsArrayFromFile, destinationTitlePerId, directionsMap);
        if (!allAdded) {
            addLogMessage(`Не все направления добавлены`, ImportLogType.Error);
            return;
        }

        //Запрашиваем направления в которых есть наши города
        directions = await getDirectionsRequest(new Set(destinationTitlePerId.values()));
        //Создаем Map направлений, где первый ключ это id города откуда повезут, второй ключ это id города куда повезут, значения второго Map это id направления
        const directionsTreeWithId = composeDirectionsMapWithId(directions.getData());
        const directionIdPerPrice = compareDirectionIdPerPriceMap(directionsArrayFromFile, destinationTitlePerId, directionsTreeWithId);
        if (!directionIdPerPrice) {
            addLogMessage("Ошибка формирования цены за направление", ImportLogType.Error);
            return;
        }

        //Создаем Map направлений, где первый ключ это id направления, второе значение это количество дней доставки
        const directionIdPerDuration = compareDirectionIdPerDeliveryDurationMap(directionsArrayFromFile, destinationTitlePerId, directionsTreeWithId);
        if (!directionIdPerDuration) {
            addLogMessage("Ошибка формирования дат доставки", ImportLogType.Error);
            return;
        }

        if (importDeliveryDurationEnabled) {
            //Обновляем время доставки для направлений
            if (!(await updateDirectionsDeliveryDuration(directionIdPerDuration))) {
                return;
            }
        }

        if (importDirectionPricesEnabled && selectedService) {
            //Обновляем направления для услуги
            if (selectedService.getType() === ServiceType.Luggage) {
                const currentService = selectedService as LuggageService;
                await updateServiceDirections(currentService, directionIdPerPrice);
            } else if (selectedService.getType() === ServiceType.Move) {
                const currentService = selectedService as DeliveryService;
                await updateServiceDirections(currentService, directionIdPerPrice);
            } else {
                addLogMessage("Ошибка обновления цен услуги", ImportLogType.Error);
                return;
            }
        }

        addLogMessage("Импорт завершен", ImportLogType.Completed);
    }

    function verifyService(): boolean {
        if (!importDirectionPricesEnabled) {
            return true;
        }
        if (!selectedService) {
            addLogMessage("Не выбранна услуга", ImportLogType.Error);
            return false;
        }
        if (selectedService.getType() !== ServiceType.Move
            && selectedService.getType() !== ServiceType.Luggage
        ) {
            addLogMessage("Выбрана некорректная услуга", ImportLogType.Error);
            return false;
        }
        return true;
    }

    function addLogMessage(msg: string, logType: ImportLogType) {
        if (isDevMode()) {
            console.log(msg);
        }
        if (importLogHandler) {
            importLogHandler(msg, logType);
        }
    }

    function checkExistOfDestinationsOnServer(_fileDestinations: Set<string>, serverDestinations: IDestination[]) {
        const fileDestinations = new Set(_fileDestinations);
        serverDestinations
            .forEach((serverDestination) => {
                const destTitle = serverDestination.getTitle().toLowerCase();
                if (fileDestinations.has(destTitle)) {
                    fileDestinations.delete(destTitle);
                }
            })
        if (fileDestinations.size !== 0) {
            const noExistDestsStr = Array.from(fileDestinations.keys()).toString()
            addLogMessage(`Нет городов: ${noExistDestsStr}`, ImportLogType.Warning);
        }
        return fileDestinations.size !== 0
    }

    function getDestinationTitlePerId(fileDestinations: Set<string>, serverDestinations: IDestination[]): Map<string, string> {
        const destinationTitlePerId = new Map<string, string>();
        serverDestinations.forEach((serverDestination) => {
            const destTitle = serverDestination.getTitle().toLowerCase();
            if (fileDestinations.has(destTitle)) {
                destinationTitlePerId.set(destTitle, serverDestination.getId());
            }
        });
        return destinationTitlePerId;
    }

    // function getDestinationIdPerTitle(fileDestinations: Set<string>, serverDestinations: IDestinationModel[]): Map<string, string> {
    //     const destinationIdPerTitle = new Map<string, string>();
    //     serverDestinations.forEach((serverDestination) => {
    //         const destTitle = serverDestination.getTitle().toLowerCase();
    //         if (fileDestinations.has(destTitle)) {
    //             destinationIdPerTitle.set(serverDestination.getId(), destTitle);
    //         }
    //     });
    //     return destinationIdPerTitle;
    // }

    function getDestinationTitleSetFromFile(file: any): Set<string> | undefined {
        if (file.length === 0) {
            addLogMessage("Файл некорректен: Файл направлений пуст", ImportLogType.Error);
            return;
        }

        if (file[0].length !== MIN_COLUMN_COUNT) {
            addLogMessage("Файл некорректен: Файл направлений не подходит под формат", ImportLogType.Error);
            return;
        }

        const fileDestinationsSet = new Set<string>();
        file.forEach((item: string[]) => {
            if (item[DESTINATION_TO_COLUMN] !== undefined) {
                fileDestinationsSet.add(item[DESTINATION_FROM_COLUMN].toLowerCase());
                console.log(item[DESTINATION_TO_COLUMN])
                fileDestinationsSet.add(item[DESTINATION_TO_COLUMN].toLowerCase());
            }
        })
        return fileDestinationsSet
    }

    function composeDirectionsMap(directions: IDirection[]): Map<string, Set<string>> {
        const directionsTree = new Map<string, Set<string>>()
        directions
            .forEach((direction) => {
                const destinationFromId = direction.getVertices()[0];
                const destinationToId = direction.getVertices()[1];

                if (!directionsTree.has(destinationFromId)) {
                    directionsTree.set(destinationFromId, new Set<string>())
                }
                directionsTree.get(destinationFromId)!.add(destinationToId);
            })
        return directionsTree;
    }

    function composeDirectionsMapWithId(directions: IDirection[]): Map<string, Map<string, string>> {
        const directionsTree = new Map<string, Map<string, string>>()
        directions
            .forEach((direction) => {
                const destinationFromId = direction.getVertices()[0];
                const destinationToId = direction.getVertices()[1];

                if (!directionsTree.has(destinationFromId)) {
                    directionsTree.set(destinationFromId, new Map<string, string>())
                }
                directionsTree.get(destinationFromId)!.set(destinationToId, direction.getId());
            })
        return directionsTree;
    }

    function compareDirectionIdPerPriceMap(
        fileDirections: any,
        destinationTitlePerId: Map<string, string>,
        serverDirectionsMapWithId: Map<string, Map<string, string>>): Map<string, number> | undefined {
        const directionIdPerPrice = new Map<string, number>();
        let isCompleted = true;

        fileDirections.forEach((item: string[]) => {
            if (item[DESTINATION_TO_COLUMN] !== undefined) {
                const destinationFromTitle = item[DESTINATION_FROM_COLUMN].toLowerCase();
                const destinationToTitle = item[DESTINATION_TO_COLUMN].toLowerCase();
                const directionCost = Number.parseInt(item[DIRECTION_COST_COLUMN]);

                const destinationFromId = destinationTitlePerId.get(destinationFromTitle);
                const destinationToId = destinationTitlePerId.get(destinationToTitle);

                if (serverDirectionsMapWithId.has(destinationFromId!)
                    && serverDirectionsMapWithId.get(destinationFromId!)!.has(destinationToId!)
                    && serverDirectionsMapWithId.get(destinationFromId!)!.get(destinationToId!)
                    && isFinite(directionCost)
                ) {
                    const directionId = serverDirectionsMapWithId.get(destinationFromId!)!.get(destinationToId!)
                    directionIdPerPrice.set(directionId!, directionCost);
                } else {
                    isCompleted = false;
                    return;
                }
            }
        })
        if (isCompleted) {
            return directionIdPerPrice;
        }
        return;
    }

    function compareDirectionIdPerDeliveryDurationMap(
        fileDirections: any,
        destinationTitlePerId: Map<string, string>,
        serverDirectionsMapWithId: Map<string, Map<string, string>>): Map<string, number> | undefined {
        const directionIdPerDuration = new Map<string, number>();
        let isCompleted = true;

        fileDirections.forEach((item: string[]) => {
            if (item[DESTINATION_TO_COLUMN] !== undefined) {
                const destinationFromTitle = item[DESTINATION_FROM_COLUMN].toLowerCase();
                const destinationToTitle = item[DESTINATION_TO_COLUMN].toLowerCase();

                const deliveryDuration = Number.parseInt(item[DELIVERY_DURATION_COLUMN]);

                const destinationFromId = destinationTitlePerId.get(destinationFromTitle);
                const destinationToId = destinationTitlePerId.get(destinationToTitle);

                if (serverDirectionsMapWithId.has(destinationFromId!)
                    && serverDirectionsMapWithId.get(destinationFromId!)!.has(destinationToId!)
                    && serverDirectionsMapWithId.get(destinationFromId!)!.get(destinationToId!)
                    && !isNaN(deliveryDuration)
                    && isFinite(deliveryDuration)
                ) {
                    const directionId = serverDirectionsMapWithId.get(destinationFromId!)!.get(destinationToId!)
                    directionIdPerDuration.set(directionId!, deliveryDuration);
                } else if (serverDirectionsMapWithId.has(destinationFromId!)
                    && serverDirectionsMapWithId.get(destinationFromId!)!.has(destinationToId!)
                    && serverDirectionsMapWithId.get(destinationFromId!)!.get(destinationToId!)
                    && importWithDefaultDurationEnabled
                ) {
                    const directionId = serverDirectionsMapWithId.get(destinationFromId!)!.get(destinationToId!)
                    directionIdPerDuration.set(directionId!, deliveryDefaultDuration);
                    addLogMessage(`Для направления ${destinationFromTitle}/${destinationToTitle} добавлено время доставки по умолчанию`, ImportLogType.Warning);
                } else {
                    addLogMessage(`Для направления ${destinationFromTitle}/${destinationToTitle} нет времени доставки`, ImportLogType.Warning);
                    isCompleted = false;
                    return;
                }
            }
        })
        if (isCompleted) {
            return directionIdPerDuration;
        }
        return;
    }

    async function createMissingDirectionsOnServer(
        fileDirections: any,
        destinationTitlePerId: Map<string, string>,
        serverDirectionsMap: Map<string, Set<string>>): Promise<boolean> {
        const createDirectionRequests: Promise<boolean>[] = [];

        fileDirections.forEach((item: string[]) => {
            if (item[DESTINATION_TO_COLUMN] !== undefined) {
                const destinationFromTitle = item[DESTINATION_FROM_COLUMN].toLowerCase();
                const destinationToTitle = item[DESTINATION_TO_COLUMN].toLowerCase();

                const destinationFromId = destinationTitlePerId.get(destinationFromTitle);
                const destinationToId = destinationTitlePerId.get(destinationToTitle);

                if (!serverDirectionsMap.get(destinationFromId!) || !serverDirectionsMap.get(destinationFromId!)!.has(destinationToId!)) {
                    //Если направления нет в "Дереве" направлений, т.е. на сервере, то добавляем его в очередь на создание
                    addLogMessage(`Направления ${destinationFromTitle + " / " + destinationToTitle} нет на сервере`, ImportLogType.InProgress);
                    createDirectionRequests
                        .push(new Promise<boolean>((resolve) => {
                            try {
                                addLogMessage(`Добавляем направление ${destinationFromTitle + " / " + destinationToTitle} на сервер`, ImportLogType.InProgress);
                                postDirectionData(
                                    `${destinationFromTitle + " / " + destinationToTitle}`,
                                    destinationFromId! as string,
                                    destinationToId! as string)
                                    .then(isAdded => {
                                        if (isAdded) {
                                            addLogMessage(`Направление ${destinationFromTitle + " / " + destinationToTitle} добавлено`, ImportLogType.InProgress);
                                        } else {
                                            addLogMessage(`Направление ${destinationFromTitle + " / " + destinationToTitle} не добавлено`, ImportLogType.InProgress);
                                        }
                                        resolve(isAdded)
                                    }).catch(() => {
                                        addLogMessage(`Направление ${destinationFromTitle + " / " + destinationToTitle} не добавлено`, ImportLogType.InProgress);
                                        resolve(false);
                                    });
                            } catch (e) {
                                addLogMessage(`Направление ${destinationFromTitle + " / " + destinationToTitle} не добавлено`, ImportLogType.InProgress);
                                resolve(false);
                            }
                        })
                        )
                } else {
                    addLogMessage(`Направление ${destinationFromTitle + " / " + destinationToTitle} уже существует`, ImportLogType.InProgress)
                }
            }
        })
        //Посылаем все запросы создания направлений на сервер, и проверяем все ли они исполнились
        return (await Promise.all(createDirectionRequests)).every((isAdded) => (isAdded))
    }

    async function updateServiceDirections(currentService: LuggageService | DeliveryService, directionIdPerPrice: Map<string, number>) {
        const token: any = await getToken();
        const newDirectionCosts: IDirectionCost[] = [];
        directionIdPerPrice.forEach((cost, id) => {
            newDirectionCosts.push(new DirectionCost(id, cost))
        })

        const directionCosts = currentService
            .getDirectionCost()
            .filter((directionCost) => (!directionIdPerPrice.has(directionCost.getId())))

        directionCosts.push(...newDirectionCosts);
        const serviceId = currentService.getId();

        const requestData = new ServiceRequestData(
            {
                accessToken: token,
                serviceType: currentService.getType(),
                height: currentService.getHeigth(),
                width: currentService.getWeight(),
                weight: currentService.getWeight(),
                length: currentService.getLength(),
                name: currentService.getName(),
                localizedName: currentService.getLocalizedName(),
                serviceId: serviceId,
                currency: currentService.getCurrency(),
                paymentType: currentService.getPaymentType(),
                directionsCost: directionCosts,
                showInCarousel: currentService.isShowingInCarousel(),
                discountApplicable: currentService.isDiscountApplicable(),
                thumbnailImage: currentService.getThumbnailImage(),
            }
        );
        try {
            const updateResult = await putServiceRequest(requestData);
            if (updateResult) {
                addLogMessage("Цены услуги обновлены", ImportLogType.InProgress)
            } else {
                addLogMessage("Ошибка обновления услуги", ImportLogType.Error)
            }
        } catch (err) {
            addLogMessage("Ошибка обновления услуги", ImportLogType.Error)
        }
    }

    async function updateDirectionsDeliveryDuration(directionIdPerDuration: Map<string, number>): Promise<boolean> {
        const token: any = await getToken();
        const dirsDuration = Array.from(directionIdPerDuration.entries());

        try {
            for (const [id, importedDays] of dirsDuration) {
                const getData = new AuthorizedRequestData(token);
                const direction = (await requestDirectionById(id, getData)).getData();

                if (direction) {

                    const updatedDeliveryDays = !importOnlyMaxDurationEnabled
                        ? importedDays
                        : direction.getDeliveryDuration() < importedDays
                            ? importedDays
                            : direction.getDeliveryDuration()


                    const putData = new DirectionRequestData({
                        directionId: direction.getId(),
                        accessToken: token,
                        name: direction.getName(),
                        localizedName: direction.getLocalizedName(),
                        vertices: direction.getVertices(),
                        deliveryDuration: updatedDeliveryDays,
                        coefficient: direction.getCoefficient()
                    });

                    if (await putDirectionRequest(putData)) {
                        addLogMessage(`Время доставки направления ${direction.getName()} обновлено`, ImportLogType.InProgress);
                        await promisedTimeout(500);
                    } else {
                        addLogMessage(`Ошибка обновления времени доставки направления ${direction.getName()}`, ImportLogType.Error);
                        return false;
                    }
                }
            }
        } catch (err) {
            addLogMessage(`Ошибка обновления времени доставки направлений`, ImportLogType.Error)
            return false;
        }
        return true;
    }

    function promisedTimeout(time: number): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            setTimeout(() => {
                resolve(true);
            }, time)
        })
    }

    async function postDirectionData(directionTitle: string, destinationFromId: string, destinationToId: string) {
        const token: any = await getToken();
        try {
            const requestData = new DirectionRequestData(
                {
                    accessToken: token,
                    name: directionTitle,
                    localizedName: [],
                    vertices: [destinationFromId, destinationToId],
                    //TODO Добавить импорт времени доставки
                    deliveryDuration: 5,
                    coefficient: ''
                })
            return await postDirectionRequest(requestData);
        } catch (err) {
            return false;
        }
    }

    async function getDestinationsRequest(): Promise<ICountableResponse<IDestination[]>> {
        const token: any = await getToken();
        const requestData = new AuthorizedRequestData(token, {}, { skip: 0, limit: 0 });
        try {
            return await requestDestinations(requestData);
        } catch (err) {
            return new CountableResponse([], 0);
        }
    }

    async function getDirectionsRequest(destinationsId: Set<string>): Promise<ICountableResponse<IDirection[]>> {
        const token: any = await getToken();
        const params = {
            limit: 0,
            skip: 0,
            query: `{vertices: {$in:["${Array.from(destinationsId.keys()).join('","')}"]}}`
        };
        const requestData = new AuthorizedRequestData(token, {}, params);
        try {
            return await requestDirections(requestData);
        } catch (err) {
            return new CountableResponse([], 0);
        }
    }
}