"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function sent() {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2]) _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteUserRequest = exports.putUserRequest = exports.postUserRequest = exports.resetAccountPass = exports.requestClientAccounts = exports.requestB2bAccounts = exports.requestAccountsForPartner = exports.requestAccounts = exports.requestAgents = exports.requestUserById = exports.requestUsersWithAuthData = exports.requestUsers = void 0;
var _1 = require("./");
var response_1 = require("../response");
var data_1 = require("../data");
var models_1 = require("../../models/");
var common_1 = require("../common");
var common_2 = require("../../common");
var errors_1 = require("../../errors");
var requestUsers = function requestUsers(requestData) {
  return __awaiter(void 0, void 0, void 0, function () {
    var url, method, updatedRequestData, result, requestResult, objectsCount, userObjects, users, err_1;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          url = (0, common_1.getApiDomain)() + "/v1.0/user";
          method = _1.RequestMethods.GET;
          updatedRequestData = requestData;
          if (!updatedRequestData.getParams().hasOwnProperty("limit")) {
            updatedRequestData = new data_1.AuthorizedRequestData(requestData.getAccessToken(), requestData.getHeaders(), Object.assign({}, requestData.getParams(), {
              limit: 0
            }), requestData.getData(), requestData.getAxiosOptions());
          }
          result = new response_1.CountableResponse([], 0);
          _a.label = 1;
        case 1:
          _a.trys.push([1, 3,, 4]);
          return [4 /*yield*/, (0, _1.request)(url, method, updatedRequestData)];
        case 2:
          requestResult = _a.sent();
          if (requestResult.data) {
            objectsCount = requestResult.headers["x-objects-count"] ? parseInt(requestResult.headers["x-objects-count"]) : 0;
            userObjects = requestResult.data;
            users = userObjects.reduce(function (users, item) {
              try {
                var user = models_1.User.fromJson(item);
                users.push(user);
              } catch (err) {
                common_2.Logger.e(err);
                if (!(err instanceof errors_1.MissingPropertiesError)) {
                  throw err;
                }
              }
              return users;
            }, []);
            result = new response_1.CountableResponse(users, objectsCount);
          }
          return [3 /*break*/, 4];
        case 3:
          err_1 = _a.sent();
          common_2.Logger.e(err_1);
          if (!(err_1 instanceof errors_1.MissingPropertiesError)) {
            throw err_1;
          }
          return [3 /*break*/, 4];
        case 4:
          return [2 /*return*/, result];
      }
    });
  });
};
exports.requestUsers = requestUsers;
var requestUsersWithAuthData = function requestUsersWithAuthData(requestData) {
  return __awaiter(void 0, void 0, void 0, function () {
    var usersResponse, filteredUsers;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4 /*yield*/, (0, exports.requestUsers)(requestData)];
        case 1:
          usersResponse = _a.sent();
          filteredUsers = usersResponse.getData().filter(function (user) {
            return user.getUsername() !== undefined;
          });
          return [2 /*return*/, new response_1.DataResponse(filteredUsers)];
      }
    });
  });
};
exports.requestUsersWithAuthData = requestUsersWithAuthData;
var requestUserById = function requestUserById(userId, requestData) {
  return __awaiter(void 0, void 0, void 0, function () {
    var url, method, result, user, err_2;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          url = (0, common_1.getApiDomain)() + "/v1.0/user/" + userId;
          method = _1.RequestMethods.GET;
          _a.label = 1;
        case 1:
          _a.trys.push([1, 3,, 4]);
          return [4 /*yield*/, (0, _1.request)(url, method, requestData)];
        case 2:
          result = _a.sent();
          if (result.data) {
            user = models_1.User.fromJson(result.data);
            return [2 /*return*/, new response_1.DataResponse(user)];
          }
          return [2 /*return*/, new response_1.DataResponse(undefined)];
        case 3:
          err_2 = _a.sent();
          throw err_2;
        case 4:
          return [2 /*return*/];
      }
    });
  });
};

exports.requestUserById = requestUserById;
var requestAgents = function requestAgents(requestData) {
  return __awaiter(void 0, void 0, void 0, function () {
    var query, updatedParams, updatedReqData;
    return __generator(this, function (_a) {
      query = {
        query: "{\"isAgent\": true}"
      };
      updatedParams = Object.assign({}, requestData.getParams(), query);
      updatedReqData = new data_1.AuthorizedRequestData(requestData.getAccessToken(), requestData.getHeaders(), updatedParams, requestData.getData(), requestData.getAxiosOptions());
      return [2 /*return*/, (0, exports.requestUsers)(updatedReqData)];
    });
  });
};
exports.requestAgents = requestAgents;
var requestAccounts = function requestAccounts(requestData) {
  return __awaiter(void 0, void 0, void 0, function () {
    var query, updatedReqData;
    return __generator(this, function (_a) {
      query = {
        query: "{\"userName\": {$ne: null}}"
      };
      updatedReqData = createReqDataWithUpdatedQueryParam(requestData, query);
      return [2 /*return*/, (0, exports.requestUsers)(updatedReqData)];
    });
  });
};
exports.requestAccounts = requestAccounts;
var requestAccountsForPartner = function requestAccountsForPartner(partnerId, requestData) {
  return __awaiter(void 0, void 0, void 0, function () {
    var query, updatedReqData;
    return __generator(this, function (_a) {
      query = {
        query: "{$and: [{\"userName\": {$ne: null}}, {\"partnerId\":\"" + partnerId + "\"}]}"
      };
      updatedReqData = createReqDataWithUpdatedQueryParam(requestData, query);
      return [2 /*return*/, (0, exports.requestUsers)(updatedReqData)];
    });
  });
};
exports.requestAccountsForPartner = requestAccountsForPartner;
var requestB2bAccounts = function requestB2bAccounts(requestData) {
  return __awaiter(void 0, void 0, void 0, function () {
    var query, updatedReqData;
    return __generator(this, function (_a) {
      query = {
        query: "{$and: [{\"userName\": {$ne: null} }, {\"claims\": {$elemMatch: {\"value\":\"b2b\"} } }]}"
      };
      updatedReqData = createReqDataWithUpdatedQueryParam(requestData, query);
      return [2 /*return*/, (0, exports.requestUsers)(updatedReqData)];
    });
  });
};
exports.requestB2bAccounts = requestB2bAccounts;
var requestClientAccounts = function requestClientAccounts(requestData) {
  return __awaiter(void 0, void 0, void 0, function () {
    var query, updatedReqData;
    return __generator(this, function (_a) {
      query = {
        query: "{$and: [ {\"userName\": {$ne: null} }, {\"claims\":  null } ]}"
      };
      updatedReqData = createReqDataWithUpdatedQueryParam(requestData, query);
      return [2 /*return*/, (0, exports.requestUsers)(updatedReqData)];
    });
  });
};
exports.requestClientAccounts = requestClientAccounts;
var createReqDataWithUpdatedQueryParam = function createReqDataWithUpdatedQueryParam(requestData, query) {
  var updatedParams = Object.assign({}, requestData.getParams(), query);
  return new data_1.AuthorizedRequestData(requestData.getAccessToken(), requestData.getHeaders(), updatedParams, requestData.getData(), requestData.getAxiosOptions());
};
var resetAccountPass = function resetAccountPass(accountId, password, requestData) {
  return __awaiter(void 0, void 0, void 0, function () {
    var url, method, reqData, data, err_3;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          url = (0, common_1.getApiDomain)() + "/v1.0/user/" + accountId + "/pass";
          method = _1.RequestMethods.POST;
          _a.label = 1;
        case 1:
          _a.trys.push([1, 3,, 4]);
          reqData = {
            password: password,
            confirm: password
          };
          data = new data_1.AuthorizedRequestData(requestData.getAccessToken(), {}, {}, reqData);
          return [4 /*yield*/, (0, _1.request)(url, method, data)];
        case 2:
          _a.sent();
          return [2 /*return*/, true];
        case 3:
          err_3 = _a.sent();
          common_2.Logger.d(err_3);
          return [2 /*return*/, false];
        case 4:
          return [2 /*return*/];
      }
    });
  });
};

exports.resetAccountPass = resetAccountPass;
var postUserRequest = function postUserRequest(requestData) {
  return __awaiter(void 0, void 0, void 0, function () {
    var url, method, response, err_4;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (!requestData.getLogin()) {
            common_2.Logger.d("User login must not be undefined");
            return [2 /*return*/, false];
          }
          url = (0, common_1.getApiDomain)() + "/v1.0/user/";
          method = _1.RequestMethods.POST;
          _a.label = 1;
        case 1:
          _a.trys.push([1, 3,, 4]);
          return [4 /*yield*/, (0, _1.request)(url, method, requestData)];
        case 2:
          response = _a.sent();
          if (response.data.id) {
            return [2 /*return*/, response.data.id];
          }
          return [2 /*return*/, false];
        case 3:
          err_4 = _a.sent();
          common_2.Logger.d(err_4);
          return [2 /*return*/, false];
        case 4:
          return [2 /*return*/];
      }
    });
  });
};

exports.postUserRequest = postUserRequest;
var putUserRequest = function putUserRequest(requestData) {
  return __awaiter(void 0, void 0, void 0, function () {
    var url, method, existedUserResult, existedData, orderData, data, err_5;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (!requestData.getId()) {
            common_2.Logger.d("User id must not be undefined");
            return [2 /*return*/, false];
          }
          url = (0, common_1.getApiDomain)() + "/v1.0/user/" + requestData.getId();
          method = _1.RequestMethods.PUT;
          _a.label = 1;
        case 1:
          _a.trys.push([1, 5,, 6]);
          return [4 /*yield*/, (0, _1.request)(url, _1.RequestMethods.GET, requestData)];
        case 2:
          existedUserResult = _a.sent();
          existedData = existedUserResult.data;
          if (!existedData) return [3 /*break*/, 4];
          existedData = clearClaimsIfNeeded(requestData, existedData);
          orderData = Object.assign({}, existedData, requestData.getData());
          data = new data_1.AuthorizedRequestData(requestData.getAccessToken(), {}, {}, orderData);
          return [4 /*yield*/, (0, _1.request)(url, method, data)];
        case 3:
          _a.sent();
          return [2 /*return*/, true];
        case 4:
          common_2.Logger.d("User not exist");
          return [2 /*return*/, false];
        case 5:
          err_5 = _a.sent();
          common_2.Logger.d(err_5);
          return [2 /*return*/, false];
        case 6:
          return [2 /*return*/];
      }
    });
  });
};

exports.putUserRequest = putUserRequest;
var clearClaimsIfNeeded = function clearClaimsIfNeeded(requestData, existedData) {
  var newData = requestData.getData();
  if (newData instanceof Object && existedData instanceof Object) {
    if (existedData.hasOwnProperty("claims") && !requestData.isB2BAccount()) {
      if (Array.isArray(existedData.claims)) {
        existedData.claims = existedData.claims.filter(function (item) {
          return item.value !== "b2b";
        });
      }
    }
  }
  return existedData;
};
var deleteUserRequest = function deleteUserRequest(userId, requestData) {
  return __awaiter(void 0, void 0, void 0, function () {
    var url, method, err_6;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          url = (0, common_1.getApiDomain)() + "/v1.0/user/" + userId;
          method = _1.RequestMethods.DELETE;
          _a.label = 1;
        case 1:
          _a.trys.push([1, 3,, 4]);
          return [4 /*yield*/, (0, _1.request)(url, method, requestData)];
        case 2:
          _a.sent();
          return [2 /*return*/, true];
        case 3:
          err_6 = _a.sent();
          common_2.Logger.d(err_6);
          return [2 /*return*/, false];
        case 4:
          return [2 /*return*/];
      }
    });
  });
};

exports.deleteUserRequest = deleteUserRequest;