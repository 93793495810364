import React from "react";
import MenuSidebarComponent from "../sidebar/MenuSidebarComponent";
import LogoutComponent from "./logout/LogoutComponent";
import AppVersionComponent from "../common/AppVersionComponent";
import NotificationBadgeComponent from "./notification/NotificationBadgeComponent";
import "./HeaderComponentStyle.scss";

const HeaderComponent = () => {
    return (
        <div className="header">
            <div className="inner-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-4 col-sm-2">
                            <div className="menu-field">
                                <MenuSidebarComponent/>
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-sm-3 offset-md-4 col-sm-4">
                            <AppVersionComponent/>
                        </div>
                        <div className="col-4 col-sm-3 col-md-2 offset-4 offset-sm-0">
                            <div className="d-inline-block mr-3"><NotificationBadgeComponent/></div>
                            <div className="d-inline-block"><LogoutComponent/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderComponent;
