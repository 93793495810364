"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DiscountRequestData = void 0;
var AuthorizedRequestData_1 = require("./AuthorizedRequestData");
var DiscountRequestData = /** @class */function (_super) {
  __extends(DiscountRequestData, _super);
  function DiscountRequestData(args) {
    var _this = _super.call(this, args.accessToken) || this;
    _this.arguments = args;
    return _this;
  }
  DiscountRequestData.prototype.getData = function () {
    var data = Object.assign({}, _super.prototype.getData.call(this), {
      code: this.arguments.promoCode,
      currency: this.arguments.currency.toString(),
      value: this.arguments.currencyValue
    });
    if (this.arguments.isLimited && this.arguments.limitPerAccount) {
      data = Object.assign({}, data, {
        limited: this.arguments.isLimited,
        countPerAccount: this.arguments.limitPerAccount
      });
    }
    if (this.arguments.isFinite && this.arguments.startDate && this.arguments.endDate) {
      data = Object.assign({}, data, {
        startDt: this.arguments.startDate,
        endDt: this.arguments.endDate
      });
    }
    if (this.arguments.referralId) {
      data = Object.assign({}, data, {
        referralId: this.arguments.referralId
      });
    } else {
      data = Object.assign({}, data, {
        referralId: ""
      });
    }
    if (this.arguments.comment) {
      data = Object.assign({}, data, {
        comment: this.arguments.comment
      });
    }
    if (this.arguments.discountId) {
      data = Object.assign({}, data, {
        id: this.arguments.discountId
      });
    }
    return data;
  };
  DiscountRequestData.prototype.getId = function () {
    return this.arguments.discountId;
  };
  return DiscountRequestData;
}(AuthorizedRequestData_1.AuthorizedRequestData);
exports.DiscountRequestData = DiscountRequestData;