import React from "react";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import ContentLoaderComponent from "../../common/ContentLoaderComponent";
import {getAccessToken} from "../../../helpers/RequestHelpers";
import {connect} from "react-redux";
import {importDirectionsAndPrice, ImportLogType} from "./direction_import_partials/ImportFromFile";
import {AuthorizedRequestData, Bind, IService, requestServices} from "@shift-mono/common";
import {Checkbox} from "primereact/checkbox";
import {InputText} from "primereact/inputtext";

interface IDestinationImportComponentProps {
    getToken: () => Promise<string>;
}

interface IDestinationImportComponentState {
    filename: string;
    destinationsFile: File | null;

    services: IService[];
    currentSelectedService?: IService;

    errorMessage: string | null;

    importLogs: { log: string, type: ImportLogType }[];
    importInProcess: boolean;
    importFinished: boolean;
    importFinishedWithError: boolean;

    importDirectionPricesEnabled: boolean;
    importDeliveryDurationEnabled: boolean;

    importOnlyMaxDurationEnabled: boolean;
    importWithDefaultDurationEnabled: boolean;
    deliveryDefaultDuration: number;

}

class DirectionsImportComponent extends React.Component<IDestinationImportComponentProps,
    IDestinationImportComponentState> {

    constructor(props: IDestinationImportComponentProps) {
        super(props);

        this.state = {
            filename: "",
            destinationsFile: null,
            services: [],
            errorMessage: null,
            importLogs: [],
            importInProcess: false,
            importFinished: false,
            importFinishedWithError: false,

            importDirectionPricesEnabled: true,
            importDeliveryDurationEnabled: true,

            importOnlyMaxDurationEnabled: false,
            importWithDefaultDurationEnabled: false,
            deliveryDefaultDuration: 5,

        }
    }
    @Bind()
    uploadHandler(event: React.ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;
        if (files) {
            const file = files[0];
            if (file) {
                this.setState({destinationsFile: file});
                this.setState({filename: file.name});
            }
        }
    }
    @Bind()
    serviceChanged(e: any) {
        this.setState({currentSelectedService: e.value});
    }
    @Bind()
    async getServicesRequest(): Promise<IService[]> {
        const token = await this.props.getToken();
        const requestData = new AuthorizedRequestData(token);
        try {
            return (await requestServices(requestData)).getData();
        } catch (err) {
            return [];
        }
    }
    @Bind()
    getServiceRequestResult(result: IService[]) {
        this.setState({services: result});
    }
    @Bind()
    renderServiceDropdown() {
        return (
            <div className="row m-1">
                <label className="col-lg-4">Услуга</label>
                <div className="col-lg-4 p-0">
                    <Dropdown
                        value={this.state.currentSelectedService}
                        options={
                            this.state.services
                        }
                        onChange={this.serviceChanged}
                        className="col-lg-12"
                        placeholder="Услуга"
                        optionLabel="name"
                    />
                </div>
            </div>
        );
    }
    @Bind()
    renderDeliveryDurationOptions() {
        return (<>
            <div className="row m-1">
                <label className="col-lg-4">Импортировать время доставки</label>
                <Checkbox
                    onChange={e => this.setState({importDeliveryDurationEnabled: e.checked})}
                    checked={this.state.importDeliveryDurationEnabled}
                />
            </div>
            {!this.state.importDeliveryDurationEnabled
                ? <></>
                : (<>
                    <div className="row m-1">
                        <label className="col-lg-4">Импортировать только время которое больше текущего</label>
                        <Checkbox
                            onChange={e => this.setState({importOnlyMaxDurationEnabled: e.checked})}
                            checked={this.state.importOnlyMaxDurationEnabled}
                        />
                    </div>

                    <div className="row m-1">
                        <label className="col-lg-4">Использовать значение по умолчанию для направлений с не указанным
                            сроком доставки</label>
                        <Checkbox
                            onChange={e => this.setState({importWithDefaultDurationEnabled: e.checked})}
                            checked={this.state.importWithDefaultDurationEnabled}
                        />
                    </div>
                    {this.state.importWithDefaultDurationEnabled
                        ? <div className="row m-1">
                            <label className="col-lg-4">Срок доставки по умолчанию</label>
                            <InputText
                                className="col-lg-4"
                                value={this.state.deliveryDefaultDuration}
                                keyfilter="int"
                                placeholder=""
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const days = Number.parseInt(e.target.value);
                                    if (!isNaN(days) && isFinite(days)) {
                                        this.setState({deliveryDefaultDuration: days});
                                    } else {
                                        this.setState({deliveryDefaultDuration: 0});
                                    }
                                }}
                            />
                        </div>
                        : <></>
                    }
                </>)
            }

        </>)
    }
    @Bind()
    renderInputForm() {
        return (
            <>
                <div className="row">
                    <div className="col-lg-12">
                        <ContentLoaderComponent<IService[]>
                            contentRequest={this.getServicesRequest}
                            resultCallback={this.getServiceRequestResult}
                            errorCallback={err => console.log(err)}
                        >

                            <form
                                className="card col-12 mt-2 p-2"
                                onSubmit={(e: any) => {
                                    e.preventDefault();
                                }}
                            >
                                {this.state.errorMessage
                                    ? (<div className="row m-1 ml-2" style={{marginTop: '2'}}>
                                        <div style={{
                                            color: 'red',
                                            fontWeight: 'bold'
                                        }}>{this.state.errorMessage}</div>
                                    </div>)
                                    : <></>}

                                <div className="row m-1">
                                    <label className="col-lg-4">Файл направлений</label>
                                    <div className="col-lg-4 p-0">
                                        <div className="custom-file">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="customFile"
                                                onChange={this.uploadHandler}
                                                accept=".csv"
                                            />
                                            <label className="custom-file-label">
                                                {this.state.filename}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {this.renderDeliveryDurationOptions()}

                                <div className="row m-1">
                                    <label className="col-lg-4">Импорт цен направлений в услугу</label>
                                    <Checkbox
                                        onChange={e => this.setState({importDirectionPricesEnabled: e.checked})}
                                        checked={this.state.importDirectionPricesEnabled}
                                    />
                                </div>
                                {this.state.importDirectionPricesEnabled ? this.renderServiceDropdown() : <></>}
                                <div className="row m-1">
                                    <span className="col-lg-12 " style={{fontSize: "1.25em"}}>Файл направления должен быть формата csv, состоять из 4 столбцов:</span>
                                    <span className="col-lg-12">Первый - название города <b>откуда</b> будет идти направление</span>
                                    <span className="col-lg-12">Второй - название города <b>куда</b> будет идти направление</span>
                                    <span className="col-lg-12">Третий - <b>цена</b> за это направление</span>
                                    <span className="col-lg-12">Четвертый - количество <b>дней</b> доставки</span>
                                </div>
                                <div className="row m-1 ml-2">
                                    <Button
                                        label="Импортировать"
                                        onClick={() => {
                                            const currentSelectedService = this.state.currentSelectedService;
                                            const destinationsFile = this.state.destinationsFile;

                                            if (
                                                (
                                                    !this.state.importDirectionPricesEnabled
                                                    || (this.state.importDirectionPricesEnabled && currentSelectedService)
                                                )
                                                && destinationsFile) {
                                                this.setState({
                                                    errorMessage: null,
                                                    importLogs: [],
                                                    importInProcess: true,
                                                    importFinished: false,
                                                    importFinishedWithError: false,
                                                });
                                                importDirectionsAndPrice(
                                                    destinationsFile,
                                                    this.state.importDirectionPricesEnabled ? currentSelectedService : undefined,
                                                    this.state.importDirectionPricesEnabled,
                                                    this.state.importDeliveryDurationEnabled,
                                                    this.state.importOnlyMaxDurationEnabled,
                                                    this.state.importWithDefaultDurationEnabled,
                                                    this.state.deliveryDefaultDuration,
                                                    this.props.getToken,
                                                    (log, type) => {
                                                        const logs = [...this.state.importLogs, {log, type}];

                                                        switch (type) {
                                                            case ImportLogType.Error:
                                                                this.setState({importFinishedWithError: true});
                                                                break;
                                                            case ImportLogType.Completed:
                                                                this.setState({importFinished: true});
                                                                break;
                                                        }

                                                        this.setState({importLogs: logs})

                                                    }
                                                )
                                            } else {
                                                this.setState({errorMessage: "Не все поля заполнены!"})
                                            }
                                        }}
                                    />
                                </div>
                            </form>
                        </ContentLoaderComponent>
                    </div>
                </div>
            </>
        );
    }
    @Bind()
    renderImportLogs() {
        const getLogColor = (type: ImportLogType): string => {
            switch (type){
                case ImportLogType.Warning:
                    return '#665500'
                case ImportLogType.Completed:
                    return 'green';
                case ImportLogType.Error:
                    return 'red';
                case ImportLogType.InProgress:
                    return '#4BAED0';
                default:
                    return '#4BAED0';
            }
        }
        return (
            <>
                <div className="row">
                    <div className="col-lg-12 card mt-2 p-2">
                        {this.state.importLogs.map(({log, type}, index) => (
                            <div className="row m-1 ml-2" style={{marginTop: '2'}} key={index}>
                                <div style={{
                                    color: getLogColor(type),
                                    fontWeight: 'bold'
                                }}>{log}</div>
                            </div>))}
                        {(this.state.importFinished || this.state.importFinishedWithError)
                            ? (<div className="row m-1 ml-2">
                                <Button
                                    label="Ок"
                                    onClick={() => {
                                        this.setState({
                                            importLogs: [],
                                            importInProcess: false,
                                            importFinished: false,
                                            importFinishedWithError: false,
                                        });
                                    }}
                                />
                            </div>)
                            : <></>}
                    </div>
                </div>
            </>
        );
    }

    render() {
        return this.state.importInProcess
            ? this.renderImportLogs()
            : this.renderInputForm();
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(DirectionsImportComponent);
