import React from "react";
import "./TagInputStyle.scss";
import {Bind} from "@shift-mono/common";

interface ITagInputProps {
  initTags?: string[];
  changeHandler?: (tags: string[]) => void;
}
interface ITagInputState {
  firstInit: boolean;

  tags: string[];
}

export default class TagInput extends React.Component<
  ITagInputProps,
  ITagInputState
> {
  private tagInputRef: any = undefined;

  constructor(props: ITagInputProps) {
    super(props);

    this.state = {
      firstInit: true,
      tags: props.initTags ? props.initTags : []
    };
  }
  @Bind()
  clearAllTags() {
    this.setState({ tags: [] });
  }
  @Bind()
  removeTag(i: number) {
    const newTags = [...this.state.tags];
    newTags.splice(i, 1);
    this.setState({ tags: newTags });
    if (this.props.changeHandler) {
      this.props.changeHandler(newTags);
    }
  };
  @Bind()
  inputKeyDown(e: any) {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      if (
        this.state.tags.find(tag => tag.toLowerCase() === val.toLowerCase())
      ) {
        e.preventDefault();
        return;
      }
      const newTags = [...this.state.tags, val];
      this.setState({ tags: newTags });
      this.tagInputRef.value = null;
      if (this.props.changeHandler) {
        this.props.changeHandler(newTags);
      }
      e.preventDefault();
    } else if (e.key === "Backspace" && !val) {
      this.removeTag(this.state.tags.length - 1);
    }
  };

  shouldComponentUpdate(nextProps: ITagInputProps, nextState: ITagInputState) {
    if (nextProps.initTags !== undefined && this.state.firstInit) {
      nextState.tags = nextProps.initTags;
      nextState.firstInit = false;
    }

    return true;
  }

  render() {
    return (
      <div className="input-tag">
        <ul className="input-tag__tags">
          {this.state.tags.map((tag, i) => (
            <li key={tag}>
              {tag}
              <button
                type="button"
                onClick={() => {
                  this.removeTag(i);
                }}
              >
                +
              </button>
            </li>
          ))}
          <li className="input-tag__tags__input">
            <input
              type="text"
              onKeyDown={this.inputKeyDown}
              ref={c => {
                this.tagInputRef = c;
              }}
            />
          </li>
        </ul>
      </div>
    );
  }
}
