"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DirectionCost = void 0;
//TODO Наследовать от Model
//TODO Добавить конвертацию из json
var DirectionCost = /** @class */function () {
  function DirectionCost(id, cost) {
    this.id = id;
    this.cost = cost;
  }
  DirectionCost.prototype.getId = function () {
    return this.id;
  };
  DirectionCost.prototype.getCost = function () {
    return this.cost;
  };
  return DirectionCost;
}();
exports.DirectionCost = DirectionCost;