"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ServiceType = void 0;
var ServiceType;
(function (ServiceType) {
  ServiceType["Common"] = "COMMON";
  ServiceType["Move"] = "MOVE";
  ServiceType["Luggage"] = "LUGGAGE";
  ServiceType["Store"] = "STORE";
  ServiceType["Other"] = "OTHER";
})(ServiceType = exports.ServiceType || (exports.ServiceType = {}));