import React from "react";
import {Column} from "primereact/column";
import {
    AuthorizedRequestData,
    Bind,
    DataResponse,
    IDataResponse,
    IWithdrawal,
    Logger,
    requestWithdrawal
} from "@shift-mono/common";
import {DataTable} from "primereact/datatable";
import ContentLoaderComponent from "../../../../common/ContentLoaderComponent";
import {getAccessToken} from "../../../../../helpers/RequestHelpers";
import {connect} from "react-redux";

interface IClientWithdrawalListProps {
    getToken: () => Promise<string>;
    clientId: string;
}

interface IClientWithdrawalListState {
    withdrawals: IWithdrawal[];
}

class ClientWithdrawalList extends React.Component<IClientWithdrawalListProps, IClientWithdrawalListState> {

    constructor(props: IClientWithdrawalListProps) {
        super(props);

        this.state = {
            withdrawals: []
        }
    }

    @Bind()
    async getWithdrawalRequest(): Promise<IDataResponse<IWithdrawal[]>> {
        try {
            const token = await this.props.getToken();
            let clientId = this.props.clientId;
            const params = {
                query: "{userId: '" + clientId + "'}"
            }
            const requestData = new AuthorizedRequestData(token, {}, params);

            return requestWithdrawal(requestData);
        } catch (err) {
            return new DataResponse<IWithdrawal[]>([]);
        }
    }

    @Bind()
    withdrawalRequestResult(result: IDataResponse<IWithdrawal[]>) {
        this.setState({
            withdrawals: result.getData()
        })
    }

    @Bind()
    renderTable() {
        if(this.state.withdrawals.length === 0) {
            return <>Нет заявок на вывод средств</>
        }

        return <DataTable
            className="main_list_table mb-3"
            value={this.state.withdrawals}
            paginator={true}
            rows={10}
        >
            <Column
                body={(withdrawal: IWithdrawal) => {
                    return withdrawal.getAmount();
                }}
                header="Сумма вывода"
            />

            <Column
                body={(withdrawal: IWithdrawal) => {
                    return withdrawal.isCompleted() ? "Да" : "Нет";
                }}
                header="Выполнено"
                style={{width: "25%", textAlign: "center"}}
            />
        </DataTable>
    }

    render() {
        return <ContentLoaderComponent<IDataResponse<IWithdrawal[]>>
            contentRequest={this.getWithdrawalRequest}
            resultCallback={this.withdrawalRequestResult}
            errorCallback={(err) => {
                Logger.d(err);
            }}
        >
            {this.renderTable()}
        </ContentLoaderComponent>

    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(ClientWithdrawalList);