"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adminStatusTitle = exports.getOrderStatusTitle = exports.OrderStatusEnv = void 0;
var models_1 = require("../models");
var OrderStatusEnv;
(function (OrderStatusEnv) {
  OrderStatusEnv[OrderStatusEnv["ADMIN"] = 0] = "ADMIN";
  OrderStatusEnv[OrderStatusEnv["B2B"] = 1] = "B2B";
})(OrderStatusEnv = exports.OrderStatusEnv || (exports.OrderStatusEnv = {}));
var getOrderStatusTitle = function getOrderStatusTitle(status, statusEnv) {
  if (statusEnv === void 0) {
    statusEnv = OrderStatusEnv.ADMIN;
  }
  switch (statusEnv) {
    case OrderStatusEnv.ADMIN:
      return (0, exports.adminStatusTitle)(status);
    case OrderStatusEnv.B2B:
      return b2bStatusTitle(status);
  }
};
exports.getOrderStatusTitle = getOrderStatusTitle;
var adminStatusTitle = function adminStatusTitle(status) {
  switch (status) {
    case models_1.OrderStatus.New:
      return "Оплачен";
    case models_1.OrderStatus.Pending:
      return "Заказ ожидает оплаты";
    case models_1.OrderStatus.CourierDelivery:
      return "Ожидает сдачи багажа";
    case models_1.OrderStatus.TransferredDelivery:
      return "Передано в доставку";
    case models_1.OrderStatus.WaitingDelivery:
      return "Ожидает вручения";
    case models_1.OrderStatus.Complete:
      return "Завершен";
    case models_1.OrderStatus.Issued:
      return "Неизвестен";
    case models_1.OrderStatus.Cancelled:
      return "Отменен";
    case models_1.OrderStatus.ErrorPayments:
      return "Ошибка платежа";
    case models_1.OrderStatus.Storage:
      return "Хранение";
  }
};
exports.adminStatusTitle = adminStatusTitle;
var b2bStatusTitle = function b2bStatusTitle(status) {
  switch (status) {
    case models_1.OrderStatus.New:
      return "Оплачен";
    case models_1.OrderStatus.Pending:
      return "Заказ ожидает оплаты";
    case models_1.OrderStatus.CourierDelivery:
      return "Ожидает сдачи багажа";
    case models_1.OrderStatus.TransferredDelivery:
      return "Передано в доставку";
    case models_1.OrderStatus.WaitingDelivery:
      return "Ожидает вручения";
    case models_1.OrderStatus.Complete:
      return "Завершен";
    case models_1.OrderStatus.Issued:
      return "Неизвестен";
    case models_1.OrderStatus.Cancelled:
      return "Отменен";
    case models_1.OrderStatus.ErrorPayments:
      return "Ошибка платежа";
    case models_1.OrderStatus.Storage:
      return "Хранение";
  }
};