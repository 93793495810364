import React, { useState, useEffect, useMemo } from "react";
import MUIDataTable from "mui-datatables";
import { getAccessToken } from "../../../../helpers/RequestHelpers";
import { connect } from "react-redux";
import useQuery from "../../../../hooks/useQuery";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { FormGroup, FormLabel, TextField } from "@material-ui/core";

function ClientsTableComponent(props: any) {
  const { url, title } = props;
  const [data, setData] = useState<any>([]);
  const StoragePointIdsForDelete: any = [];
  const query = useQuery();
  const queryObject: any = query.getObject();
  const history = useHistory();

  const checkPageSize = (object: { pageSize: any }) => {
    return object?.pageSize;
  };
  const pageSize = useMemo(() => checkPageSize(queryObject), [queryObject]);

  const checkPage = (object: { page: any }) => {
    return object?.page;
  };
  const page = useMemo(() => checkPage(queryObject), [queryObject]);
  const checkSearch = (object: { searchText: any }) => {
    return object?.searchText ? object?.searchText : "";
  };
  const searchText = useMemo(() => checkSearch(queryObject), [queryObject]);
  useEffect(() => {
    props.getToken();

    var url = "/v1.0/client?query=%7B%7D";
    const accessToken = localStorage.getItem("accessToken");
    var headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    fetch(process.env.REACT_APP_API_DOMAIN + url, {
      headers: headers,
    })
      .then((res) => {
        if (res.status === 401) {
          localStorage.clear();
          window.location.replace("/auth");
          throw res;
        }
        return res;
      })

      .then((res) => res.json())
      .then((data) => {
        const newData = data.map((element: any) => {
          return {
            phoneNumber: element.phoneNumber,
            fio:
              element.secondName +
              " " +
              element.firstName +
              " " +
              element.thirdName,
            email: element.email,
            status: element.isAgent ? "Агент" : "Пользователь",
            id: element.id,
            createdOn: element.createdOn,
          };
        });

        setData(newData);
      });
  }, [props]);

  function prepareData(url: string) {
    var columns = [
      {
        name: "phoneNumber",
        label: "Телефон",
        options: {
          filter: true,
          sort: true,
          filterType: "textField",
          filterList: queryObject.phoneNumber ? [queryObject.phoneNumber] : [],
        },
      },
      {
        name: "fio",
        label: "ФИО",
        options: {
          filter: true,
          sort: true,
          filterType: "textField",
          filterList: queryObject.fio ? [queryObject.fio] : [],
        },
      },
      {
        name: "email",
        label: "Почта",
        options: {
          filter: true,
          sort: true,
          filterType: "textField",
          filterList: queryObject.email ? [queryObject.email] : [],
        },
      },
      {
        name: "status",
        label: "Статус",
        options: {
          filter: true,
          sort: true,
          filterList: queryObject.status ? [queryObject.status] : [],
        },
      },
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },
      {
        name: "createdOn",
        label: "Дата создания",
        options: {
          filter: true,
          sort: true,
          filterList: (() => {
            const list: any = [];
            if (queryObject.createdOnStart) {
              list[0] = queryObject.createdOnStart;
            }
            if (queryObject.createdOnEnd) {
              list[1] = queryObject.createdOnEnd;
            }
            return list;
          })(),
          //sortDirection: 'desc',
          customBodyRender: (value: string | number | Date) =>
            moment(new Date(value)).format("DD.MM.YYYY"),
          filterType: "custom" as any,
          customFilterListOptions: {
            render: (v: any) => {
              if (v[0] && v[1]) {
                return [`Oт: ${v[0]}`, `До: ${v[1]}`];
              } else if (v[0]) {
                return `Oт: ${v[0]}`;
              } else if (v[1]) {
                return `До: ${v[1]}`;
              }
              return false;
            },
            update: (filterList: any, filterPos: any, index: any) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, "");
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
          filterOptions: {
            names: [],
            logic(date: moment.MomentInput, filters: any[]): boolean {
              const check: any = moment(date, "DD.MM.YYYY HH:mm");
              const from: any = moment(filters[0], "YYYY-MM-DD");
              const to: any = moment(filters[1], "YYYY-MM-DD");
              if (
                filters[0] &&
                filters[1] &&
                check.diff(to, "days") > 0 &&
                check.diff(from, "days") < 0
              ) {
                return true;
              } else if (filters[1] && check.diff(to, "days") > 0) {
                return true;
              } else if (filters[0] && check.diff(from, "days") < 0) {
                return true;
              }
              return false;
            },
            display: (
              filterList: { [x: string]: any },
              onChange: (arg0: any, arg1: any, arg2: any) => void,
              index: string | number,
              column: any
            ): JSX.Element => (
              <div>
                <FormLabel style={{ fontSize: 15 }}>Дата создания</FormLabel>
                <FormGroup row>
                  <TextField
                    id="startDate"
                    label="Дата начала"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][0] || ""}
                    onChange={(event) => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: "45%", marginRight: "5%", marginTop: "3%" }}
                  />
                  <TextField
                    id="endDate"
                    label="Дата окончания:"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][1] || ""}
                    onChange={(event) => {
                      filterList[index][1] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: "45%", marginRight: "5%", marginTop: "3%" }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
    ];

    return columns;
  }

  var columns: any = prepareData(url);

  const options: any = {
    filter: true,
    selectableRows: false,
    filterType: "dropdown",
    responsive: "vertical",
    searchText: searchText,
    page: page ? parseInt(page) - 1 : 0,
    draggableColumns: {
      enabled: true,
    },
    rowsPerPage: pageSize || 10,
    // rowsPerPageOptions: [1, 10, 100],
    downloadOptions: {
      filename: "excel-format.csv",
      separator: ";",
      // utf8WithBom: true,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: (
      buildHead: (arg0: any) => string,
      buildBody: (arg0: any) => string,
      columns: any,
      data: any
    ) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    onRowSelectionChange: (
      currentRowsSelected: any,
      allRows: any,
      rowsSelected: any
    ) => {
      rowsSelected.forEach((element: string | number) => {
        StoragePointIdsForDelete.push(data[element].id);
      });
    },

    onChangeRowsPerPage: (num: any) => {
      query.set("pageSize", num.toString());
      window.history.pushState(null, "", `?${query.toString()}`);
    },
    onChangePage: (numberRows: any) => {
      query.set("page", (numberRows + 1).toString());
      window.history.pushState(null, "", `?${query.toString()}`);
      //var element = document.querySelector("#pagination-rows")?.innerHTML
    },

    onFilterChange(
      changedColumn: string,
      filterList: { [x: string]: string[] },
      type: any,
      changedColumnIndex: string | number
    ) {
      switch (changedColumn) {
        case "createdOn": {
          query.delete("createdOnStart");
          query.delete("createdOnEnd");

          if (filterList[changedColumnIndex][0]) {
            query.set("createdOnStart", filterList[changedColumnIndex][0]);
          }

          if (filterList[changedColumnIndex][1]) {
            query.set("createdOnEnd", filterList[changedColumnIndex][1]);
          }
          break;
        }

        default:
          query.delete(changedColumn);
          if (filterList[changedColumnIndex][0]) {
            query.set(changedColumn, filterList[changedColumnIndex][0]);
          }
          break;
      }
      window.history.pushState(null, "", `?${query.toString()}`);
    },

    onRowClick: (rowData: any, rowState: any) => {
      const cleintId = rowData[4];
      history.push(`/users/${cleintId}`);
      //window.location.href = url
    },
    onSearchChange: (searchText: any) => {
      if (searchText) {
        query.set("searchText", searchText.toString());
      } else {
        query.delete("searchText");
      }
      window.history.pushState(null, "", `?${query.toString()}`);
    },
    textLabels: {
      body: {
        noMatch: "К сожалению, подходящие записи не найдены",
        toolTip: "Сортировать",
        columnHeaderTooltip: (column: any) => `Сортировать по ${column.label}`,
      },
      pagination: {
        next: "Следующая Страница",
        previous: "предыдущий",
        rowsPerPage: "Рядов на странице:",
        displayRows: "из",
      },
      toolbar: {
        search: "Поиск",
        downloadCsv: "Скачать CSV",
        print: "Распечатать",
        viewColumns: "Просмотр столбцов",
        filterTable: "Таблица фильтров",
      },
      filter: {
        all: "Все",
        title: "ФИЛЬТРЫ",
        reset: "Сброс",
      },
      viewColumns: {
        title: "Показать столбцы",
        titleAria: "Показать/скрыть столбцы таблицы",
      },
      selectedRows: {
        text: "строк(и) выбраны",
        delete: "Удалить",
        deleteAria: "Удалить выбранные строки",
      },
    },
  };

  return (
    <React.Fragment>
      {/* <img src = {image} alt='something'/>  */}
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={options}
      />
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getToken: () => dispatch(getAccessToken()),
  };
};

export default connect(null, mapDispatchToProps)(ClientsTableComponent);
