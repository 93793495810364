import React from "react";
import {Growl} from "primereact/growl";
import {connect} from "react-redux";
import ContentLoaderComponent from "../../common/ContentLoaderComponent";
import {getAccessToken} from "../../../helpers/RequestHelpers";
import StoragePointFormComponent, {
    IStoragePointFormData
} from "./form_parts/StoragePointFormComponent";
import {
    AuthorizedRequestData,
    requestStoragePointById,
    requestServices,
    putStoragePointRequest,
    StoragePointRequestData,
    postImage,
    requestDestinations,
    IStoragePoint,
    StoragePointLocation,
    IService,
    ICountableResponse,
    IDestination,
    CountableResponse, Bind, IPartner, requestPartners
} from "@shift-mono/common";

interface IStoragePointDetailComponentProps {
    getToken: () => Promise<string>;
    match: any;
}

interface IStoragePointDetailComponentState {
    currentStoragePoint: IStoragePoint | undefined;
    services: IService[];
    destinations: IDestination[];
    partners: IPartner[];
}

class StoragePointDetailComponent extends React.Component<IStoragePointDetailComponentProps,
    IStoragePointDetailComponentState> {
    private growl: any = undefined;

    constructor(props: IStoragePointDetailComponentProps) {
        super(props);

        this.state = {
            currentStoragePoint: undefined,
            services: [],
            destinations: [],
            partners: [],
        };
    }

    @Bind()
    async storagePointRequest(): Promise<IStoragePoint | undefined> {
        const token = await this.props.getToken();
        const requestData = new AuthorizedRequestData(token);
        const id = this.props.match.params.id;

        return (await requestStoragePointById(id, requestData)).getData();
    }

    @Bind()
    storagePointRequestResult(storagePoint: IStoragePoint | undefined) {
        this.setState({currentStoragePoint: storagePoint});
    }

    @Bind()
    async getServicesRequest(): Promise<IService[]> {
        const token = await this.props.getToken();
        const requestData = new AuthorizedRequestData(token);
        try {
            return (await requestServices(requestData)).getData();
        } catch (err) {
            return [];
        }
    }

    @Bind()
    getServiceRequestResult(result: IService[]) {
        this.setState({services: result});
    }

    @Bind()
    async getDestinationsRequest(): Promise<ICountableResponse<IDestination[]>> {
        const token = await this.props.getToken();
        const requestData = new AuthorizedRequestData(token, {}, {skip: 0, limit: 0});
        try {
            return await requestDestinations(requestData);
        } catch (err) {
            return new CountableResponse([], 0);
        }
    }

    @Bind()
    getDestinationsRequestResult(destinationsResponse: ICountableResponse<IDestination[]>) {
        this.setState({destinations: destinationsResponse.getData()})
    }

    @Bind()
    async partnersRequest(): Promise<IPartner[]> {
        const token = await this.props.getToken();
        const requestData = new AuthorizedRequestData(token);
        try {
            return (await requestPartners(requestData)).getData();
        } catch (err) {
            return [];
        }
    }

    @Bind()
    partnersRequestResult(partners: IPartner[]) {
        this.setState({partners})
    }

    @Bind()
    validateForm(formData: IStoragePointFormData): boolean {
        if (formData.name.trim() === "" ||
            formData.reservedCellCount === 0 ||
            formData.destinationId.trim() === ""
        ) {
            return false;
        }

        return true;
    }

    @Bind()
    async updateStoragePoint(formData: IStoragePointFormData) {
        try {
            if (this.validateForm(formData) && this.state.currentStoragePoint) {
                const token = await this.props.getToken();

                const id = this.state.currentStoragePoint.getId();
                let imageURLs = this.state.currentStoragePoint.getImages();

                if (formData.imageFile) {
                    const imageResult = await postImage(token, formData.imageFile!);
                    if (imageResult === null) {
                        this.growl.show({
                            severity: "error",
                            summary: "Ошибка",
                            detail: "Не удалось загрузить изображение"
                        });
                        return;
                    }
                    imageURLs = [imageResult.url];
                }
                const requestData = new StoragePointRequestData(
                    {
                        storagePointId: id,
                        accessToken: token,
                        active: formData.active,
                        name: formData.name,
                        localizedName: formData.localizedName,
                        address: formData.address,
                        imagesURL: imageURLs,
                        reservedCellCount: formData.reservedCellCount,
                        serviceIds: formData.selectedAvailableServiceIds,
                        location: new StoragePointLocation(formData.latitude, formData.longitude),
                        selfService: formData.selfServiceAvailable,
                        canStore: formData.canStore,
                        couriers: formData.couriersAvailable,
                        destinationId: formData.destinationId,
                        storagePointType: formData.type,
                        partnerId: formData.partnerId
                    }
                );

                const result = await putStoragePointRequest(requestData);

                if (result) {
                    this.growl.show({
                        severity: "success",
                        summary: "Успешно",
                        detail: "Пункт хранения обновлен"
                    });
                } else {
                    this.growl.show({
                        severity: "error",
                        summary: "Ошибка",
                        detail: "Ошибка добавления"
                    });
                }
            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Не все поля заполнены"
                });
            }
        } catch (err) {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка добавления"
            });
        }
    }
    backButtonClick = () => {
        // Your custom logic here
        window.close()
      }
    render() {
        return (
            <>
                <Growl ref={el => (this.growl = el)}/>
                <ContentLoaderComponent<IService[]>
                    contentRequest={this.getServicesRequest}
                    resultCallback={this.getServiceRequestResult}
                    errorCallback={err => console.log(err)}
                >
                    <ContentLoaderComponent<ICountableResponse<IDestination[]>>
                        contentRequest={this.getDestinationsRequest}
                        resultCallback={this.getDestinationsRequestResult}
                        errorCallback={err => console.log(err)}
                    >
                        <ContentLoaderComponent<IStoragePoint | undefined>
                            contentRequest={this.storagePointRequest}
                            resultCallback={this.storagePointRequestResult}
                        >
                            <ContentLoaderComponent<IPartner[]>
                                contentRequest={this.partnersRequest}
                                resultCallback={this.partnersRequestResult}
                            >
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h3>
                                        <span className="back-btn" onClick={this.backButtonClick}>&#x2190;</span>
                                        {/* <BackButtonComponent/> Информация оs ПВЗ / Отеле */}
                                        Информация оs ПВЗ / Отеле
                                        </h3>
                                    </div>
                                </div>

                                <StoragePointFormComponent
                                    currentStoragePoint={this.state.currentStoragePoint}
                                    saveCallback={(formData: IStoragePointFormData) => {
                                        this.updateStoragePoint(formData);
                                    }}
                                    availableSevices={this.state.services}
                                    availableDestinations={this.state.destinations}
                                    availablePartners={this.state.partners}
                                />
                            </ContentLoaderComponent>
                        </ContentLoaderComponent>
                    </ContentLoaderComponent>
                </ContentLoaderComponent>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(StoragePointDetailComponent);
