"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RequestData = void 0;
var RequestData = /** @class */function () {
  function RequestData(headers, params, data, axios_options) {
    if (headers === void 0) {
      headers = {};
    }
    if (params === void 0) {
      params = {};
    }
    if (data === void 0) {
      data = {};
    }
    if (axios_options === void 0) {
      axios_options = {};
    }
    this.headers = headers;
    this.params = params;
    this.axios_options = axios_options;
    this.data = data;
  }
  RequestData.prototype.getHeaders = function () {
    return this.headers;
  };
  RequestData.prototype.getAxiosOptions = function () {
    return this.axios_options;
  };
  RequestData.prototype.getParams = function () {
    return this.params;
  };
  RequestData.prototype.getData = function () {
    return this.data;
  };
  return RequestData;
}();
exports.RequestData = RequestData;