import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  AuthorizedRequestData,
  CountableResponse,
  IClient,
  ICountableResponse,
  IUser,
  Logger,
  requestB2bAccounts,
  requestUsers,
  requestClientAccounts,
} from "@shift-mono/common";
import ContentLoaderComponent from "../../../common/ContentLoaderComponent";
import { getAccessToken } from "../../../../helpers/RequestHelpers";
import { calcSkipAndLimit } from "../../../../helpers/PaginationHelpers";
import { Link } from "react-router-dom";
import RouteURLs from "../../../../actions/routesURL";
import { Button } from "primereact/button";
import { SearchComponent } from "../clients/partials/SearchComponent";
import useQuery from "../../../../hooks/useQuery";

interface IAccountsListComponentProps {
  getToken: () => Promise<string>;
  listType: AccountsListType;
}

interface IAccountsListComponentState {
  accounts: IUser[];
  accountsCount: number;
  paginationListRows: number;
  firstPaginationIndex: number;
  loading: boolean;
  searchVal: string | undefined;
}

export enum AccountsListType {
  Clients,
  B2b,
}

function AccountsListComponent(props: IAccountsListComponentProps) {
  let loaderRef: any = undefined;
  const query = useQuery();
  const queryObject: any = query.getObject();
  const checkSearchText = (object: { searchText: any }) => {
    return object?.searchText;
  };
  const querySearchText = useMemo(() => checkSearchText(queryObject), [queryObject]);
  const [accounts, setAccounts] = useState<IUser[]>([]);
  const [accountsCount, setAccountsCount] = useState<number>(0);
  const paginationListRows:number= 0;
  const [firstPaginationIndex, setFirstPaginationIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchVal, setSearchVal] = useState<string | undefined>(
    querySearchText
  );

  const getAccountRequestFunc = () => {
    return props.listType === AccountsListType.B2b
      ? requestB2bAccounts
      : requestClientAccounts;
  };

  const getAccountsRequest = async () => {
    try {
      const token = await props.getToken();
      let currentAccountsCount = accountsCount;

      currentAccountsCount = await getAccountsCount();

      const startIndex = 0;
      let params = calcSkipAndLimit(
        startIndex,
        currentAccountsCount,
        paginationListRows
      );
      // params = Object.assign({}, params, this.composeFilterRequestParams());

      //params = Object.assign({}, params, this.composeQueryRequestParams());
      if (composeQueryRequestParams()) {
        let params = calcSkipAndLimit(0, 0, paginationListRows);
        params = Object.assign({}, params, composeQueryRequestParams());
        const requestData = new AuthorizedRequestData(token, {}, params);
        //const requestData = new AuthorizedRequestData(token, {}, params);
        const clientsResponse = await requestUsers(requestData);
        const requestUserType = getAccountRequestFunc();
        if (
          requestUserType.name === "requestClientAccounts" ||
          searchVal != null
        ) {
          return clientsResponse;
        } else {
          return getAccountRequestFunc()(requestData);
        }
      }
      const requestData = new AuthorizedRequestData(token, {}, params);
      //const requestData = new AuthorizedRequestData(token, {}, params);
      const clientsResponse = await requestUsers(requestData);
      const requestUserType = getAccountRequestFunc();
      if (
        requestUserType.name === "requestClientAccounts" ||
        searchVal != null
      ) {
        return clientsResponse;
      } else {
        return getAccountRequestFunc()(requestData);
      }
    } catch (err) {
      return new CountableResponse<IUser[]>([], 0);
    }
  };

  const composeFilterRequestParams = () => {
    return {};
  };
  const composeQueryRequestParams = () => {
    const queryObjs = [];

    //   if (this.props.filterParams) {
    //     switch (this.props.filterParams!.agentStatus) {
    //       case true:
    //         queryObjs.push({ isAgent: true });
    //         break;
    //       case false:
    //         queryObjs.push({ $or: [{ isAgent: false }, { isAgent: null }] });
    //         break;
    //     }
    //   }

    if (searchVal) {
      const searchReg = searchVal
        .trim()
        .replace("+", "\\+")
        .split(" ")
        .join("|");
      //   const userType = this.getAccountRequestFunc()
      // //   if(userType.name =="RequestB2BAccount")
      queryObjs.push({
        $or: [
          { userName: { $regex: searchReg, $options: "i" } },
          // { firstName: { $regex: searchReg, $options: "i" } },
          // { secondName: { $regex: searchReg, $options: "i" } },
          // { thirdName: { $regex: searchReg, $options: "i" } },
        ],
      });
      // queryObjs.push({
      //     $and: [
      //         {"userName": {$ne: null} }, {"claims": {$elemMatch: {"value":"b2b"} } }]
      //   });
    }

    const result = queryObjs.length > 0 ? { $and: queryObjs } : {};
    return { query: JSON.stringify(result) };
  };

  const accountsRequestResult = (users: ICountableResponse<IUser[]>) => {
    setAccounts(users.getData().reverse());
    setLoading(false);
    setAccountsCount(users.getObjectsCount());
  };

  const getAccountsCount = async () => {
    const token = await props.getToken();
    let params = {
      skip: 0,
      limit: 1,
    };
    params = Object.assign({}, params, composeFilterRequestParams());
    const mockRequestData = new AuthorizedRequestData(token, {}, params);

    try {
      const accountsResponse = await getAccountRequestFunc()(mockRequestData);
      return accountsResponse.getObjectsCount();
    } catch (err) {
      return 0;
    }
  };

  const onPage = async (event: any) => {
    setLoading(false);

    const token = await props.getToken();
    let currentAccountsCount = accountsCount;

    currentAccountsCount = await getAccountsCount();

    const startIndex = event.first;
    let params = calcSkipAndLimit(
      event.first,
      currentAccountsCount,
      paginationListRows
    );
    params = Object.assign({}, params, composeFilterRequestParams());

    const requestData = new AuthorizedRequestData(token, {}, params);
    try {
      const accountsResponse = await getAccountRequestFunc()(requestData);
      setFirstPaginationIndex(startIndex);
      setAccounts(accountsResponse.getData().reverse());
      setLoading(false);
      setAccountsCount(accountsResponse.getObjectsCount());
    } catch (err) {
      return new CountableResponse<IClient[]>([], 0);
    }
  };

  const actionTemplate = (rowData: IClient, column: any) => {
    return (
      <>
        <Link to={RouteURLs.users + RouteURLs.accounts + "/" + rowData.getId()}>
          <Button
            type="button"
            icon="pi pi-file-o"
            className="p-button-info mr-3"
          />
        </Link>
      </>
    );
  };
  const reloadContent = () => {
    if (loaderRef !== undefined) {
      loaderRef.tryToLoading();
    }
  };
  return (
    <ContentLoaderComponent<ICountableResponse<IUser[]>>
      contentRequest={getAccountsRequest}
      resultCallback={accountsRequestResult}
      errorCallback={(err) => {
        Logger.d(err);
      }}
      ref={(el) => {
        loaderRef = el;
      }}
    >
      <SearchComponent
        style={{ marginBottom: "10px" }}
        searchValue={searchVal}
        onChangeHandler={(searchVal) => {
          setSearchVal(searchVal);
        }}
        searchCallback={(searchVal) => {
          setSearchVal(searchVal);
          searchVal
            ? query.set("searchText", searchVal)
            : query.delete("searchText");
          window.history.pushState(null, "", `?${query.toString()}`);
          reloadContent();
        }}
      />
      <DataTable
        className="main_list_table mb-3"
        value={accounts}
        paginator={true}
        totalRecords={accountsCount}
        rows={paginationListRows}
        first={firstPaginationIndex}
        lazy={true}
        loading={loading}
        onPage={onPage}
      >
        <Column
          body={(user: IUser) => {
            return (
              <>
                <div>Логин: {user.getUsername()}</div>
              </>
            );
          }}
          header="Информация"
          // style={{width: "75%"}}
        />

        <Column body={actionTemplate} style={{ width: "4em" }} />
      </DataTable>
    </ContentLoaderComponent>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getToken: () => dispatch(getAccessToken()),
  };
};

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(
  AccountsListComponent
);
