import React from "react";
import AddNewsComponent from "./AddNewsComponent";
import NewsListComponent from "./NewsListComponent";
import TabMenuComponent, {ITabItem} from "../../common/TabMenu/TabMenuComponent";

interface INewsComponentProps {
}

interface INewsComponentState {
    items: ITabItem[];
}

export class NewsComponent extends React.Component<INewsComponentProps,
    INewsComponentState> {
    constructor(props: INewsComponentProps) {
        super(props);

        this.state = {
            items: [
                {
                    id: 1,
                    label: "Список новостей",
                    component: <NewsListComponent/>,
                },
                {
                    id: 2,
                    label: "Добавить новость",
                    component: <AddNewsComponent/>,
                }
            ],
        };
    }

    render() {
        return (
            <>
                <TabMenuComponent
                    items={this.state.items}
                />
            </>
        );
    }
}
