import React, { useState, useEffect, useMemo } from "react";
import MUIDataTable from "mui-datatables";
import { getAccessToken } from "../../../../helpers/RequestHelpers";
import { connect } from "react-redux";
import useQuery from "../../../../hooks/useQuery";
import { useHistory } from "react-router-dom";

function PartnersTableComponent(props: any) {
  const { url, title } = props;
  const [data, setData] = useState<any>([]);
  const StoragePointIdsForDelete:any = [];
  const query = useQuery();
  const queryObject: any = query.getObject();
  const history = useHistory();

  const checkPageSize = (object: { pageSize: any }) => {
    return object?.pageSize;
  };
  const pageSize = useMemo(() => checkPageSize(queryObject), [queryObject]);

  const checkPage = (object: { page: any }) => {
    return object?.page;
  };
  const page = useMemo(() => checkPage(queryObject), [queryObject]);
  const checkSearch = (object: { searchText: any }) => {
    return object?.searchText ? object?.searchText : "";
  };
  const searchText = useMemo(() => checkSearch(queryObject), [queryObject]);
  useEffect(() => {
    props.getToken();

    var url = "/v1.0/partner?query=%7B%7D";
    const accessToken = localStorage.getItem("accessToken");
    var headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    fetch(process.env.REACT_APP_API_DOMAIN + url, {
      headers: headers,
    })
      .then((res) => res.json()) 
      .then((data) => {
        // const newData=data.map((element:any)=>{
        //   return {
        //     title:element.title,
        //     id:element.id
        //   }
        // })

        setData(data);
      });
  }, [props]);

  function prepareData(url: string) {
    var columns = [
      {
        name: "title",
        label: "Партнер",
        options: {
          filter: true,
          sort: true,
          filterType: "textField",
          filterList: queryObject.title ? [queryObject.title] : [],
        },
      },
        {
          name: "id",
          label: "Идентификатор",
          options: {
              filter: true,
              sort: true,
              filterType: "textField",
              filterList: queryObject.id ? [queryObject.id] : [],
            },
        }
      

    ];

    return columns;
  }

  var columns: any = prepareData(url);

  const options: any = {
    filter: true,
    selectableRows: false,
    filterType: "dropdown",
    responsive: "vertical",
    searchText: searchText,
    page:page?parseInt(page)-1:0,
    draggableColumns: {
      enabled: true,
    },
    rowsPerPage: pageSize || 10,
    // rowsPerPageOptions: [1, 10, 100],
    downloadOptions: {
      filename: "excel-format.csv",
      separator: ";",
      // utf8WithBom: true,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: (
      buildHead: (arg0: any) => string,
      buildBody: (arg0: any) => string,
      columns: any,
      data: any
    ) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    onRowSelectionChange: (
      currentRowsSelected: any,
      allRows: any,
      rowsSelected: any
    ) => {
      rowsSelected.forEach((element: string | number) => {
        StoragePointIdsForDelete.push(data[element].id);
      });
    },
    
    onChangeRowsPerPage: (num: any) => {
      query.set("pageSize", num.toString());
      window.history.pushState(null, "", `?${query.toString()}`);
    },
    onChangePage: (numberRows: any) => {
      query.set("page", (numberRows + 1).toString());
      window.history.pushState(null, "", `?${query.toString()}`);
      //var element = document.querySelector("#pagination-rows")?.innerHTML
    },
   
    onFilterChange(
      changedColumn: string,
      filterList: { [x: string]: string[] },
      type: any,
      changedColumnIndex: string | number
    ) {
      query.delete(changedColumn);
      if (filterList[changedColumnIndex][0]) {
        query.set(changedColumn, filterList[changedColumnIndex][0]);
      }
      window.history.pushState(null, "", `?${query.toString()}`);
    },
   
    onRowClick: (rowData: any, rowState: any) => {
      const partnerId = rowData[1]
            history.push(`/users/partners/${partnerId}`);
      //window.location.href = url
    },
    onSearchChange: (searchText: any) => {
      if (searchText) {
        query.set("searchText", searchText.toString());
      } else {
        query.delete("searchText");
      }
      window.history.pushState(null, "", `?${query.toString()}`);
    },
    textLabels: {
      body: {
        noMatch: "К сожалению, подходящие записи не найдены",
        toolTip: 'Сортировать',
        columnHeaderTooltip: (column:any) => `Сортировать по ${column.label}`
      },
      pagination: {
        next: 'Следующая Страница',
        previous: 'предыдущий',
        rowsPerPage: 'Рядов на странице:',
        displayRows: 'из'
      },
      toolbar: {
        search: 'Поиск',
        downloadCsv: 'Скачать CSV',
        print: 'Распечатать',
        viewColumns: 'Просмотр столбцов',
        filterTable: 'Таблица фильтров'
      },
      filter: {
        all: 'Все',
        title: 'ФИЛЬТРЫ',
        reset: 'Сброс'
      },
      viewColumns: {
        title: 'Показать столбцы',
        titleAria: 'Показать/скрыть столбцы таблицы'
      },
      selectedRows: {
        text: 'строк(и) выбраны',
        delete: 'Удалить',
        deleteAria: 'Удалить выбранные строки'
      }
    }
  };

  return (
    <React.Fragment>
      {/* <img src = {image} alt='something'/>  */}
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={options}
      />
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getToken: () => dispatch(getAccessToken()),
  };
};

export default connect(null, mapDispatchToProps)(PartnersTableComponent);
