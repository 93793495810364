"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Withdrawal = void 0;
var Model_1 = require("./Model");
var errors_1 = require("../errors");
var Withdrawal = /** @class */function (_super) {
  __extends(Withdrawal, _super);
  function Withdrawal(args) {
    var _this = _super.call(this) || this;
    _this.arguments = args;
    return _this;
  }
  Withdrawal.fromJson = function (data) {
    var requiredProps = ["id"];
    var verifyResult = Withdrawal.verifyRequiredProperties(data, requiredProps);
    if (!verifyResult.isAllPropsExist) {
      throw new errors_1.MissingPropertiesError("Missing props: ".concat(verifyResult.missingProps.toString(), " in ").concat(this.constructor.name, " json data."));
    }
    return new Withdrawal({
      id: data.id ? data.id : "",
      userId: data.userId ? data.userId : "",
      amount: data.amount ? data.amount : 0,
      completed: data.complitted ? data.complitted : false
    });
  };
  Withdrawal.prototype.getId = function () {
    return this.arguments.id;
  };
  Withdrawal.prototype.getUserId = function () {
    return this.arguments.userId;
  };
  Withdrawal.prototype.getAmount = function () {
    return this.arguments.amount;
  };
  Withdrawal.prototype.isCompleted = function () {
    return this.arguments.completed;
  };
  return Withdrawal;
}(Model_1.Model);
exports.Withdrawal = Withdrawal;