import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import {
  Button,
  FormGroup,
  FormLabel,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import moment from "moment";
import { getAccessToken } from "../../../helpers/RequestHelpers";
import useQuery from "../../../hooks/useQuery";

const SPACED_DATE_FORMAT = "DD.MM.YYYY";

function DiscountTableComponent(props: any) {
  const { url, title } = props;
  const [data, setData] = useState<any>([]);
  const discountIdsForDelete: any = [];
  const history = useHistory();
  const query = useQuery();
  const queryObject: any = query.getObject();
  const checkSearch = (object: { searchText: any }) => {
    return object?.searchText ? object?.searchText : "";
  };
  const searchText = useMemo(() => checkSearch(queryObject), [queryObject]);
  const checkPageSize = (object: { pageSize: any }) => {
    return object?.pageSize;
  };
  const pageSize = useMemo(() => checkPageSize(queryObject), [queryObject]);
  const getDiscounts=()=>{
    props.getToken();

    var url = "/v1/discount";
    const accessToken = localStorage.getItem("accessToken");
    var headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    fetch(process.env.REACT_APP_API_DOMAIN + url + "?fromAdminPanel=true", {
      headers: headers,
    })
      .then((res) => {
        if (res.status === 401) {
          localStorage.clear();
          window.location.replace("/auth");
          throw res;
        }
        return res;
      })
      .then((res) => res.json())
      .then((data) => {
        for (let i = 0; i < data.length; i++) {
          var date = new Date(data[i].createdOn);
          const onlyDate = moment(date).format("MM.DD.YYYY");
          const onlyTime = moment(date).format("HH:mm");

          data[i].createdOn = onlyDate;
          data[i].onlyTime = onlyTime;
        }
        const gridData = data.map((row: { createdOn: any }) => {
          const updatedRow = {
            ...row,
            createdOn: moment(row.createdOn, "MM.DD.YYYY").toDate().getTime(),
            //@ts-ignore
            comment: row.comment,
          };
          return updatedRow;
        });
        data.sort(function (a: any, b: any) {
          return b.onlyTime.localeCompare(a.onlyTime);
        });
        setData(gridData);
      });
  }
  useEffect(() => {
    props.getToken();
    getDiscounts()
  }, [props]);
  function prepareData(url: string) {
    var columns = [
      {
        name: "code",
        label: "Код дисконта",
        options: {
          filter: true,
          sort: true,
          filterType: "textField",
          filterList: queryObject.code ? [queryObject.code] : [],
        },
      },
      {
        name: "value",
        label: "Cкидка",
        options: {
          filter: true,
          sort: true,
          filterType: "textField",
          filterList: queryObject.value ? [queryObject.value] : [],
        },
      },
      {
        name: "createdOn",
        label: "Дата создания",
        options: {
          filter: true,
          sort: true,
          filterList: (() => {
            const list: any = [];
            if (queryObject.createdOnStart) {
              list[0] = queryObject.createdOnStart;
            }
            if (queryObject.createdOnEnd) {
              list[1] = queryObject.createdOnEnd;
            }
            return list;
          })(),
          customBodyRender: (value: string | number | Date) =>
            moment(new Date(value)).format(SPACED_DATE_FORMAT),
          filterType: "custom" as any,
          customFilterListOptions: {
            render: (v: any) => {
              if (v[0] && v[1]) {
                return [`Oт: ${v[0]}`, `До: ${v[1]}`];
              } else if (v[0]) {
                return `Oт: ${v[0]}`;
              } else if (v[1]) {
                return `До: ${v[1]}`;
              }
              return false;
            },
            update: (filterList: any, filterPos: any, index: any) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, "");
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
          filterOptions: {
            names: [],
            logic(date: moment.MomentInput, filters: any[]): boolean {
              const check: any = moment(date, "DD.MM.YYYY HH:mm");
              const from: any = moment(filters[0], "YYYY-MM-DD");
              const to: any = moment(filters[1], "YYYY-MM-DD");
              if (
                filters[0] &&
                filters[1] &&
                check.diff(to, "days") > 0 &&
                check.diff(from, "days") < 0
              ) {
                return true;
              } else if (filters[1] && check.diff(to, "days") > 0) {
                return true;
              } else if (filters[0] && check.diff(from, "days") < 0) {
                return true;
              }
              return false;
            },
            display: (
              filterList: { [x: string]: any },
              onChange: (arg0: any, arg1: any, arg2: any) => void,
              index: string | number,
              column: any
            ): JSX.Element => (
              <div>
                <FormLabel style={{ fontSize: 15 }}>Дата создания</FormLabel>
                <FormGroup row>
                  <TextField
                    id="startDate"
                    label="Дата начала"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][0] || ""}
                    onChange={(event) => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: "45%", marginRight: "5%", marginTop: "3%" }}
                  />
                  <TextField
                    id="endDate"
                    label="Дата окончания:"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][1] || ""}
                    onChange={(event) => {
                      filterList[index][1] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: "45%", marginRight: "5%", marginTop: "3%" }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "comment",
        label: "Комментарий",
        options: {
          filter: true,
          sort: true,
          filterType: "textField",
          filterList: queryObject.comment ? [queryObject.comment] : [],
        },
      },
      {
        name: "id",
        label: "Id Дисконта",
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },
    ];

    return columns;
  }

  var columns: any = prepareData(url);

  const options: any = {
    filter: true,
    selectableRows: true,
    filterType: "dropdown",
    responsive: "vertical",
    searchText: searchText,
    page: parseInt(queryObject.page) - 1 || 0,
    rowsPerPage: pageSize || 10,
    // rowsPerPageOptions: [1, 10, 100],
    customToolbarSelect: (
      selectedRows: any,
      displayData: any,
      setSelectedRows: any
    ) => (
      <div>
        <Tooltip title={"Delete"} className="mr-6">
          <Button onClick={()=>{
            setSelectedRows([])
             const accessToken = localStorage.getItem("accessToken");
             var headers = {
               "Content-Type": "application/json",
               Authorization: `Bearer ${accessToken}`,
             };
             const params = {
               discountIds: discountIdsForDelete,
             };
             const options = {
               method: "DELETE",
               body: JSON.stringify(params),
               headers: headers,
             };
             var url = "/v1/discount";
             const api: string = process.env.REACT_APP_API_DOMAIN + url;
             fetch(api, options)
               .then((response) => response.json())
               .then((response) => {
                discountIdsForDelete.splice(0, discountIdsForDelete.length);
                 getDiscounts()
               });
          }}><Delete /></Button>
        </Tooltip>
      </div>
    ),
    downloadOptions: {
      filename: "excel-format.csv",
      separator: ";",
      // utf8WithBom: true,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: (
      buildHead: (arg0: any) => string,
      buildBody: (arg0: any) => string,
      columns: any,
      data: any
    ) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    onRowSelectionChange: (
      currentRowsSelected: any,
      allRows: any,
      rowsSelected: any
    ) => {
      rowsSelected.forEach((element: string | number) => {
        discountIdsForDelete.push(data[element].id);
      });
    },
    
    onChangePage: (numberRows: any) => {
      query.set("page", (numberRows + 1).toString());
      window.history.pushState(null, "", `?${query.toString()}`);
    },
    onChangeRowsPerPage: (num: any) => {
      query.set("pageSize", num.toString());
      window.history.pushState(null, "", `?${query.toString()}`);
    },

    onFilterChange(
      changedColumn: string,
      filterList: { [x: string]: string[] },
      type: any,
      changedColumnIndex: string | number
    ) {
      switch (changedColumn) {
        case "createdOn": {
          query.delete("createdOnStart");
          query.delete("createdOnEnd");

          if (filterList[changedColumnIndex][0]) {
            query.set("createdOnStart", filterList[changedColumnIndex][0]);
          }

          if (filterList[changedColumnIndex][1]) {
            query.set("createdOnEnd", filterList[changedColumnIndex][1]);
          }
          break;
        }

        default:
          query.delete(changedColumn);
          if (filterList[changedColumnIndex][0]) {
            query.set(changedColumn, filterList[changedColumnIndex][0]);
          }
          break;
      }
      window.history.pushState(null, "", `?${query.toString()}`);
    },

    onRowClick: (rowData: any, rowState: any) => {
      const discountId = rowData[4];
      history.push(`/discounts/${discountId}`);
    },
    onSearchChange: (searchText: any) => {
      if (searchText) {
        query.set("searchText", searchText.toString());
      } else {
        query.delete("searchText");
      }
      window.history.pushState(null, "", `?${query.toString()}`);
    },
    textLabels: {
      body: {
        noMatch: "К сожалению, подходящие записи не найдены",
        toolTip: "Сортировать",
        columnHeaderTooltip: (column: any) => `Сортировать по ${column.label}`,
      },
      pagination: {
        next: "Следующая Страница",
        previous: "предыдущий",
        rowsPerPage: "Рядов на странице:",
        displayRows: "из",
      },
      toolbar: {
        search: "Поиск",
        downloadCsv: "Скачать CSV",
        print: "Распечатать",
        viewColumns: "Просмотр столбцов",
        filterTable: "Таблица фильтров",
      },
      filter: {
        all: "Все",
        title: "ФИЛЬТРЫ",
        reset: "Сброс",
      },
      viewColumns: {
        title: "Показать столбцы",
        titleAria: "Показать/скрыть столбцы таблицы",
      },
      selectedRows: {
        text: "строк(и) выбраны",
        delete: "Удалить",
        deleteAria: "Удалить выбранные строки",
      },
    },
  };

  return (
    <React.Fragment>
      {/* <img src = {image} alt='something'/>  */}
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={options}
      />
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getToken: () => dispatch(getAccessToken()),
  };
};

export default connect(null, mapDispatchToProps)(DiscountTableComponent);
