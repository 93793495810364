import React from "react";

import ClientsTableComponent from "./ClientsTableComponent";

interface IClientsComponentProps {}

export default function ClientsComponent(props: IClientsComponentProps) {
  return (
    <>
      <div className="row">
        <div className="col-lg-12 ">
          <div className="row">
            <div className="col-lg-12">
              <h3>Список клиентов</h3>
            </div>
          </div>
          <>
            <ClientsTableComponent />
          </>
        </div>
      </div>
    </>
  );
}
