"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Address = void 0;
var Model_1 = require("./Model");
var Address = /** @class */function (_super) {
  __extends(Address, _super);
  function Address(country, city, street, house, flat, zipCode, additional) {
    if (country === void 0) {
      country = "";
    }
    if (city === void 0) {
      city = "";
    }
    if (street === void 0) {
      street = "";
    }
    if (house === void 0) {
      house = "";
    }
    if (flat === void 0) {
      flat = "";
    }
    if (zipCode === void 0) {
      zipCode = "";
    }
    if (additional === void 0) {
      additional = "";
    }
    var _this = _super.call(this) || this;
    _this.country = country;
    _this.city = city;
    _this.street = street;
    _this.house = house;
    _this.flat = flat;
    _this.zipCode = zipCode;
    _this.additional = additional;
    return _this;
  }
  Address.fromJson = function (data) {
    return new Address(data.country ? data.country : "", data.city ? data.city : "", data.street ? data.street : "", data.house ? data.house : "", data.flat ? data.flat : "", data.zipCode ? data.zipCode : "", data.additional ? data.additional : "");
  };
  Address.prototype.toJson = function () {
    return {
      country: this.getCountry(),
      city: this.getCity(),
      street: this.getStreet(),
      house: this.getHouse(),
      flat: this.getFlat(),
      zipCode: this.getZipCode(),
      additional: this.getAdditional()
    };
  };
  Address.prototype.getCountry = function () {
    return this.country;
  };
  Address.prototype.setCountry = function (country) {
    this.country = country;
  };
  Address.prototype.getCity = function () {
    return this.city;
  };
  Address.prototype.setCity = function (city) {
    this.city = city;
  };
  Address.prototype.getStreet = function () {
    return this.street;
  };
  Address.prototype.setStreet = function (street) {
    this.street = street;
  };
  Address.prototype.getHouse = function () {
    return this.house;
  };
  Address.prototype.setHouse = function (house) {
    this.house = house;
  };
  Address.prototype.getFlat = function () {
    return this.flat;
  };
  Address.prototype.setFlat = function (flat) {
    this.flat = flat;
  };
  Address.prototype.getZipCode = function () {
    return this.zipCode;
  };
  Address.prototype.setZipCode = function (zipCode) {
    this.zipCode = zipCode;
  };
  Address.prototype.getAdditional = function () {
    return this.additional;
  };
  Address.prototype.setAdditional = function (additional) {
    this.additional = additional;
  };
  Address.prototype.getFullAddress = function () {
    return "".concat(this.getCountry() ? this.getCountry() + ", " : "").concat(this.getCity() ? this.getCity() + ", " : "").concat(this.getStreet() ? this.getStreet() + ", " : "").concat(this.getHouse() ? this.getHouse() + ", " : "").concat(this.getFlat() ? this.getFlat() + ", " : "").concat(this.getZipCode() ? this.getZipCode() + ", " : "").concat(this.getAdditional() ? this.getAdditional() + ", " : "").trim().slice(0, -1);
  };
  return Address;
}(Model_1.Model);
exports.Address = Address;