"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Logger = void 0;
var Logger = /** @class */function () {
  function Logger() {}
  Logger._isDevMode = function () {
    return process.env.REACT_APP_DEV_MODE === "true";
  };
  Logger.d = function (message) {
    if (Logger._isDevMode()) {
      console.log(message);
    }
  };
  Logger.e = function (message) {
    if (Logger._isDevMode()) {
      console.error(message);
    }
  };
  return Logger;
}();
exports.Logger = Logger;