import C from "../../actions/actionsTypes";
import {v4 as uuidv4} from "uuid";
import {MissingArgumentError} from "../../errors/MissingArgumentError";
import {isDevMode} from "../../helpers/AppHelpers";
import {NotificationStatus, Notification} from "@shift-mono/common";

export const notification = (state: any = [], action: any) => {
    switch (action.type) {
        case C.NOTIFICATION.ADD:
            try {
                return [...state, new Notification({
                    id: uuidv4(),
                    title: action.data.title,
                    description: action.data.description,
                    date: new Date(Date.now()),
                    status: NotificationStatus.New,
                    objectId: action.data.objectId ? action.data.objectId : null,
                    objectType: action.data.objectType ? action.data.objectType : null,
                    notificationType: action.data.notificationType ? action.data.notificationType : null,
                })];
            } catch (err) {
                if (err instanceof MissingArgumentError) {
                    if (isDevMode()) {
                        console.log(err);
                    }
                    return state;
                } else {
                    throw err;
                }

            }

        case C.NOTIFICATION.CHANGE_STATUS:
            return state.map((item: any) => {
                if (item._id === action.id) {
                    item._status = action.status;
                }
                return item
            });
        case C.NOTIFICATION.REMOVE:
            return state.filter((item: any) => {
                return item._id !== action.id
            });
        case C.NOTIFICATION.REMOVE_ALL:
            return [];
        default:
            return state;
    }
};