import React, { useEffect, useMemo, useState } from "react";
import MUIDataTable from "mui-datatables";
import { downloadExcel } from "react-export-table-to-excel";
import { Button } from "primereact/button";
import useQuery from "../../../hooks/useQuery";
import OrderModel from "../models/order";
import { OrderClient } from "../service/api";
import { GET_COLUMN, OPTIONS } from "./utils";
import { useHistory } from "react-router";
import { SearchComponent } from "../users/clients/partials/SearchComponent";
import FilterOrders from "./filter/FilterOrders";
import moment from "moment";

const TableComponentTmp = () => {
  const history = useHistory();

  const query = useQuery();
  const queryObject: any = query.getObject();
  const columns = useMemo(() => GET_COLUMN(queryObject), []);
  const { getOrders } = OrderClient();
  const header = [
    "Статус Заказа",
    "Дата заказа",
    "Время заказа",
    "Цена",
    "Номер пользователя",
    "Имя пользователя",
    "Id заказа",
  ];
  const [orderList, setOrderList] = useState<Array<OrderModel>>([]);
  const [ordersCount, setOrdersCount] = useState<number>(0);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const checkSearch = (object: { search: any }) => {
    return object?.search ?? "";
  };
  const checkStatus = (object: { status: any }) => {
    return object?.status ?? "";
  };

  const checkPageSize = (object: { pageSize: any }) => {
    return object.pageSize ?? 10;
  };
  const checkPage = (object: { page: any }) => {
    return object?.page ?? 0;
  };
  const search = useMemo(() => checkSearch(queryObject), [queryObject]);
  const status = useMemo(() => checkStatus(queryObject), [queryObject]);
  const [statusFilter, setStatusFilter] = useState<string>(status);
  const checkCreatedOnFrom = (object: { createdOnFrom: any }) => {
    return object?.createdOnFrom ?? "";
  };
  const createdOnFromFilter = useMemo(
    () => checkCreatedOnFrom(queryObject),
    [queryObject]
  );
  const [createdOnFrom, setCreatedOnFrom] =
    useState<string>(createdOnFromFilter);
  const checkCreatedOnTo = (object: { createdOnTo: any }) => {
    return object?.createdOnTo ?? "";
  };
  const createdOnToFilter = useMemo(
    () => checkCreatedOnTo(queryObject),
    [queryObject]
  );
  const [createdOnTo, setCreatedOnTo] = useState<string>(createdOnToFilter);
  const checkPriceFrom = (object: { priceFrom: any }) => {
    return object?.priceFrom ?? "";
  };
  const priceFromFilter = useMemo(
    () => checkPriceFrom(queryObject),
    [queryObject]
  );
  const [priceFrom, setPriceFrom] = useState<string>(priceFromFilter);
  const checkPriceTo = (object: { priceTo: any }) => {
    return object?.priceTo ?? "";
  };
  const priceToFilter = useMemo(() => checkPriceTo(queryObject), [queryObject]);
  const [priceTo, setPriceTo] = useState<string>(priceToFilter);
  const checkSortBy = (object: { sortBy: any }) => {
    return object?.sortBy ?? "0";
  };
  const sortByQuery = useMemo(() => checkSortBy(queryObject), [queryObject]);
  const [sortBy, setSortBy] = useState<string>(sortByQuery);
  const checkSortType = (object: { sortType: any }) => {
    return object?.sortType ?? "desc";
  };
  const sortTypeQuery = useMemo(
    () => checkSortType(queryObject),
    [queryObject]
  );
  const [sortType, setSortType] = useState<string>(sortTypeQuery);
  const [searchValue, setSearchValue] = useState(search);
  const pageSize = useMemo(() => checkPageSize(queryObject), [queryObject]);
  const page = useMemo(() => checkPage(queryObject), [queryObject]);

  useEffect(() => {
    (async () => {
      const { orders, totalCount } = await getOrders({
        skip: 0,
        limit: page ? page * pageSize : pageSize,
        sortBy: sortBy,
        sortType: sortType==='asc'?'0':"1",
        ...queryObject,
      });
      setOrderList(orders);
      setOrdersCount(parseInt(totalCount));
    })();
  }, []);
  const searchHandler = async (text: string) => {
    query.set("page", (1).toString());
    window.history.pushState(null, "", `?${query.toString()}`);

    const { orders, totalCount } = await getOrders({
      skip: 0,
      limit: pageSize,
      sortBy: sortBy,
      sortType:sortType==='asc'?'0':"1",
      ...queryObject,
      search: text,

    });
    setOrderList(orders);
    setOrdersCount(parseInt(totalCount));
  };
  const filter = async (value: any) => {
    query.set("page", (1).toString());
    window.history.pushState(null, "", `?${query.toString()}`);

    if (value) {
      Object.keys(value).forEach((key: any) => {
        query.delete(key);
      });
      Object.keys(value).forEach(function (key: any) {
        if (!["", null].includes(value[key])) {
          query.set(key, value[key]);
        }
      });
      window.history.pushState(null, "", `?${query.toString()}`);
    }
    const { orders, totalCount } = await getOrders({
      skip: 0,
      limit: pageSize,
      search: search,
      ...value,
    });
    setOrderList(orders);
    setOrdersCount(parseInt(totalCount));
  };
  const reset = async (value: any) => {
    Object.keys(value).forEach((key: any) => {
      query.delete(key);
    });
    window.history.pushState(null, "", `?${query.toString()}`);

    const { orders, totalCount } = await getOrders({
      skip: 0,
      limit: pageSize,
      search: search,
      sortBy: sortBy,
      sortType: sortType==='asc'?'0':"1",
      ...value,
    });
    setOrderList(orders);
    setOrdersCount(parseInt(totalCount));
    setStatusFilter("");
    setCreatedOnFrom("");
    setCreatedOnTo("");
    setPriceFrom("");
    setPriceTo("");
  };
  const checkUniq = (array1: any, array2: any) => {
    return !array1.some((item: any) =>
      array2.some((elem: any) => elem.id === item.id)
    );
  };

  const onChangePage = async (currentPage: number) => {
    const skip = currentPage * pageSize;

    const { orders } = await getOrders({
      skip: skip,
      limit: pageSize,

      ...queryObject,
    });
    checkUniq(orderList, orders) && setOrderList([...orderList, ...orders]);
  };
  const onChangePageSize = async (size: number) => {
    const { orders } = await getOrders({
      skip: 0,
      limit: size * page,
      sortBy: sortBy,
      sortType: sortType==='asc'?'0':"1",
      ...queryObject,
    });
    setOrderList([...orders]);
  };
  const handleDownloadExcel = async () => {
    const { orders } = await getOrders({
      skip: 0,
      limit: 0,
      sortBy: sortBy,
      sortType: sortType==='asc'?'0':"1",
      ...queryObject,
    });
    downloadExcel({
      fileName: "react-export-table-to-excel -> downloadExcel method",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,
        // accept two different data structures
        //@ts-ignore
        body: orders,
      },
    });
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "10px",
          justifyContent: "space-between",
        }}
      >
        <SearchComponent
          style={{ width: "92%" }}
          searchValue={searchValue}
          onChangeHandler={(searchVal) => {
            setSearchValue(searchVal ?? "");
          }}
          searchCallback={(searchVal) => {
            const searchText= searchVal?.trim()
            .replace("+", "%2B")
            if (searchText) {
              query.set("search", searchText.toString());
            } else {
              query.delete("search");
            }
            window.history.pushState(null, "", `?${query.toString()}`);
            searchHandler(searchText ?? "");
          }}
        />
        <Button
          type="button"
          icon="pi pi-filter"
          className=""
          style={{ padding: "1px" }}
          onClick={() => {
            setOpenFilter(true);
          }}
        />
        <Button
          type="button"
          icon="pi pi-download"
          className=""
          style={{ padding: "1px" }}
          onClick={handleDownloadExcel}
        />
      </div>
      <FilterOrders
        openFilter={openFilter}
        setOpenFilter={(value: boolean) => setOpenFilter(value)}
        statusFilter={statusFilter}
        changeStatusFilter={(status: string) => setStatusFilter(status)}
        createdOnFrom={createdOnFrom}
        setCreatedOnFrom={(value: string) => {
          setCreatedOnFrom(value);
        }}
        createdOnTo={createdOnTo}
        setCreatedOnTo={(value: string) => {
          setCreatedOnTo(value);
        }}
        priceFrom={priceFrom}
        setPriceFrom={(value: string) => {
          setPriceFrom(value);
        }}
        priceTo={priceTo}
        setPriceTo={(value: string) => {
          setPriceTo(value);
        }}
        sortBy={sortBy}
        setSortBy={(value: string) => {
          setSortBy(value);
        }}
        sortType={sortType}
        setSortType={(value: string) => {
          setSortType(value);
        }}
        callbackHandler={(value) => {
          filter(value);
        }}
        resetHandler={(value) => {
          reset(value);
        }}
      />
      <MUIDataTable
        data={orderList}
        columns={columns}
        options={{
          ...OPTIONS,
          count: ordersCount,
          //searchText: searchText,
          rowsPerPage: pageSize || 10,
          page: page ? page - 1 : 0,
          onChangeRowsPerPage: (num: any) => {
            onChangePageSize(num);
            query.set("pageSize", num.toString());
            window.history.pushState(null, "", `?${query.toString()}`);
          },
          setRowProps: (row) => {
            return {
              onDoubleClick: () => {
                history.push(`/orders/${row[6]}`);
              },
              style: {
                background:
                  moment().diff(moment(row[1]+' '+row[2],'DD.MM.YYYY HH:mm'),'hours')<=24
                    ? "lightgreen"
                    : "none",
              },
            };
          },
          onChangePage: (numberRows: any) => {
            onChangePage(numberRows);
            query.set("page", (numberRows + 1).toString());
            window.history.pushState(null, "", `?${query.toString()}`);
          },
        }}
        title={""}
      />
    </React.Fragment>
  );
};

export default TableComponentTmp;
