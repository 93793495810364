import React from "react";
import ContentLoaderComponent from "../../common/ContentLoaderComponent";
import BackButtonComponent from "../../common/BackButtonComponent";
import { Growl } from "primereact/growl";
import { connect } from "react-redux";
import { getAccessToken } from "../../../helpers/RequestHelpers";
import NewsFormComponent, {
  INewsFormData
} from "./add_news_parts/NewsFormComponent";
import { Redirect } from "react-router-dom";
import RouteURLs from "../../../actions/routesURL";
import {
  INews,
  requestNewsById,
  putNewsRequest,
  NewsRequestData,
  deleteNewsRequest,
  AuthorizedRequestData,
  postImage, Bind
} from "@shift-mono/common";

interface INewsDetailComponentProps {
  getToken: () => Promise<string>;
  match: any;
}
interface INewsDetailComponentState {
  currentNews: INews | undefined;

  isNeedRedirect: boolean;
}

class NewsDetailComponent extends React.Component<
  INewsDetailComponentProps,
  INewsDetailComponentState
> {
  private growl: any = undefined;
  private formRef: any = undefined;

  constructor(props: INewsDetailComponentProps) {
    super(props);

    this.state = {
      currentNews: undefined,
      isNeedRedirect: false
    };
  }
  @Bind()
  async newsRequest(): Promise<INews | undefined> {
    const token = await this.props.getToken();
    const request_data = new AuthorizedRequestData(token);
    const id = this.props.match.params.id;

    return (await requestNewsById(id, request_data)).getData();
  }
  @Bind()
  newsRequestResult(data: INews | undefined) {
    this.setState({ currentNews: data });
  }

  validateFormData(data: INewsFormData): boolean {
    if (data.newsTitle.trim() === "" || data.newsContent.trim() === "") {
      return false;
    }

    return true;
  }
  @Bind()
  async updateNewsRequest(data: INewsFormData) {
    const token = await this.props.getToken();

    if (!this.state.currentNews || !this.validateFormData(data)) {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Не все поля заполнены"
      });
      return;
    }
    try {
      let imageUrl = this.state.currentNews.getImageUrl();
      if (data.imageFile !== null) {
        const imageResult = await postImage(token, data.imageFile);
        if (imageResult === null) {
          this.growl.show({
            severity: "error",
            summary: "Ошибка",
            detail: "Не удалось загрузить изображение"
          });
          return;
        }
        imageUrl = imageResult.url;
      }

      const newsId = this.state.currentNews.getId();

      const requestData = new NewsRequestData({
            accessToken: token,
            newsTitle: data.newsTitle,
            newsContent: data.newsContent,
            tags: data.tags,
            imageUrl: imageUrl,

            newsId: newsId,
          })

      const newsUpdated = await putNewsRequest(requestData);

      if (newsUpdated) {
        this.growl.show({
          severity: "success",
          summary: "Успешно",
          detail: "Новость обновлена"
        });
      } else {
        this.growl.show({
          severity: "error",
          summary: "Ошибка",
          detail: "Ошибка обновления"
        });
      }
    } catch (err) {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Ошибка обновления"
      });
      return;
    }
  }
  @Bind()
  async deleteNewsRequest() {
    const token = await this.props.getToken();

    if (!this.state.currentNews) {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Ошибка удаления"
      });
      return;
    }
    const requestData = new AuthorizedRequestData(token);
    const newId = this.state.currentNews.getId();
    if (await deleteNewsRequest(newId, requestData)) {
      this.setState({ isNeedRedirect: true });
    } else {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Ошибка удаления"
      });
      return;
    }
  }

  render() {
    return (
      <>
        {this.state.isNeedRedirect ? (
          <Redirect to={RouteURLs.news + "/"} />
        ) : (
          <></>
        )}
        <Growl ref={el => (this.growl = el)} />
        <ContentLoaderComponent<INews | undefined>
          contentRequest={this.newsRequest}
          resultCallback={this.newsRequestResult}
        >
          <div className="row">
            <div className="col-lg-12">
              <h3>
                <BackButtonComponent /> Информация о новости
              </h3>
            </div>
          </div>
          <NewsFormComponent
            ref={el => {
              this.formRef = el;
            }}
            saveCallback={(formData: INewsFormData) => {
              this.updateNewsRequest(formData);
            }}
            deleteCallback={() => {
              this.deleteNewsRequest();
            }}
            currentNews={this.state.currentNews}
          />
        </ContentLoaderComponent>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getToken: () => dispatch(getAccessToken())
  };
};

export default connect(null, mapDispatchToProps)(NewsDetailComponent);
