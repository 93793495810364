"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Destination = void 0;
var LocalizedField_1 = require("./LocalizedField");
var Model_1 = require("./Model");
var errors_1 = require("../errors");
var Destination = /** @class */function (_super) {
  __extends(Destination, _super);
  function Destination(id, title, localizedTitle, polygon, center, boundingBox, coefficient) {
    var _this = _super.call(this) || this;
    _this.id = id;
    _this.title = title;
    _this.localizedTitle = localizedTitle;
    _this.polygon = polygon;
    _this.center = center;
    _this.boundingBox = boundingBox;
    _this.coefficient = coefficient;
    return _this;
  }
  Destination.fromJson = function (data) {
    var requiredProps = ["id"];
    var verifyResult = Destination.verifyRequiredProperties(data, requiredProps);
    if (!verifyResult.isAllPropsExist) {
      throw new errors_1.MissingPropertiesError("Missing props: ".concat(verifyResult.missingProps.toString(), " in ").concat(this.constructor.name, " json data."));
    }
    var localizedTitleArray = [];
    if (data.localizedTitle) {
      localizedTitleArray = (0, LocalizedField_1.convertFromObject)(data.localizedTitle);
    }
    return new Destination(data.id ? data.id : "", data.title ? data.title : "", localizedTitleArray, data.polygon ? data.polygon : "", data.center ? data.center : "", data.boundingBox ? data.boundingBox : "", data.coefficient ? data.coefficient : "");
  };
  Destination.prototype.getId = function () {
    return this.id;
  };
  Destination.prototype.getTitle = function () {
    return this.title;
  };
  Destination.prototype.getLocalizedTitle = function () {
    return this.localizedTitle;
  };
  Destination.prototype.getPolygon = function () {
    return this.polygon;
  };
  Destination.prototype.getCenter = function () {
    return this.center;
  };
  Destination.prototype.getBoundingBox = function () {
    return this.boundingBox;
  };
  Destination.prototype.getCoefficient = function () {
    return this.coefficient;
  };
  return Destination;
}(Model_1.Model);
exports.Destination = Destination;