import React from "react";
import { InputText } from "primereact/inputtext";
import MultiLanguageTextInputComponent from "../../../common/MultiLanguageTextInput/MultiLanguageTextInputComponent";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { ILocalizedField, IDestination, Bind } from "@shift-mono/common";

interface IDestinationFormComponentProps {
  saveCallback?: (formData: IDestinationFormData) => void;
  currentDestination?: IDestination | undefined;
}
interface IDestinationFormComponentState {
  firstInit: boolean;

  title: string;
  localizedTitle: ILocalizedField[];
  destinationPolygon: string;
  destinationCenter: string;
  destinationBoundingBox: string;
  coefficient: string;
}

export interface IDestinationFormData {
  title: string;
  localizedTitle: ILocalizedField[];
  destinationPolygon: string;
  destinationCenter: string;
  destinationBoundingBox: string;
  coefficient:string
}

class DestinationFormComponent extends React.Component<
  IDestinationFormComponentProps,
  IDestinationFormComponentState
> {
  private multiLangInputRef: any = undefined;

  constructor(props: IDestinationFormComponentProps) {
    super(props);

    this.state = {
      firstInit: true,
      title: "",
      localizedTitle: [],
      destinationPolygon: "",
      destinationCenter: "",
      destinationBoundingBox: "",
      coefficient: "",
    };
  }
  @Bind()
  clearForm() {
    this.setState({
      title: "",
      localizedTitle: [],
      destinationPolygon: "",
      destinationCenter: "",
      destinationBoundingBox: "",
      coefficient:""
    });
    if (this.multiLangInputRef) {
      this.multiLangInputRef.clear();
    }
  }
  @Bind()
  saveButtonHandle() {
    if (this.props.saveCallback) {
      this.props.saveCallback({
        title: this.state.title,
        localizedTitle: this.state.localizedTitle,
        destinationPolygon: this.state.destinationPolygon,
        destinationCenter: this.state.destinationCenter,
        destinationBoundingBox: this.state.destinationBoundingBox,
        coefficient:this.state.coefficient
      });
    }
  }
  @Bind()
  formatGeoJson(geojson: string): string {
    let result = geojson;
    const regPoint = /point/g;
    const regLineString = /lineString/g;
    const regPolygon = /polygon/g;

    result = result.replace(regPoint, "Point");
    result = result.replace(regLineString, "LineString");
    result = result.replace(regPolygon, "Polygon");

    return result;
  }
  @Bind()
  fillStateOfDestination(
    destination: IDestination,
    existState: IDestinationFormComponentState
  ) {
    existState.title = destination.getTitle();
    existState.coefficient=destination.getCoefficient();
    existState.localizedTitle = destination.getLocalizedTitle();
    existState.destinationCenter = this.formatGeoJson(
      JSON.stringify(destination.getCenter())
    );
    existState.destinationPolygon = this.formatGeoJson(
      JSON.stringify(destination.getPolygon())
    );
    existState.destinationBoundingBox = this.formatGeoJson(
      JSON.stringify(destination.getBoundingBox())
    );
  }

  shouldComponentUpdate(
    nextProps: IDestinationFormComponentProps,
    nextState: IDestinationFormComponentState
  ) {
    if (this.state.firstInit && nextProps.currentDestination !== undefined) {
      this.fillStateOfDestination(nextProps.currentDestination!, nextState);
      nextState.firstInit = false;
    }

    return true;
  }

  render() {
    return (
      <>
        <form
          className="card mt-2 p-2"
          onSubmit={(e: any) => {
            e.preventDefault();
          }}
        >
          <div className="row m-1">
            <label className="col-4">Название места</label>
            <InputText
              value={this.state.title}
              placeholder=""
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({ title: e.target.value })
              }
            />
          </div>
          <div className="row m-1">
            <label className="col-4"></label>
            <MultiLanguageTextInputComponent
              className="col-4 p-0"
              ref={(el) => {
                this.multiLangInputRef = el;
              }}
              initLocalizedFields={this.state.localizedTitle}
              changeLangValueCallback={(localizedFields) => {
                this.setState({ localizedTitle: localizedFields });
              }}
            />
          </div>
          <div className="row m-1">
            <label className="col-4">Центральная точка</label>
            <InputTextarea
              rows={5}
              cols={30}
              autoResize={true}
              style={{ maxHeight: "200px" }}
              value={this.state.destinationCenter}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                this.setState({ destinationCenter: e.target.value });
              }}
            />
          </div>

          <div className="row m-1">
            <label className="col-4">
              Минимальный описывающий прямоугольник
            </label>
            <InputTextarea
              rows={5}
              cols={30}
              autoResize={true}
              style={{ maxHeight: "200px" }}
              value={this.state.destinationBoundingBox}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                this.setState({ destinationBoundingBox: e.target.value });
              }}
            />
          </div>

          <div className="row m-1">
            <label className="col-4">Полигон</label>
            <InputTextarea
              rows={5}
              cols={30}
              autoResize={true}
              style={{ maxHeight: "200px" }}
              value={this.state.destinationPolygon}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                this.setState({ destinationPolygon: e.target.value });
              }}
            />
          </div>
          <div className="row m-1">
            <label className="col-4">Коэффициент</label>
            <InputText
              type="number"
              value={this.state.coefficient}
              placeholder=""
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({ coefficient: e.target.value })
              }
            />
          </div>
          <div className="row m-1 ml-2">
            <Button
              label={this.props.currentDestination ? "Сохранить" : "Добавить"}
              onClick={async () => {
                this.saveButtonHandle();
              }}
            />
          </div>
        </form>
      </>
    );
  }
}

export default DestinationFormComponent;
