import React from "react";
import LoginComponent from "../components/content/auth/LoginComponent";

const AuthPage = () => {
    return (
        <div className="container d-flex justify-content-center">
            <LoginComponent/>
        </div>
    );
};

export default AuthPage;
