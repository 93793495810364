"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StoragePointRequestData = void 0;
var AuthorizedRequestData_1 = require("./AuthorizedRequestData");
var models_1 = require("../../models");
var StoragePointRequestData = /** @class */function (_super) {
  __extends(StoragePointRequestData, _super);
  function StoragePointRequestData(args) {
    var _this = _super.call(this, args.accessToken) || this;
    _this.arguments = args;
    return _this;
  }
  StoragePointRequestData.prototype.getData = function () {
    var result = {
      name: this.arguments.name,
      localizedName: (0, models_1.convertToObject)(this.arguments.localizedName),
      address: this.arguments.address.toJson(),
      images: this.arguments.imagesURL,
      services: this.arguments.serviceIds,
      cells: this.arguments.reservedCellCount,
      location: {
        type: this.arguments.location.getType(),
        coordinates: this.arguments.location.getCoord()
      },
      active: this.arguments.active,
      selfService: this.arguments.selfService,
      canStore: this.arguments.canStore,
      couriers: this.arguments.couriers,
      destinationId: this.arguments.destinationId,
      type: this.arguments.storagePointType
    };
    if (this.arguments.storagePointId) {
      result = Object.assign({}, result, {
        id: this.arguments.storagePointId
      });
    }
    if (this.arguments.partnerId) {
      result = Object.assign({}, result, {
        partnerId: this.arguments.partnerId
      });
    } else {
      result = Object.assign({}, result, {
        partnerId: ""
      });
    }
    return Object.assign({}, _super.prototype.getData.call(this), result);
  };
  StoragePointRequestData.prototype.getId = function () {
    return this.arguments.storagePointId;
  };
  return StoragePointRequestData;
}(AuthorizedRequestData_1.AuthorizedRequestData);
exports.StoragePointRequestData = StoragePointRequestData;