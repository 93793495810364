import React from "react";
import BackButtonComponent from "../../common/BackButtonComponent";
import ContentLoaderComponent from "../../common/ContentLoaderComponent";
import { getAccessToken } from "../../../helpers/RequestHelpers";
import { connect } from "react-redux";
import { Growl } from "primereact/growl";
import DestinationFormComponent, {
  IDestinationFormData
} from "./form_partials/DestinationFormComponent";
import {
  IDestination,
  AuthorizedRequestData,
  requestDestinationById,
  putDestinationRequest,
  DestinationRequestData, Bind
} from "@shift-mono/common";
import RouteURLs from "../../../actions/routesURL";

interface DestinationDetailComponentProps {
  getToken: () => Promise<string>;
  match: any;
}
interface DestinationDetailComponentState {
  currentDestination: IDestination | undefined;
}

class DestinationDetailComponent extends React.Component<
  DestinationDetailComponentProps,
  DestinationDetailComponentState
> {
  loaderRef: any = undefined;
  growl: any = undefined;
  geoJsonValidator: any = require("geojson-validation");

  constructor(props: DestinationDetailComponentProps) {
    super(props);

    this.state = {
      currentDestination: undefined
    };

  }

  @Bind()
  async contentRequest() {
    const token = await this.props.getToken();
    const request_data = new AuthorizedRequestData(token);
    const id = this.props.match.params.id;

    return (await requestDestinationById(id, request_data)).getData();
  }
  @Bind()
  requestResult(destination: IDestination | undefined) {
    if (destination) {
      this.setState({
        currentDestination: destination
      });
    }
  }
  @Bind()
  async validateForm(formData: IDestinationFormData): Promise<boolean> {
    if (
      formData.title.trim() === "" ||
      formData.destinationCenter.trim() === "" ||
      formData.destinationBoundingBox.trim() === "" ||
      formData.destinationPolygon.trim() === ""
    ) {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Не все поля заполнены"
      });
      return false;
    }

    const centerIsPoint = await new Promise((resolve, rejects) => {
      this.geoJsonValidator.isPoint(
        JSON.parse(formData.destinationCenter),
        (isValid: boolean) => {
          resolve(isValid);
        }
      );
    });

    if (!centerIsPoint) {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Центральная точка не корректна"
      });
      return false;
    }

    const boundingBoxIsLineString = await new Promise((resolve, rejects) => {
      this.geoJsonValidator.isLineString(
        JSON.parse(formData.destinationBoundingBox),
        (isValid: boolean) => {
          resolve(isValid);
        }
      );
    });

    if (!boundingBoxIsLineString) {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Минимальный описывающий прямоугольник не корректен"
      });
      return false;
    }

    const polygonIsPolygon = await new Promise((resolve, rejects) => {
      this.geoJsonValidator.isPolygon(
        JSON.parse(formData.destinationPolygon),
        (isValid: boolean) => {
          resolve(isValid);
        }
      );
    });

    if (!polygonIsPolygon) {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Полигон не корректен"
      });
      return false;
    }

    return true;
  }
  @Bind()
  async sendDestination(formData: IDestinationFormData) {
    const token = await this.props.getToken();
    const title = formData.title.trim();
    const localizedTitle = formData.localizedTitle;
    const center = formData.destinationCenter.trim();
    const bounding_box = formData.destinationBoundingBox.trim();
    const polygon = formData.destinationPolygon.trim();
    const coefficient=formData.coefficient;
    const id = this.props.match.params.id;

    let destination_added = false;

    if (!id) {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Ошибка обновления"
      });
      return;
    }

    if (await this.validateForm(formData)) {
      const request_data = new DestinationRequestData({
        accessToken: token,
        title: title,
        localizedTitle: localizedTitle,
        center: center,
        boundingBox: bounding_box,
        polygon: polygon,
        coefficient:coefficient,
        destinationId: id,
      });

      destination_added = await putDestinationRequest(request_data);
    }

    if (destination_added) {
      this.growl.show({
        severity: "success",
        summary: "Успешно",
        detail: "Место добавлено"
      });
    } else {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Ошибка добавления"
      });
    }
  }

  render() {
    return (
      <>
        <ContentLoaderComponent<IDestination | undefined>
          contentRequest={this.contentRequest}
          resultCallback={this.requestResult}
          ref={el => {
            this.loaderRef = el;
          }}
        >
          <div className="row">
            <div className="col-lg-12">
              <h3>
                <BackButtonComponent backURL={RouteURLs.destinations}/> Информация о городе
              </h3>
            </div>
          </div>
          <div className="row">
            <Growl ref={el => (this.growl = el)} />
            <DestinationFormComponent
              currentDestination={this.state.currentDestination}
              saveCallback={(formData: IDestinationFormData) => {
                this.sendDestination(formData);
              }}
            />
          </div>
        </ContentLoaderComponent>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getToken: () => dispatch(getAccessToken())
  };
};

export default connect(null, mapDispatchToProps)(DestinationDetailComponent);
