import React from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FormGroup, FormLabel, TextField, Divider,Button, IconButton } from "@material-ui/core";
import { STATUS_LIST, SORTED_LIST } from "./utlis";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";

interface IFilterProps {
  openFilter: boolean;
  setOpenFilter: (value: boolean) => void;
  statusFilter: string;
  changeStatusFilter: (value: string) => void;
  createdOnFrom: string;
  setCreatedOnFrom: (value: string) => void;
  createdOnTo: string;
  setCreatedOnTo: (value: string) => void;
  priceFrom: string;
  setPriceFrom: (value: string) => void;
  priceTo: string;
  setPriceTo: (value: string) => void;
  sortBy: string;
  setSortBy: (value: string) => void;
  sortType: string;
  setSortType: (value: string) => void;
  callbackHandler: (value: {
    status: string;
    createdOnFrom: string;
    createdOnTo: string;
    priceFrom: string;
    priceTo: string;
    sortBy: string;
    sortType: string;
  }) => void;
  resetHandler: (value: {
    status: string;
    createdOnFrom: string;
    createdOnTo: string;
    priceFrom: string;
    priceTo: string;
    sortBy: string;
    sortType: string;
  }) => void;
}
const FilterOrders = (props: IFilterProps) => {
  return (
    <Dialog
      visible={props.openFilter}
      onHide={() => props.setOpenFilter(false)}
      style={{ width: "40%" }}
      header={
        <label style={{ fontSize: "25px" }}>
          <b>Фильтры заказов</b>
        </label>
      }
    >
      <>
        <div className={"col-lg-12"}>
          <div className="row m-12">
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className={"col-lg-6"}
            >
              <FormLabel style={{ fontSize: 15 }}>Статус Заказа</FormLabel>

              <Dropdown
                value={props.statusFilter}
                options={STATUS_LIST}
                onChange={(e: any) => {
                  props.changeStatusFilter(e.value);
                }}
                style={{ width: "100%" }}
                placeholder="Статус Заказа"
                optionLabel="label"
              />
            </div>
            <div className="col-lg-1"></div>
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className={"col-lg-5"}
            >
              <FormLabel style={{ fontSize: 15 }}>Сортировать по </FormLabel>
              <div className="row">
                <Dropdown
                  value={props.sortBy}
                  options={SORTED_LIST}
                  onChange={(e: any) => {
                    props.setSortBy(e.value);
                  }}
                  style={{ width: "70%" }}
                  optionLabel="label"
                />
                <IconButton
                size="small" 
                color={'primary'}
                  onClick={(e) => {
                    props.setSortType(
                      props.sortType === "asc" ? "desc" : "asc"
                    );
                  }}
                  style={{
                    marginLeft: ".25em",
                  }}
                >{props.sortType === "asc"?<ArrowUpward />:<ArrowDownward/>}</IconButton>
              </div>
            </div>
          </div>
          <div className="row m-12" style={{ marginTop: "30px" }}>
            <div className="col-lg-6">
              <FormLabel style={{ fontSize: 15 }}>Дата создания</FormLabel>
              <div className="row m-1">
                <TextField
                  id="createdOnFrom "
                  label="Дата начала"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={props.createdOnFrom}
                  onChange={(event) => {
                    props.setCreatedOnFrom(event.target.value);
                  }}
                  style={{ width: "48%", marginRight: "4%" }}
                />
                <TextField
                  id="createdOnTo"
                  label="Дата окончания:"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={props.createdOnTo}
                  onChange={(event) => {
                    props.setCreatedOnTo(event.target.value);
                  }}
                  style={{ width: "48%" }}
                />
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-5">
              <FormLabel style={{ fontSize: 15 }}>Цена</FormLabel>
              <FormGroup row>
                <TextField
                  label="Oт какой"
                  type={"number"}
                  value={props.priceFrom}
                  onChange={(event) => {
                    props.setPriceFrom(event.target.value);
                  }}
                  style={{ width: "48%", marginRight: "4%" }}
                />
                <TextField
                  label="До какой"
                  type={"number"}
                  value={props.priceTo}
                  onChange={(event) => {
                    props.setPriceTo(event.target.value);
                  }}
                  style={{ width: "48%" }}
                />
              </FormGroup>
            </div>
          </div>

          <Divider style={{ marginTop: "30px", marginBottom: "20px" }} />
          <div
            className="row"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
            variant="outlined"
            color="primary"
              onClick={() => {
                const value: {
                  status: string;
                  createdOnFrom: string;
                  createdOnTo: string;
                  priceFrom: string;
                  priceTo: string;
                  sortBy: string;
                  sortType: string;
                } = {
                  status: props.statusFilter,
                  createdOnFrom: props.createdOnFrom,
                  createdOnTo: props.createdOnTo,
                  priceFrom: props.priceFrom,
                  priceTo: props.priceTo,
                  sortBy: props.sortBy,
                  sortType: props.sortType === "asc" ? "0" : "1",
                };
                props.callbackHandler(value);
                props.setOpenFilter(false);
              }}
            >
              Отфильтровать
            </Button>
            <Button
            variant="outlined"
            color="primary"

              onClick={() => {
                props.resetHandler({
                  status: "",
                  createdOnFrom: "",
                  createdOnTo: "",
                  priceFrom: "",
                  priceTo: "",
                  sortBy: "",
                  sortType: "",
                });
                props.setOpenFilter(false);
              }}
              style={{ marginLeft: "15px" }}
            >
              Сброс
            </Button>
          </div>
        </div>
      </>
    </Dialog>
  );
};
export default FilterOrders;
