import React from "react";
import PageTemplate from "./PageTemplate";
import RouteURLs from "../actions/routesURL";
import { Route } from "react-router-dom";
import { NewsComponent } from "../components/content/news/NewsComponent";
import NewsDetailComponent from "../components/content/news/NewsDetailComponent";

const NewsPage = () => {
  return (
    <PageTemplate>
      <Route exact path={RouteURLs.news} component={NewsComponent} />
      <Route exact path={RouteURLs.news + "/:id"} component={NewsDetailComponent} />
    </PageTemplate>
  );
};

export default NewsPage;
