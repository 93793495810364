"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.User = void 0;
var Model_1 = require("./Model");
var errors_1 = require("../errors");
var User = /** @class */function (_super) {
  __extends(User, _super);
  function User(args) {
    var _this = _super.call(this) || this;
    _this.arguments = args;
    return _this;
  }
  User.fromJson = function (data) {
    var requiredProps = ["id"];
    var verifyResult = User.verifyRequiredProperties(data, requiredProps);
    if (!verifyResult.isAllPropsExist) {
      throw new errors_1.MissingPropertiesError("Missing props: ".concat(verifyResult.missingProps.toString(), " in ").concat(this.constructor.name, " json data."));
    }
    return new User({
      id: data.id ? data.id : "",
      phone: data.phoneNumber ? data.phoneNumber : "",
      email: data.email ? data.email : "",
      username: data.userName ? data.userName : undefined,
      firstName: data.firstName ? data.firstName : "",
      secondName: data.secondName ? data.secondName : "",
      thirdName: data.thirdName ? data.thirdName : "",
      partnerId: data.partnerId ? data.partnerId : undefined
    });
  };
  User.prototype.getId = function () {
    return this.arguments.id;
  };
  User.prototype.getEmail = function () {
    return this.arguments.email;
  };
  User.prototype.getPhone = function () {
    return this.arguments.phone;
  };
  User.prototype.getUsername = function () {
    return this.arguments.username;
  };
  User.prototype.getFirstName = function () {
    return this.arguments.firstName;
  };
  User.prototype.getSecondName = function () {
    return this.arguments.secondName;
  };
  User.prototype.getThirdName = function () {
    return this.arguments.thirdName;
  };
  User.prototype.getFullName = function () {
    return "".concat(this.getFirstName(), " ").concat(this.getSecondName(), " ").concat(this.getThirdName()).trim();
  };
  User.prototype.getPartnerId = function () {
    return this.arguments.partnerId;
  };
  return User;
}(Model_1.Model);
exports.User = User;