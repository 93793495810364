import moment from "moment";
import OrderModel from "../order";
import { returnOrderStatus, returnUserName } from "./utlis";

type orderResponseModel = {
  orderStatus: string;
  createdOn: string;
  onlyTime: string;
  totalWithDiscount: string;
  phoneNumber: string;
  user?: any; //TO DO
  id: number;
};

const createOrderFromRowDataWithCount = (
  responseItem: orderResponseModel
): OrderModel =>
  new OrderModel({
    orderStatus: returnOrderStatus(responseItem.orderStatus),
    createdOn: moment
      .utc(responseItem.createdOn)
      .local()
      .format("DD.MM.YYYY")
      .toString(),
    onlyTime: moment.utc(responseItem.createdOn).local().format("HH:mm"),
    totalWithDiscount: responseItem.totalWithDiscount,
    phoneNumber: returnUserName(responseItem.user).phoneNumber,
    userName: returnUserName(responseItem.user).userName,
    id: responseItem.id,
  });

export const createOrderListFromRowDataWithCount = (response: {
  data: Array<orderResponseModel>;
  header: any;
}): { orders: Array<OrderModel>; totalCount: number } => ({
  orders: response.data.map((item: orderResponseModel) =>
    createOrderFromRowDataWithCount(item)
  ),
  totalCount: response.header["x-objects-count"],
});

export default createOrderListFromRowDataWithCount;
