import Axios from "axios";

enum StatusCodes {
  UNAUTHORIZED = 401,
}

enum LocalStorageItems {
  ACCESS_TOKE = "access_token",
  REFRESH_TOKEN = "refresh_token",
}

enum Methods {
  GET = "get",
  POST = "post",
  PATCH = "patch",
}

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
});

const requestHandler = (config: any) => {
  const accessToken = localStorage.getItem(LocalStorageItems.ACCESS_TOKE);
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  config.headers = {
    ...config.headers,
    ...headers,
  };
  return config;
};

const requestError = (error: any) => {
  return error;
};

const responseHandler = (res: any) => {
  return res;
};

const responseError = async (resError: any) => {
  if (StatusCodes.UNAUTHORIZED !== resError.response.status) {
    return resError;
  }
  localStorage.clear()
  window.location.replace('/auth');
  return null
  const refreshToken = localStorage.getItem(LocalStorageItems.REFRESH_TOKEN);
  if (refreshToken) {
    try {
     
    } catch (_) {
      return null;
    }
  }
};

axios.interceptors.request.use(requestHandler, requestError);
axios.interceptors.response.use(responseHandler, responseError);

type RequestParams = {
  url: string;
  params?: any;
  body?: any;
  baseURL?: string;
};

export const RestClient = (): any => {
  const get = (
    { url, params, baseURL }: RequestParams,
    withHeaders: Boolean = false
  ) =>
    axios
      .request({ url: url, method: Methods.GET, params, baseURL })
      .then((res) =>
        withHeaders ? { data: res.data, headers: res.headers } : res.data
      )
      .catch(() => []);

  const post = ({ url, params, body, baseURL }: RequestParams) =>
    axios
      .request({ url: url, method: Methods.POST, params, data: body, baseURL })
      .then((res) => res.data)
      .catch(() => []);
  const patch = ({ url, params, body, baseURL }: RequestParams) =>
    axios
      .request({ url: url, method: Methods.PATCH, params, data: body, baseURL })
      .then((res) => res.data)
      .catch(() => []);
  return {
    get: get,
    post: post,
    patch: patch,
  };
};

interface IData {
  data: any;
  status_code: string;
  error: string;
}
const ret: IData = {
  data: null,
  status_code: "",
  error: "",
};

// TODO remove this functionality after clear code usage
//TODO: дописать отправку сообщения об ошибке на почту
export async function ajaxAction(
  url: string,
  method: string,
  data?: string | FormData | null | undefined
): Promise<IData> {
  try {
    let headers: any;
    const accessToken = localStorage.getItem("access_token");
    // d = JSON.stringify({ data }),
    headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    if (data instanceof FormData) {
    } else if (data) {
      // d = JSON.stringify({ data }),
      headers = {
        "Content-Type": "application/x-www-form-urlencoded",
      };
    }

    await fetch(url, {
      method: method,
      body: data,
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: headers,
      redirect: "follow",
      referrerPolicy: "no-referrer",
    })
      .then((response) => {
        ret.status_code = response.status.toString();
        if (
          ret.status_code === "200" ||
          ret.status_code === "201" ||
          ret.status_code === "0"
        ) {
          return response.json();
        }
      })
      .then((data) => {
        ret.data = data;
      });
  } catch (err) {
    console.log(err);
  }
  return ret;
}
