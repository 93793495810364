"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NotificationStatus = void 0;
var NotificationStatus;
(function (NotificationStatus) {
  NotificationStatus["New"] = "new";
  NotificationStatus["Regular"] = "regular";
})(NotificationStatus = exports.NotificationStatus || (exports.NotificationStatus = {}));