"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DirectionRequestData = void 0;
var AuthorizedRequestData_1 = require("./AuthorizedRequestData");
var models_1 = require("../../models");
var DirectionRequestData = /** @class */function (_super) {
  __extends(DirectionRequestData, _super);
  function DirectionRequestData(args) {
    var _this = _super.call(this, args.accessToken) || this;
    _this.arguments = args;
    return _this;
  }
  DirectionRequestData.prototype.getData = function () {
    var result = {
      name: this.arguments.name,
      localizedName: (0, models_1.convertToObject)(this.arguments.localizedName),
      vertices: this.arguments.vertices,
      coefficient: this.arguments.coefficient,
      deliveryDuration: this.arguments.deliveryDuration
    };
    if (this.arguments.directionId) {
      result = Object.assign({}, result, {
        id: this.arguments.directionId
      });
    }
    return Object.assign({}, _super.prototype.getData.call(this), result);
  };
  DirectionRequestData.prototype.getId = function () {
    return this.arguments.directionId;
  };
  return DirectionRequestData;
}(AuthorizedRequestData_1.AuthorizedRequestData);
exports.DirectionRequestData = DirectionRequestData;