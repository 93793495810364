import React from "react";
import RouteURLs from "../../../actions/routesURL";
import "./OrderListComponentStyle.scss";
import {Column} from "primereact/column";
import {connect} from "react-redux";
import {DataTable} from "primereact/datatable";
import {getAccessToken} from "../../../helpers/RequestHelpers";
import {Link} from "react-router-dom";
import {Button} from "primereact/button";
import {getOrders} from "../../../api/api";
import {calcSkipAndLimit} from "../../../helpers/PaginationHelpers";
import TableComponentTmp  from "./OrderTableComponentTmp";

import {
    IOrderService,
    IOrder,
    IClient,
    CountableResponse,
    ICountableResponse,
    requestClientsByIds,
    AuthorizedRequestData,
    IService,
    Bind,
    getOrderStatusTitle
} from "@shift-mono/common";


interface IClientListFilterParams {
    agentStatus: boolean | undefined;
  }
interface IOrderListComponentProps {
    getToken: () => Promise<string>;
    filterParams?: IClientListFilterParams;
}

interface IOrderListComponentState {
    orders: IOrder[];
    ordersCount: number;
    paginationListRows: number;
    firstPaginationIndex: number;
    loading: boolean;
    services: IService[];
    clients: Map<string, IClient | undefined>;
    expandedRows: any;
    screenWidth: number;
    searchVal: object | undefined;
}

class OrderListComponent extends React.Component<IOrderListComponentProps,
    IOrderListComponentState> {
    breakpointWidth = 1140;
    loaderRef: any = undefined;
    constructor(props: IOrderListComponentProps) {
        super(props);

        this.state = {
            orders: [],
            ordersCount: 0,
            paginationListRows: 20,
            firstPaginationIndex: 0,
            loading: true,
            services: [],
            expandedRows: null,
            clients: new Map(),
            screenWidth: window.innerWidth,
            searchVal: {},
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resizeHandler);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeHandler);
    }

    @Bind()
    resizeHandler() {
        this.setState({
            screenWidth: window.innerWidth
        });
    }
    @Bind()
    composeQueryRequestParams() {
      const queryObjs = [];
  
      if (this.props.filterParams) {
        switch (this.props.filterParams!.agentStatus) {
          case true:
            queryObjs.push({ isAgent: true });
            break;
          case false:
            queryObjs.push({ $or: [{ isAgent: false }, { isAgent: null }] });
            break;
        }
      }
  
     // if (this.state.searchVal) {

    //     const searchReg = this.state.searchVal
    //       .trim()
    //       .replace("+", "\\+")
    //       .split(" ")
    //       .join("|");
    //     queryObjs.push({
    //       $or: [
    //         { phoneNumber: { $regex: searchReg, $options: "i" } },
    //         { userName: { $regex: searchReg, $options: "i" } },
    //         { orderStatus: { $regex: searchReg, $options: "i" } },
    //         { totalWithDiscount: { $regex: searchReg, $options: "i" } },
    //         { createdOn: { $regex: searchReg, $options: "i" } },
    //       ],
    //     });
    //   }
  
    //   const result = queryObjs.length > 0 ? { $and: queryObjs } : {};
      return this.state.searchVal;
    }
    @Bind()
    async getClientsRequest(): Promise<IClient[]> {
        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token);
        try {
            return (await requestClientsByIds(Array.from(this.state.clients.keys()), request_data)).getData();
        } catch (err) {
            return [];
        }
    }

    @Bind()
    clientRequestResult(clients: IClient[]) {
        const clientsMap: Map<string, IClient | undefined> = new Map();
        clients.forEach((client) => {
            clientsMap.set(client.getUserId(), client);
        })
        this.setState({
            clients: clientsMap,
        })
    }

    getClientsIdMapFromOrders(orders: IOrder[]): Map<string, undefined> {
        const clients: Map<string, undefined> = new Map();
        orders
            .map((order: IOrder) => order.getClientId())
            .forEach((id) => {
                if (id) {
                    clients.set(id, undefined);
                }
            });
        return clients;
    }

    @Bind()
    async getOrdersRequest(): Promise<ICountableResponse<IOrder[]>> {
        const token = await this.props.getToken();

        try {
            let currentOrdersCount = this.state.ordersCount;

            //if (currentOrdersCount === 0) {
                currentOrdersCount = await this.getOrdersCount();
            //}
            console.log(this.state.searchVal)
            const startIndex = 0;
            let {skip, limit} = calcSkipAndLimit(startIndex, currentOrdersCount, this.state.paginationListRows);
            let params = {
                skip: skip,
                limit: limit,
            };
            params = Object.assign({}, params, this.state.searchVal);
            const mockRequestData = new AuthorizedRequestData(token, {}, params);

            // const request_data = new AuthorizedRequestData(token, {}, {
            //     skip: skip,
            //     limit: limit,
            // });
            return await getOrders(mockRequestData);
        } catch (err) {
            return new CountableResponse<IOrder[]>([], 0);
        }
    }

    @Bind()
    ordersRequestResult(orders: ICountableResponse<IOrder[]>) {
        const clients = this.getClientsIdMapFromOrders(orders.getData());

        this.setState({
            orders: orders.getData().reverse(),
            loading: false,
            ordersCount: orders.getObjectsCount(),
            clients
        });
    }

    // @Bind()
    // async getServicesRequest() {
    //     const token = await this.props.getToken();
    //     const request_data = new AuthorizedRequestData(token);
    //     try {
    //         return (await requestServices(request_data)).getData();
    //     } catch (err) {
    //         return [];
    //     }
    // }

    // @Bind()
    // servicesRequestResult(result: IService[]) {
    //     this.setState({services: result});
    // }

    @Bind()
    rowExpansionTemplate(data: IOrder) {
        return (
            <>
                <div className="row m-1">
                    <div className="col-lg-5 mt-2">
                        <div className="row">
                            <label className="col-6">Цена без скидки: </label>
                            <span className="col-6">{data.getTotalPrice()}</span>
                        </div>
                        <div className="row">
                            <label className="col-6">Итоговая цена: </label>
                            <span className="col-6">
                                <b>{data.getTotalPriceWithDiscount()}</b>
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <DataTable className="main_list_table" value={data.getServices()}>
                            <Column
                                field="service_id"
                                body={this.serviceNameFieldTemplate}
                                header="Услуга"
                            />
                            <Column field="total" header="Цена"/>
                        </DataTable>
                    </div>
                </div>
            </>
        );
    }

    @Bind()
    serviceNameFieldTemplate(rowData: IOrderService) {
        const id = rowData.getServiceId();
        let title = "";
        const services = this.state.services.filter((item: IService) => {
            return item.getId() === id;
        });
        if (services.length > 0) {
            title = services[0].getName();
        }

        return title;
    }
    @Bind()
    reloadContent() {
        if (this.loaderRef !== undefined) {
            this.loaderRef.tryToLoading();
        }
    }

    actionTemplate(rowData: IOrder, column: any) {
        return (
            <>
                <Link to={RouteURLs.orders + "/" + rowData.getId()}>
                    <Button
                        type="button"
                        icon="pi pi-file-o"
                        className="p-button-info mr-3"
                    />
                </Link>
            </>
        );
    }

    @Bind()
    async onPage(event: any) {
        this.setState({
            loading: true
        });

        const token = await this.props.getToken();
        let currentOrdersCount = this.state.ordersCount;

        if (currentOrdersCount === 0) {
            currentOrdersCount = await this.getOrdersCount();
        }

        const startIndex = event.first;
        let {skip, limit} = calcSkipAndLimit(event.first, currentOrdersCount, this.state.paginationListRows);

        const requestData = new AuthorizedRequestData(token, {}, {
            skip: skip,
            limit: limit,
        });
        try {
            const ordersResponse = await getOrders(requestData);
            this.setState({
                firstPaginationIndex: startIndex,
                orders: ordersResponse.getData().reverse(),
                loading: false,
                ordersCount: ordersResponse.getObjectsCount()
            })

            //Get clients Data
            const clients = this.getClientsIdMapFromOrders(ordersResponse.getData());
            this.setState({
                clients
            })
            this.clientRequestResult(await this.getClientsRequest());

        } catch (err) {
            return new CountableResponse<IOrder[]>([], 0);
        }
    }

    @Bind()
    async getOrdersCount(): Promise<number> {
        const token = await this.props.getToken();
        const mockRequestData = new AuthorizedRequestData(token, {}, {
            skip: 0,
            limit: 1
        });

        try {
            const ordersResponse = await getOrders(mockRequestData);
            console.log('ordersResponse',ordersResponse.getObjectsCount())
            return ordersResponse.getObjectsCount();

        } catch (err) {
            return 0;
        }
    }

    rowClassName(order: IOrder) {
        const currentDate = new Date(Date.now())
        const orderDate = order.getCreatedDate()

        return {order_list_table_row_current_day: orderDate ? orderDate.toLocaleDateString() === currentDate.toLocaleDateString() : false}
    }

    @Bind()
    renderTable() {
        if (this.state.screenWidth < this.breakpointWidth) {
            return this.renderSmallSizeTable();
        }

        return this.renderFullSizeTable();
    }

    renderFullSizeTable() {
        return <DataTable
            className="main_list_table mb-3"
            value={this.state.orders}
            expandedRows={this.state.expandedRows}
            onRowToggle={(e: any) =>
                this.setState({expandedRows: e.data})
            }
            rowExpansionTemplate={this.rowExpansionTemplate}
            paginator={true}
            totalRecords={this.state.ordersCount}
            rows={this.state.paginationListRows}
            rowClassName={this.rowClassName}
            first={this.state.firstPaginationIndex}
            lazy={true}
            loading={this.state.loading}
            onPage={this.onPage}
        >
            <Column expander={true} style={{width: "3em"}}/>
            <Column
                body={(order: IOrder) => {
                    return getOrderStatusTitle(order.getOrderStatus());
                }}
                header="Статус"
                style={{width: "150px", textAlign: "center"}}
            />
            <Column
                body={(order: IOrder) => {
                    return order.getCreatedDate() ? order.getCreatedDate()!.toLocaleDateString() : "нет данных";
                }}
                style={{width: "10em", textAlign: "center"}}
                header="Создан"
            />
            <Column
                body={(order: IOrder) => {
                    const noDataMessage = "Не заполнено пользователем!";
                    const clientId = order.getClientId();

                    if (clientId) {
                        const client = this.state.clients.get(clientId);
                        return client ? client.getFullName() !== "" ? client.getFullName() : noDataMessage : noDataMessage;
                    }
                    return noDataMessage;
                }}
                header="ФИО пользователя"
            />
            <Column
                body={(order: IOrder) => {
                    const noDataMessage = "нет данных!";
                    const clientId = order.getClientId();

                    if (clientId) {
                        const client = this.state.clients.get(clientId);
                        return client ? client.getPhone() !== "" ? client.getPhone() : noDataMessage : noDataMessage;
                    }
                    return noDataMessage;
                }}
                header="Телефон"
            />
            <Column
                field="totalWithDiscount"
                header="Цена"
                style={{width: "200px", textAlign: "center"}}
            />
            <Column
                body={this.actionTemplate}
                style={{width: "4em"}}
            />
        </DataTable>
    }

    renderSmallSizeTable() {
        return <DataTable
            className="main_list_table mb-3"
            value={this.state.orders}
            expandedRows={this.state.expandedRows}
            onRowToggle={(e: any) =>
                this.setState({expandedRows: e.data})
            }
            rowExpansionTemplate={this.rowExpansionTemplate}
            paginator={true}
            totalRecords={this.state.ordersCount}
            rows={this.state.paginationListRows}
            rowClassName={this.rowClassName}
            first={this.state.firstPaginationIndex}
            lazy={true}
            loading={this.state.loading}
            onPage={this.onPage}
        >
            <Column
                header={"Заказы"}
                body={(order: IOrder) => {
                    const getClient = () => {
                        const clientId = order.getClientId();

                        if (clientId) {
                            return this.state.clients.get(clientId);
                        }
                        return undefined;
                    }
                    const getClientName = () => {
                        const noDataMessage = "нет данных!";
                        const client = getClient();
                        return client ? client.getFullName() !== "" ? client.getFullName() : noDataMessage : noDataMessage;
                    }
                    const getPhoneNumber = () => {
                        const noDataMessage = "нет данных!";
                        const client = getClient();
                        return client ? client.getPhone() !== "" ? client.getPhone() : noDataMessage : noDataMessage;
                    }

                    return <Link to={RouteURLs.orders + "/" + order.getId()}>
                        <div className="order_list_table_small_cell">
                            <div className="content">
                                <p>Статус: <span className="data">{getOrderStatusTitle(order.getOrderStatus())}</span>
                                </p>
                                <p>Дата создания: <span
                                    className="data">{order.getCreatedDate() ? order.getCreatedDate()!.toLocaleDateString() : "нет данных"}</span>
                                </p>
                                <p>ФИО клиента: <span className="data">{getClientName()}</span></p>
                                <p>Телефон: <span className="data">{getPhoneNumber()}</span></p>
                            </div>
                            <div className="price">
                                <p>{order.getTotalPriceWithDiscount()}</p>
                            </div>
                        </div>
                    </Link>
                }}
            />
        </DataTable>
    }

    render() {
        return (
            <>
            <TableComponentTmp/>
            </>
            
        )
        // return (
        //     <div className="row">
        //         <ContentLoaderComponent<ICountableResponse<IOrder[]>>
        //             contentRequest={this.getOrdersRequest}
        //             resultCallback={this.ordersRequestResult}
        //             ref={el => {
        //                 this.loaderRef = el;
        //               }}
        //         >
        //             {/* <ContentLoaderComponent<IService[]>
        //                 contentRequest={this.getServicesRequest}
        //                 resultCallback={this.servicesRequestResult}
        //                 ref={el => {
        //                     this.loaderRef = el;
        //                   }}
        //             > */}
        //                 <ContentLoaderComponent<IClient[]>
        //                     contentRequest={this.getClientsRequest}
        //                     resultCallback={this.clientRequestResult}
        //                     ref={el => {
        //                         this.loaderRef = el;
        //                       }}
        //                 >
        //                     <div className="col-lg-12 ">
        //                         <div className="row">
        //                             <div className="col-lg-12">
        //                                 <h3>Список заказов</h3>
        //                             </div>
        //                         </div>
        //                         <div className="row">
        //                             <div className="col-lg-12">
        //                                 {/* <h3>Фильтр компонент</h3> */}
        //                                 {/* <SearchComponent
        //             style={{marginBottom: '10px'}}
        //             searchValue={this.state.searchVal}
        //             onChangeHandler={(searchVal) => {
        //                 this.setState({searchVal});
        //             }}
        //             searchCallback={(searchVal) => {
        //                 this.setState({searchVal});
        //                 this.reloadContent()
        //             }}
        //         /> */}
        //                             </div>
        //                         </div>
        //                         <div className="row">
        //                             <div className="col-lg-12">
        //                                 {this.renderTable()}
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </ContentLoaderComponent>
        //             </ContentLoaderComponent>
        //         {/* </ContentLoaderComponent> */}
        //     </div>
        // );
     }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(OrderListComponent);
