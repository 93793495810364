
export const GET_COLUMN = (filtersFromUrl: any):any => [
  {
    name: "orderStatus",
    label: "Статус Заказа",
  },
  {
    name: "createdOn",
    label: "Дата заказа",
  },
  {
    name: "onlyTime",
    label: "Время заказа",
  },
  {
    name: "totalWithDiscount",
    label: "Цена",
  },
  {
    name: "phoneNumber",
    label: "Номер пользователя",
  },
  {
    name: "userName",
    label: "Имя пользователя",
  },
  {
    name: "id",
    label: "Id заказа",
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
];
export const OPTIONS: any = {
  print: false,
  download: false,
  filter: false,
  search: false,
  sort: false,
  viewColumns: false,
  selectableRows: false,

  textLabels: {
    body: {
      noMatch: "К сожалению, подходящие записи не найдены",
      toolTip: "Сортировать",
      columnHeaderTooltip: (column: any) => `Сортировать по ${column.label}`,
    },
    pagination: {
      next: "Следующая Страница",
      previous: "предыдущий",
      rowsPerPage: "Рядов на странице:",
      displayRows: "из",
    },
    toolbar: {
      search: "Поиск",
      downloadCsv: "Скачать CSV",
      print: "Распечатать",
      viewColumns: "Просмотр столбцов",
      filterTable: "Таблица фильтров",
    },
    filter: {
      all: "Все",
      title: "ФИЛЬТРЫ",
      reset: "Сброс",
    },
    viewColumns: {
      title: "Показать столбцы",
      titleAria: "Показать/скрыть столбцы таблицы",
    },
    selectedRows: {
      text: "строк(и) выбраны",
      delete: "Удалить",
      deleteAria: "Удалить выбранные строки",
    },
  },
};
