import React from "react";
import RouteURLs from "../../../actions/routesURL";
import ContentLoaderComponent from "../../common/ContentLoaderComponent";
import {getAccessToken} from "../../../helpers/RequestHelpers";
import {DataTable} from "primereact/datatable";
import {connect} from "react-redux";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Growl} from "primereact/growl";
import {Link} from "react-router-dom";
import {
    AuthorizedRequestData,
    ICountableResponse,
    CountableResponse,
    IDestination,
    Destination,
    requestDestinations,
    deleteDestinationRequest, Bind
} from "@shift-mono/common";

interface IDestinationListComponentProps {
    getToken: () => Promise<string>;
}

interface IDestinationListComponentState {
    destinations: IDestination[];
    destinationsCount: number;
    paginationListRows: number;
    firstPaginationIndex: number;
    loading: boolean;
    delete_dialog_visible: boolean;
    deleting_destination_id: string | undefined;
}

class DestinationListComponent extends React.Component<IDestinationListComponentProps,
    IDestinationListComponentState> {
    private growl: any = undefined;
    private loaderRef: any = undefined;

    constructor(props: IDestinationListComponentProps) {
        super(props);

        this.state = {
            destinations: [],
            destinationsCount: 0,
            paginationListRows: 10,
            firstPaginationIndex: 0,
            loading: true,
            delete_dialog_visible: false,
            deleting_destination_id: undefined
        };
    }
    @Bind()
    async contentRequest(): Promise<ICountableResponse<IDestination[]>> {
        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token);
        try {
            return requestDestinations(request_data);
        } catch (err) {
            return new CountableResponse<Destination[]>([], 0);
        }

    }
    @Bind()
    requestResult(destinations: ICountableResponse<IDestination[]>) {
        this.setState({
            destinations: destinations.getData(),
            destinationsCount: destinations.getObjectsCount(),
            loading: false
        });
    }
    @Bind()
    async deleteDestinationRequest(destination_id: string) {
        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token);

        try {
            const resultStatus = await deleteDestinationRequest(
                destination_id,
                request_data
            );
            if (resultStatus) {
                this.growl.show({
                    severity: "success",
                    summary: "Успешно",
                    detail: "Услуга удалена"
                });
                if (this.loaderRef !== undefined) {
                    this.loaderRef.tryToLoading();
                }
            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Ошибка удаления"
                });
            }
        } catch (err) {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка удаления"
            });
        }
    }
    @Bind()
    actionTemplate(rowData: Destination, column: any) {
        return (
            <>
                <Link
                    to={RouteURLs.destinations + "/" + rowData.getId()}>
                    <Button
                        type="button"
                        icon="pi pi-pencil"
                        className="p-button-info mr-3"
                    />
                </Link>
                <Button
                    type="button"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={() => {
                        this.showDeleteDialog(rowData.getId());
                    }}
                />
            </>
        );
    }
    @Bind()
    showDeleteDialog(destination_id: string) {
        this.setState({
            delete_dialog_visible: true,
            deleting_destination_id: destination_id
        });
    }
    @Bind()
    hideDeleteDialog() {
        this.setState({delete_dialog_visible: false});
    }

    deleteDialogFooterTemplate = (
        <div>
            <Button
                label="Да"
                icon="pi pi-check"
                onClick={async () => {
                    if (this.state.deleting_destination_id) {
                        await this.deleteDestinationRequest(
                            this.state.deleting_destination_id
                        );
                        this.setState({deleting_destination_id: undefined});
                    } else {
                        this.growl.show({
                            severity: "error",
                            summary: "Ошибка",
                            detail: "Ошибка удаления"
                        });
                    }

                    this.hideDeleteDialog();
                }}
            />
            <Button
                label="Нет"
                icon="pi pi-times"
                onClick={() => this.hideDeleteDialog()}
            />
        </div>
    );
    @Bind()
    async onPage(event: any) {
        this.setState({
            loading: true
        });

        const startIndex = event.first;
        const endIndex = this.state.paginationListRows;

        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token, {}, {
            skip: startIndex,
            limit: endIndex
        });
        try {
            const destinationsResponse = await requestDestinations(request_data);
            this.setState({
                firstPaginationIndex: startIndex,
                destinations: destinationsResponse.getData(),
                loading: false,
                destinationsCount: destinationsResponse.getObjectsCount()
            })
        } catch (err) {
            return new CountableResponse<Destination[]>([], 0);
        }
    }

    render() {
        return (
            <>
                <Growl
                    ref={el => {
                        this.growl = el;
                    }}
                />
                <Dialog
                    header="Внимание!"
                    footer={this.deleteDialogFooterTemplate}
                    visible={this.state.delete_dialog_visible}
                    style={{width: "50vw"}}
                    modal={true}
                    onHide={() => {
                        this.hideDeleteDialog();
                    }}
                >
                    Вы уверенны, что хотите удалить место назначения?
                </Dialog>
                <ContentLoaderComponent<ICountableResponse<IDestination[]>>
                    contentRequest={this.contentRequest}
                    resultCallback={this.requestResult}
                    ref={el => {
                        this.loaderRef = el;
                    }}
                >
                    <DataTable
                        className="main_list_table mt-2"
                        value={this.state.destinations}
                        paginator={true}
                        totalRecords={this.state.destinationsCount}
                        rows={this.state.paginationListRows}
                        first={this.state.firstPaginationIndex}
                        lazy={true}
                        loading={this.state.loading}
                        onPage={this.onPage}
                    >
                        <Column field="title" header="Название"/>
                        <Column body={this.actionTemplate} style={{width: "8em"}}/>
                    </DataTable>
                </ContentLoaderComponent>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(DestinationListComponent);
