"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Token = void 0;
var Model_1 = require("./Model");
var errors_1 = require("../errors");
var Token = /** @class */function (_super) {
  __extends(Token, _super);
  function Token(args) {
    var _this = _super.call(this) || this;
    _this.getAccessToken = function () {
      return _this.accessToken;
    };
    _this.getRefreshToken = function () {
      return _this.refreshToken;
    };
    _this.getExpiresIn = function () {
      return _this.expiresIn;
    };
    _this.getScope = function () {
      return _this.scope;
    };
    _this.getTokenType = function () {
      return _this.tokenType;
    };
    _this.accessToken = args.accessToken;
    _this.refreshToken = args.refreshToken;
    _this.expiresIn = args.expiresIn;
    _this.scope = args.scope;
    _this.tokenType = args.tokenType;
    return _this;
  }
  Token.fromJson = function (data) {
    var requiredProps = ["access_token", "expires_in", "token_type", "refresh_token", "scope"];
    var verifyResult = Token.verifyRequiredProperties(data, requiredProps);
    if (!verifyResult.isAllPropsExist) {
      throw new errors_1.MissingPropertiesError("Missing props: ".concat(verifyResult.missingProps.toString(), " in ").concat(this.constructor.name, " json data."));
    }
    return new Token({
      accessToken: data.access_token ? data.access_token : "",
      refreshToken: data.refresh_token ? data.refresh_token : "",
      expiresIn: data.expires_in ? data.expires_in : false,
      scope: data.scope ? data.scope : "",
      tokenType: data.token_type ? data.token_type : ""
    });
  };
  return Token;
}(Model_1.Model);
exports.Token = Token;