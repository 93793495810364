import React from "react";
import {Bind, IPartner} from "@shift-mono/common";
import {Dropdown} from "primereact/dropdown";

interface IPartnerPickerComponentProps extends React.HTMLAttributes<HTMLDivElement> {
    availablePartners: IPartner[],
    changeHandler: (selectedPartner: any) => void,
    currentPartnerId?: string,
    currentPartner:IPartner | undefined
}

interface IPartnerPickerComponentState {
    currentPartnerItem:any
}

interface DropdownItem {
    title: string,
    value: IPartner | undefined
}

export default class PartnerPickerComponent extends React.Component<IPartnerPickerComponentProps, IPartnerPickerComponentState> {
    constructor(props: IPartnerPickerComponentProps) {
        super(props)
        this.state = {
            currentPartnerItem: props.currentPartner
        }
    }
    @Bind()
    getOptions(): DropdownItem[] {
        return [...this.props.availablePartners, undefined]
            .map((item) => {
                return this.getOptionItemFor(item)
            })
    }

    getOptionItemFor(partner: IPartner | undefined) {
        return partner
            ? {title: partner.getTitle(), value: partner}
            : {title: "Нет партнера", value: partner}
    }
    componentWillReceiveProps(nextProps: Readonly<IPartnerPickerComponentProps>, nextContext: any): void {
        if (nextProps.currentPartner !== this.state.currentPartnerItem) {
            this.setState({ currentPartnerItem: nextProps.currentPartner })
        }
    }
    render() {
        // const currentPartner = this.props.availablePartners
        //     .filter((partner) => {
        //         return partner.getId() === this.props.currentPartnerId 
        //     })
        //     .pop()

        // const current = this.getOptionItemFor(currentPartner);
        return <Dropdown
            className={this.props.className ? this.props.className : ""}
            optionLabel={"title"}
            value={this.state.currentPartnerItem??{title: "Нет партнера", value: undefined}}
            options={this.getOptions()}
           // dataKey={'value'}
            onChange={(e) => {
                this.props.changeHandler(e.value);
            }}
            placeholder="Выберите партнера"/>
    }
}