import { RestClient } from "..";
import createOrderListFromRowData from "../../models/modelFactory/order";
import { API } from "./utils";

interface IOrderParams {
  skip: number,
  limit: number,
  search?: string,
  status?: string,
  createdOnFrom?: string,
  createdOnTo?: string,
  priceFrom?: string,
  priceTo?: string,
  sortBy: string,
  sortType: string,
}

const queryBuilder = (url: any, queryObject: any) => {
  const newUrl = Object.entries(queryObject).reduce((acc, [key, value], index) => {
    if (value !== undefined && value!=='') {
      acc = acc + `${key}=${value}&`
      return acc
    }
    return acc
  }, url)
  return newUrl
}

export const OrderClient = (): any => {
  const { get } = RestClient();
  const getOrders = (params: IOrderParams) =>
    get({ url: queryBuilder(API + '/v1.0/order?fromadminpanel=true&', params) }, true).then(
      (data: any) =>
        createOrderListFromRowData({
          data: data.data,
          header: data.headers,
        })
    );

  return {
    getOrders,
  };
};
