import React from "react";
import {Growl} from "primereact/growl";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {DataTable} from "primereact/datatable";
import DirectionCostSelectorComponent from "./DirectionCostSelectorComponent";
import {
    ServiceType,
    IDirectionCost,
    IMonthStorageCost,
    IDestination,
    IDirection,
    Direction, Bind
} from "@shift-mono/common";

interface ITypedInputComponentProps {
    type: ServiceType;
    available_delivery_direction?: IDirection[];
    available_destinations?: IDestination[];
    changeHandle: (value: IOutput) => void;
    default_cost?: number;
    default_month_storage_cost_list?: IMonthStorageCost[];
    default_price_per_direction?: IDirectionCost[];
}

interface ITypedInputComponentState {
    firstInit: boolean;
    selected_month_storage_value: any | null;
    available_month_storages: any[];
    cost: number;
    selected_delivery_direction: IDeliveryDirectionDropdownItem | null;
    selected_price_per_direction_list: CostPerDirection[];
    selected_month_storage_list: IMonthStorageCost[];
}

export interface IOutput {
    current_type: ServiceType;
    cost?: number;
    cost_per_directions_list?: IDirectionCost[];
    cost_per_month_list?: IMonthStorageCost[];
}

interface IDeliveryDirectionDropdownItem {
    direction: Direction;
    title: string;
}

export class CostPerDirection implements IDirectionCost {
    constructor(
        private cost: number,
        private directionId: string,
        private direction?: IDirection
    ) {
    }

    getCost() {
        return this.cost;
    }

    setCost(cost: number) {
        this.cost = cost;
    }

    getId() {
        return this.directionId;
    }

    getDirection() {
        return this.direction;
    }
}

class CostPerMonths implements IMonthStorageCost {
    constructor(private cost: number, private month_count: number) {
    }

    getMonthCount() {
        return this.month_count;
    }

    getCost() {
        return this.cost;
    }
}

export class TypedInputComponent extends React.Component<ITypedInputComponentProps,
    ITypedInputComponentState> {
    private growl: any = undefined;

    constructor(props: ITypedInputComponentProps) {
        super(props);
        this.state = {
            firstInit: true,
            selected_month_storage_value: null,
            available_month_storages: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                (item: number) => {
                    let month_name = "Месяц";
                    if (item > 1) {
                        month_name = "Месяца";
                    }
                    if (item > 4) {
                        month_name = "Месяцев";
                    }
                    return {title: item + " " + month_name, value: item};
                }
            ),
            cost: this.props.default_cost ? this.props.default_cost : 0,
            selected_delivery_direction: null,
            selected_price_per_direction_list: [],
            selected_month_storage_list: this.props.default_month_storage_cost_list
                ? this.props.default_month_storage_cost_list
                : []
        };
    }

    shouldComponentUpdate(
        nextProps: ITypedInputComponentProps,
        nextState: ITypedInputComponentState
    ) {
        if (
            nextProps.available_delivery_direction !== undefined &&
            nextProps.available_delivery_direction.length > 0 &&
            nextProps.default_price_per_direction !== undefined &&
            this.state.firstInit
        ) {
            nextState.selected_price_per_direction_list = this.compileCostPerDirection(
                nextProps.default_price_per_direction,
                nextProps.available_delivery_direction
            );
            nextState.firstInit = false;
        }

        return true;
    }
    @Bind()
    clearForm() {
        this.setState({
            selected_month_storage_value: null,
            cost: 0,
            selected_delivery_direction: null,
            selected_price_per_direction_list: [],
            selected_month_storage_list: []
        });
    }

    compileCostPerDirection(
        directionsCost: IDirectionCost[],
        availableDirections: IDirection[]
    ): CostPerDirection[] {
        return directionsCost.map((dirCost: IDirectionCost) => {
            const findDirections = availableDirections.filter(
                (direction: IDirection) => {
                    return dirCost.getId() === direction.getId();
                }
            );

            const findDirection =
                findDirections.length > 0 ? findDirections[0] : undefined;
            return new CostPerDirection(
                dirCost.getCost(),
                dirCost.getId(),
                findDirection
            );
        });
    }
    @Bind()
    getDirectionTitle(direction: Direction): string {
        const firstDestinationId = direction.getVertices()[0]
            ? direction.getVertices()[0]
            : "";
        const secondDestinationId = direction.getVertices()[1]
            ? direction.getVertices()[1]
            : "";

        if (this.props.available_destinations) {
            const firstDestination = this.props.available_destinations.filter(
                (item: IDestination) => {
                    return item.getId() === firstDestinationId;
                }
            )[0];
            const secondDestination = this.props.available_destinations.filter(
                (item: IDestination) => {
                    return item.getId() === secondDestinationId;
                }
            )[0];

            let firstDestinationTitle = firstDestinationId;
            let secondDestinationTitle = secondDestinationId;

            if (firstDestination) {
                firstDestinationTitle = firstDestination.getTitle();
            }
            if (secondDestination) {
                secondDestinationTitle = secondDestination.getTitle();
            }

            return firstDestinationTitle + " : " + secondDestinationTitle;
        } else {
            return firstDestinationId + " : " + secondDestinationId;
        }
    }
    @Bind()
    deliveryDirectionChanged(e: any) {
        this.setState({
            selected_delivery_direction: e.value
        });
    }
    @Bind()
    monthStorageChanged(e: any) {
        this.setState({selected_month_storage_value: e.value});
    }
    @Bind()
    storageActionTemplate(storageCost: IMonthStorageCost) {
        return (
            <>
                <Button
                    type="button"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={() => {
                        this.removeStorageCost(storageCost);
                    }}
                />
            </>
        );
    };
    @Bind()
    removeStorageCost(storageCost: IMonthStorageCost) {
        const updatedSelectedMonthStorageList = this.state.selected_month_storage_list.filter((selectedStorageCost) => {
            return (!(storageCost.getCost() === selectedStorageCost.getCost() && storageCost.getMonthCount() === selectedStorageCost.getMonthCount()));
        });

        this.setState({
            selected_month_storage_list: updatedSelectedMonthStorageList
        });
        this.props.changeHandle({
            current_type: this.props.type,
            cost_per_month_list: updatedSelectedMonthStorageList
        });
    }
    @Bind()
    moveServiceActionTemplate(costPerDirection: CostPerDirection) {
        return (
            <>
                <Button
                    type="button"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={() => {
                        this.removeCostPerDirection(costPerDirection);
                    }}
                />
            </>
        );
    }
    @Bind()
    removeCostPerDirection(costPerDirection: CostPerDirection) {
        const updatedSelectedPricePerDirectionList = this.state.selected_price_per_direction_list.filter((selectedPricePerDirection: CostPerDirection) => {
            return selectedPricePerDirection.getId() !== costPerDirection.getId();
        });

        this.setState({
            selected_price_per_direction_list: updatedSelectedPricePerDirectionList,
        });

        this.props.changeHandle({
            current_type: this.props.type,
            cost_per_directions_list: updatedSelectedPricePerDirectionList
        });
    }

    render() {
        const storageServiceComponent = (
            <>
                <div className="row m-1">
                    <label className="col-lg-4">Кол-во месяцев</label>
                    <Dropdown
                        className="col-lg-4"
                        value={this.state.selected_month_storage_value}
                        options={this.state.available_month_storages}
                        onChange={this.monthStorageChanged}
                        placeholder="Кол-во месяцев"
                        dataKey="value"
                        optionLabel="title"
                    />
                </div>
                <div className="row m-1">
                    <label className="col-lg-4">Цена</label>
                    <InputText
                        className="col-lg-4"
                        value={this.state.cost}
                        placeholder="Цена"
                        keyfilter="int"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            this.setState({cost: Number(e.target.value)})
                        }
                    />
                </div>
                <div className="row m-1 ml-2">
                    <Button
                        label="Добавить вариант хранения"
                        onClick={() => {
                            const month_count = this.state.selected_month_storage_value;
                            const cost = this.state.cost;

                            if (month_count === null || cost <= 0) {
                                this.growl.show({
                                    severity: "error",
                                    summary: "Ошибка",
                                    detail: "Не все поля заполнены"
                                });
                                return;
                            }

                            const price_per_month = [
                                ...this.state.selected_month_storage_list,
                                new CostPerMonths(cost, month_count.value)
                            ];
                            this.setState({
                                selected_month_storage_list: price_per_month,
                                selected_month_storage_value: null,
                                cost: 0
                            });

                            this.props.changeHandle({
                                current_type: this.props.type,
                                cost_per_month_list: price_per_month
                            });
                        }}
                    />
                </div>
                <div className="row m-1">
                    <label className="col-lg-4">Варианты хранения</label>
                </div>
                <div className="row m-1">
                    <div className="col-12">
                        <DataTable
                            className="main_list_table"
                            value={this.state.selected_month_storage_list}
                        >
                            <Column
                                body={(rowData: IMonthStorageCost) => {
                                    return rowData.getMonthCount();
                                }}
                                header="Кол-во месяцев"
                            />
                            <Column
                                body={(rowData: IMonthStorageCost) => {
                                    return rowData.getCost();
                                }}
                                header="Цена"
                            />
                            <Column body={this.storageActionTemplate} style={{width: "4em"}}/>
                        </DataTable>
                    </div>
                </div>
            </>
        );

        const moveServiceComponent = <DirectionCostSelectorComponent
            selectedDirections={this.state.selected_price_per_direction_list}
            changeSelectedDirectionHandler={
                (selectedDirections: CostPerDirection[]) => {
                    this.setState({selected_price_per_direction_list: selectedDirections})
                    this.props.changeHandle({
                        current_type: this.props.type,
                        cost_per_directions_list: selectedDirections
                    });
                }
            }/>;

        const otherServiceComponent = (
            <div className="row m-1">
                <label className="col-lg-4">Цена</label>
                <InputText
                    className="col-lg-4"
                    value={this.state.cost}
                    placeholder="Цена"
                    keyfilter="int"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const new_cost = Number(e.target.value);

                        if (new_cost <= 0) {
                            this.growl.show({
                                severity: "error",
                                summary: "Ошибка",
                                detail: "Цена не может быть меньше или равна 0"
                            });
                            return;
                        }

                        this.setState({cost: new_cost});
                        this.props.changeHandle({
                            current_type: this.props.type,
                            cost: new_cost
                        });
                    }}
                />
            </div>
        );

        let component;
        switch (this.props.type) {
            case ServiceType.Store:
                component = storageServiceComponent;
                break;
            case ServiceType.Luggage:
            case ServiceType.Move:
                component = moveServiceComponent;
                break;
            case ServiceType.Other:
                component = otherServiceComponent;
                break;
            default:
                component = otherServiceComponent;
                break;
        }
        return (
            <>
                <Growl ref={el => (this.growl = el)}/>
                {component}
            </>
        );
    }
}
