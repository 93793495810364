import React, {Fragment} from "react";
import DiscountListComponent from "./DiscountListComponent";
import AddDiscountFormComponent from "./AddDiscountFormComponent";
import TabMenuComponent, {ITabItem} from "../../common/TabMenu/TabMenuComponent";
import WithdrawalComponent from "../withdrawal/WithdrawalComponent";

interface IDiscountComponentProps {
}

interface IDiscountComponentState {
    items: ITabItem[];
}

export default class DiscountComponent extends React.Component<IDiscountComponentProps,
    IDiscountComponentState> {
    constructor(props: IDiscountComponentProps) {
        super(props);
        this.state = {
            items: [
                {
                    id: 1,
                    label: "Промокоды",
                    component: <DiscountListComponent/>,
                }, {
                    id: 2,
                    label: "Заявки на списание средств",
                    component: <WithdrawalComponent/>,
                },
                {
                    id: 3,
                    label: "Добавить промокод",
                    component: <AddDiscountFormComponent/>,
                }
            ],
        };
    }

    render() {
        return (
            <Fragment>
                <TabMenuComponent
                    items={this.state.items}
                />
            </Fragment>
        );
    }
}
