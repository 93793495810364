import React from "react";
import { connect } from "react-redux";
import { getAccessToken } from "../../../helpers/RequestHelpers";
import { Growl } from "primereact/growl";
import DestinationFormComponent, {
  IDestinationFormData
} from "./form_partials/DestinationFormComponent";
import {
  Bind,
  DestinationRequestData,
  postDestinationRequest
} from "@shift-mono/common";

interface IAddDestinationFormComponentProps {
  getToken: () => Promise<string>;
}
interface IAddDestinationFormComponentState {}

class AddDestinationFormComponent extends React.Component<
  IAddDestinationFormComponentProps,
  IAddDestinationFormComponentState
> {
  private growl: any = undefined;
  private formRef: any = undefined;
  private geoJsonValidator: any = require("geojson-validation");

  @Bind()
  async validateForm(formData: IDestinationFormData): Promise<boolean> {
    if (
      formData.title.trim() === "" ||
      formData.destinationCenter.trim() === "" ||
      formData.destinationBoundingBox.trim() === "" ||
      formData.destinationPolygon.trim() === ""
    ) {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Не все поля заполнены"
      });
      return false;
    }

    const centerIsPoint = await new Promise((resolve, rejects) => {
      this.geoJsonValidator.isPoint(
        JSON.parse(formData.destinationCenter),
        (isValid: boolean) => {
          resolve(isValid);
        }
      );
    });

    if (!centerIsPoint) {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Центральная точка не корректна"
      });
      return false;
    }

    const boundingBoxIsLineString = await new Promise((resolve, rejects) => {
      this.geoJsonValidator.isLineString(
        JSON.parse(formData.destinationBoundingBox),
        (isValid: boolean) => {
          resolve(isValid);
        }
      );
    });

    if (!boundingBoxIsLineString) {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Минимальный описывающий прямоугольник не корректен"
      });
      return false;
    }

    const polygonIsPolygon = await new Promise((resolve, rejects) => {
      this.geoJsonValidator.isPolygon(
        JSON.parse(formData.destinationPolygon),
        (isValid: boolean) => {
          resolve(isValid);
        }
      );
    });

    if (!polygonIsPolygon) {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Полигон не корректен"
      });
      return false;
    }

    return true;
  }
  @Bind()
  async sendDestination(formData: IDestinationFormData) {
    const token = await this.props.getToken();
    const title = formData.title.trim();
    const localizedTitle = formData.localizedTitle;
    const center = formData.destinationCenter.trim();
    const boundingBox = formData.destinationBoundingBox.trim();
    const polygon = formData.destinationPolygon.trim();
    const coefficient=formData.coefficient;

    let destination_added = false;

    if (await this.validateForm(formData)) {
      const request_data = new DestinationRequestData({
        accessToken: token,
        title: title,
        localizedTitle: localizedTitle,
        center: center,
        boundingBox: boundingBox,
        polygon: polygon,
        coefficient:coefficient,
      });

      destination_added = await postDestinationRequest(request_data);
    }else{
      return;
    }

    if (destination_added) {
      this.growl.show({
        severity: "success",
        summary: "Успешно",
        detail: "Место добавлено"
      });
      if (this.formRef) {
        this.formRef.clearForm();
      }
    } else {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Ошибка добавления"
      });
    }
  }

  render() {
    return (
      <>
        <Growl ref={el => (this.growl = el)} />
        <DestinationFormComponent
          ref={el => {
            this.formRef = el;
          }}
          saveCallback={(formData: IDestinationFormData) => {
            this.sendDestination(formData);
          }}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getToken: () => dispatch(getAccessToken())
  };
};

export default connect(null, mapDispatchToProps)(AddDestinationFormComponent);
