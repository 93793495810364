import React from "react";
import {getAccessToken} from "../../../../helpers/RequestHelpers";
import {connect} from "react-redux";
import {
    AuthorizedRequestData,
    Bind,
    CountableResponse,
    ICountableResponse,
    IPartner,
    IUser,
    Logger,
    PartnerRequestData,
    putPartnerRequest,
    requestAccounts,
    requestPartnerById
} from "@shift-mono/common";
import ContentLoaderComponent from "../../../common/ContentLoaderComponent";
import {Growl} from "primereact/growl";
import PartnerFormComponent, {IPartnerFormData} from "./form_partials/PartnerFormComponent";
import BackButtonComponent from "../../../common/BackButtonComponent";

interface IPartnerDetailComponentProps {
    getToken: () => Promise<string>;
    match: any;
}

interface IPartnerDetailComponentState {
    currentPartner: IPartner | undefined;
    accounts: IUser[];
}

class PartnerDetailComponent extends React.Component<IPartnerDetailComponentProps, IPartnerDetailComponentState> {
    private growl: any = undefined;
    private formRef: any = undefined;

    constructor(props: IPartnerDetailComponentProps) {
        super(props);

        this.state = {
            currentPartner: undefined,
            accounts: []
        }
    }

    @Bind()
    async updatePartner(data: IPartnerFormData) {
        if (!this.state.currentPartner) {
            return;
        }
        try {
            const token = await this.props.getToken();
            const partnerId = this.state.currentPartner!.getId();

            const partnerRequestData = new PartnerRequestData({
                accessToken: token,
                partnerTitle: data.title,
                partnerId: partnerId,
            })
            if (await putPartnerRequest(partnerRequestData)) {
                this.growl.show({
                    severity: "success",
                    summary: "Успешно",
                    detail: "Партнер обновлен"
                });
            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Ошибка обновления партнера"
                });
            }
        } catch (err) {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка обновления партнера"
            });
        }

    }

    @Bind()
    async getPartnerRequest(): Promise<IPartner | undefined> {
        const token = await this.props.getToken();
        const id = this.props.match.params.id;
        const requestData = new AuthorizedRequestData(token);

        try {
            return (await requestPartnerById(id, requestData)).getData();
        } catch (err) {
        }
    }

    @Bind()
    partnerRequestResult(partner: IPartner | undefined) {
        this.setState({
            currentPartner: partner,
        })
    }

    @Bind()
    async getAccountsRequest(): Promise<ICountableResponse<IUser[]>> {
        try {
            const token = await this.props.getToken();
            const requestData = new AuthorizedRequestData(token);
            return requestAccounts(requestData);
        } catch (err) {
            return new CountableResponse<IUser[]>([], 0);
        }
    }

    @Bind()
    accountsRequestResult(result: ICountableResponse<IUser[]>) {
        this.setState({
            accounts: result.getData()
        })
    }

    render() {

        return <>
            <Growl ref={el => (this.growl = el)}/>
            <ContentLoaderComponent<IPartner | undefined>
                contentRequest={this.getPartnerRequest}
                resultCallback={this.partnerRequestResult}
                errorCallback={(err) => {
                    Logger.d(err);
                }}
            >
                <ContentLoaderComponent<ICountableResponse<IUser[]>>
                    contentRequest={this.getAccountsRequest}
                    resultCallback={this.accountsRequestResult}
                    errorCallback={(err) => {
                        Logger.d(err);
                    }}
                >
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>
                                <BackButtonComponent/> Информация о партнере
                            </h3>
                        </div>
                    </div>
                    <PartnerFormComponent
                        ref={el => (this.formRef = el)}
                        saveCallback={this.updatePartner}
                        availableAccounts={this.state.accounts}
                        currentPartner={this.state.currentPartner}
                    />
                </ContentLoaderComponent>
            </ContentLoaderComponent>
        </>;
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(PartnerDetailComponent);