"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAuthDomain = exports.getApiDomain = void 0;
var getApiDomain = function getApiDomain() {
  return process.env.REACT_APP_API_DOMAIN ? process.env.REACT_APP_API_DOMAIN : "";
};
exports.getApiDomain = getApiDomain;
var getAuthDomain = function getAuthDomain() {
  return process.env.REACT_APP_AUTH_DOMAIN ? process.env.REACT_APP_AUTH_DOMAIN : "";
};
exports.getAuthDomain = getAuthDomain;