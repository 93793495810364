"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StoragePointLocationType = void 0;
var StoragePointLocationType;
(function (StoragePointLocationType) {
  StoragePointLocationType["Point"] = "point";
  StoragePointLocationType["None"] = "none";
})(StoragePointLocationType = exports.StoragePointLocationType || (exports.StoragePointLocationType = {}));