import EventMessagesComponent from "../../components/events/EventMessagesComponent";
import {IAuthorizedRequestData, IOrder, requestOrders, ICountableResponse} from "@shift-mono/common";

const dispatchNewOrderWrapper = (originalFunc: (requestData: IAuthorizedRequestData) => Promise<ICountableResponse<IOrder[]>>): (requestData: IAuthorizedRequestData) => Promise<ICountableResponse<IOrder[]>> => {

    return async (requestData: IAuthorizedRequestData): Promise<ICountableResponse<IOrder[]>> => {
        const resultPromise = originalFunc(requestData);
        resultPromise.then((response) => {
            response
                .getData()
                .filter((order) => {
                    return order.isNew()
                })
                .forEach((order) => {
                    const notificationData = {
                        title: "Создан заказ",
                        description: "",
                        objectId: order.getId(),
                        objectType: "order"
                    };
                    document.dispatchEvent(EventMessagesComponent.generateAddMessageEvent(notificationData));
                })
        })
        return resultPromise;
    }
}

//TODO Порефакторить
const wrappedGetOrders = dispatchNewOrderWrapper(requestOrders);
export {wrappedGetOrders as getOrders}