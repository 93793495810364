import React from "react";
import AccountsListComponent, {AccountsListType} from "./AccountsListComponent";

interface IAccountsComponentProps {
    listType: AccountsListType;
}

interface IAccountsComponentState {
}

export default class AccountsComponent extends React.Component<IAccountsComponentProps, IAccountsComponentState> {
    render() {
        return (<>
            <div className="row">
                <div className="col-lg-12 ">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>Список аккаунтов</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <AccountsListComponent listType={this.props.listType}/>
                        </div>
                    </div>
                </div>
            </div>
        </>);

    }
}