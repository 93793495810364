import React from "react";
import {Bind, OrderStatus, getOrderStatusTitle} from "@shift-mono/common";
import {Dropdown} from "primereact/dropdown";

interface IStatusPickerProps extends React.HTMLAttributes<HTMLDivElement> {
    availableStatus: OrderStatus[];
    onChangeHandler: (status: OrderStatus) => void;
    currentStatus: OrderStatus;
}

interface IStatusPickerState {
    currentStatusItem:any
}

interface IDropdownItem {
    title: string;
    value: OrderStatus;
}

export class StatusPicker extends React.Component<IStatusPickerProps, IStatusPickerState> {
    constructor(props:IStatusPickerProps){
    super(props)
    this.state={
        currentStatusItem:props.currentStatus
    }
    }
    @Bind()
    getOptions(): IDropdownItem[] {
        return [...this.props.availableStatus]
            .map((item) => {
                return this.getOptionItemFor(item)
            })
    }

    getOptionItemFor(orderStatus: OrderStatus) {
        return {title: getOrderStatusTitle(orderStatus), value: orderStatus}
    }
    componentWillReceiveProps(nextProps: Readonly<IStatusPickerProps>, nextContext: any): void {
        if(nextProps.currentStatus!==this.state.currentStatusItem){
            this.setState({currentStatusItem:nextProps.currentStatus})
        }
    }
    render() {
        return <Dropdown
            className={this.props.className ? this.props.className : ""}
            style={this.props.style ? this.props.style : {}}
            optionLabel={"title"}
            value={this.state.currentStatusItem}
            options={this.getOptions()}
            onChange={(e) => {
                this.props.onChangeHandler(e.value);
            }}
            dataKey={"value"}
            placeholder="Выберите статус"/>
    }
}