import React from "react";
import {withRouter} from "react-router-dom"
import ContentLoaderComponent from "../../common/ContentLoaderComponent";
import { Growl } from "primereact/growl";
import { Column } from "primereact/column";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { getAccessToken } from "../../../helpers/RequestHelpers";
import { getServiceTypeTitle } from "../../../helpers/ServiceHelpers";
import "./OrderDetailComponentStyle.scss";
import {
  AuthorizedRequestData,
  Bind,
  getOrderStatusTitle,
  IAddress,
  IClient,
  IDirection,
  IDiscount,
  IOrder,
  IOrderService,
  IService,
  IStoragePoint,
  Logger,
  OrderStatus,
  requestClientById,
  requestDirectionsByIds,
  requestDiscountById,
  requestOrderById,
  requestServicesByIds,
  requestStoragePointsByIds,
  StoragePointType,
  updateOrderStatus,
} from "@shift-mono/common";
import { ImageItem } from "./detail_partials/ImageItem";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { StatusPicker } from "./detail_partials/StatusPicker";
import moment from "moment";

interface IHash<T> {
  [serviceId: string]: T;
}

interface IOrderDetailComponentProps {
  getToken: () => Promise<string>;
  match: any;
}

interface IOrderDetailComponentState {
  currentOrder: IOrder | undefined;
  orderServices: IHash<IOrderService> | undefined;
  orderStoragePoints: IHash<IStoragePoint> | undefined;
  orderDirections: IHash<IDirection> | undefined;
  orderDiscount: IDiscount | undefined;
  selectedServiceModels: IService[];
  client: IClient | undefined;
  completeOrderDialog: boolean;

  availableStatus: OrderStatus[];
  currentDialogStatus: OrderStatus;
  description: string;
}

class OrderDetailComponent extends React.Component<
  IOrderDetailComponentProps,
  IOrderDetailComponentState
> {
  private growl: any = undefined;
  private loaderRef: any = undefined;

  constructor(props: IOrderDetailComponentProps) {
    super(props);
    this.state = {
      currentOrder: undefined,
      orderServices: undefined,
      orderDirections: undefined,
      orderDiscount: undefined,
      orderStoragePoints: undefined,
      selectedServiceModels: [],
      client: undefined,
      completeOrderDialog: false,

      availableStatus: [
        OrderStatus.Pending,
        OrderStatus.New,
        OrderStatus.CourierDelivery,
        OrderStatus.TransferredDelivery,
        OrderStatus.WaitingDelivery,
        OrderStatus.Complete,
        OrderStatus.Cancelled,
        OrderStatus.Storage,
      ],
      currentDialogStatus: OrderStatus.Complete,
      description: "",
    };
  }
  @Bind()
  async orderRequest() {
    const token = await this.props.getToken();
    const id = this.props.match.params.id;
    const requestData = new AuthorizedRequestData(token);

    try {
      return (await requestOrderById(id, requestData)).getData();
    } catch (err) {}
  }
  @Bind()
  orderRequestResult(order: IOrder | undefined) {
    this.setState({
      currentOrder: order,
      currentDialogStatus: order?.getOrderStatus()  || OrderStatus.None
    });
    if (order) {
      this.setState({
        orderServices: this.compileOrderServices(order),
      });

      this.requestStoragePointFromOrderServices(order);
      this.requestDirectionFromOrderServices(order);
      this.requestClientFromOrder(order);
      this.requestDiscountFromOrder(order);
    }
  }
  @Bind()
  async servicesRequest(): Promise<IService[]> {
    if (!this.state.currentOrder) {
      return [];
    }

    const token = await this.props.getToken();
    const requestData = new AuthorizedRequestData(token);

    try {
      return (
        await requestServicesByIds(
          this.state.currentOrder
            .getServices()
            .map((service: IOrderService) => {
              return service.getServiceId();
            }),
          requestData
        )
      ).getData();
    } catch (err) {
      return [];
    }
  }
  @Bind()
  servicesRequestResult(services: IService[]) {
    this.setState({
      selectedServiceModels: services,
    });
  }
  @Bind()
  async requestStoragePointFromOrderServices(order: IOrder) {
    const storagePointIds = order
      .getServices()
      .reduce((curIds: string[], service: IOrderService): string[] => {
        const spId = service.getStoragePointId();
        if (spId) {
          curIds.push(spId);
        }
        return curIds;
      }, []);

    if (order.getStoragePointFromId()) {
      storagePointIds.push(order.getStoragePointFromId()!);
    }
    if (order.getStoragePointToId()) {
      storagePointIds.push(order.getStoragePointToId()!);
    }

    const token = await this.props.getToken();
    const requestData = new AuthorizedRequestData(token);
    const storagePoints: IStoragePoint[] = (
      await requestStoragePointsByIds(storagePointIds, requestData)
    ).getData();

    const spHash = storagePoints.reduce(
      (
        curSPoints: IHash<IStoragePoint>,
        sPoint: IStoragePoint
      ): IHash<IStoragePoint> => {
        curSPoints[sPoint.getId()] = sPoint;
        return curSPoints;
      },
      {}
    );

    this.setState({ orderStoragePoints: spHash });
  }
  @Bind()
  async requestDirectionFromOrderServices(order: IOrder) {
    const directionIds = order
      .getServices()
      .reduce((curIds: string[], service: IOrderService): string[] => {
        const dirId = service.getDirectionId();
        if (dirId) {
          curIds.push(dirId);
        }
        return curIds;
      }, []);

    const token = await this.props.getToken();
    const requestData = new AuthorizedRequestData(token);
    const directions: IDirection[] = (
      await requestDirectionsByIds(directionIds, requestData)
    ).getData();

    const dirHash = directions.reduce(
      (
        curDirections: IHash<IDirection>,
        direction: IDirection
      ): IHash<IDirection> => {
        curDirections[direction.getId()] = direction;
        return curDirections;
      },
      {}
    );

    this.setState({ orderDirections: dirHash });
  }
  @Bind()
  async requestClientFromOrder(order: IOrder) {
    if (!order.getClientId() || order.getClientId() === "") {
      return;
    }

    const clientId = order.getClientId();
    const token = await this.props.getToken();
    const requestData = new AuthorizedRequestData(token);
    try {
      const client = (
        await requestClientById(clientId!, requestData)
      ).getData();
      this.setState({
        client,
      });
    } catch (err) {
      Logger.e(err);
      this.setState({
        client: undefined,
      });
    }
  }
  @Bind()
  async requestDiscountFromOrder(order: IOrder) {
    if (!order.getDiscountId() || order.getDiscountId() === "") {
      return;
    }
    const discountId = order.getDiscountId();
    const token = await this.props.getToken();
    const requestData = new AuthorizedRequestData(token);

    try {
      const discount = (
        await requestDiscountById(discountId!, requestData)
      ).getData();
      this.setState({
        orderDiscount: discount,
      });
    } catch (err) {
      Logger.e(err);
      this.setState({
        orderDiscount: undefined,
      });
    }
  }
  @Bind()
  completeOrderButtonHandler() {
    this.showChangeStatusDialog();
  }

  compileOrderServices(order: IOrder): IHash<IOrderService> {
    return order
      .getServices()
      .reduce(
        (
          curServices: IHash<IOrderService>,
          service: IOrderService
        ): IHash<IOrderService> => {
          curServices[service.getServiceId()] = service;
          return curServices;
        },
        {}
      );
  }

  renderImagesGalleria(images: string[]) {
    return images.map((image) => {
      return <ImageItem imageSrc={image} />;
    });
  }
  @Bind()
  serviceCellTemplate(service: IService) {
    const serviceId = service.getId();

    let serviceCost: number = 0;
    let count: number = 1;
    let totalPriceWithDiscount: number = 0;
    let totalPrice: number = 0;
    let photoUrls: string[] = [];

    let storagePointId: string | undefined = undefined;
    let directionId: string | undefined = undefined;
    let addressTo: IAddress | undefined = undefined;
    let addressFrom: IAddress | undefined = undefined;
    let duration: number | undefined = undefined;

    let serviceName: string = service.getName();

    if (this.state.orderServices && this.state.orderServices[serviceId]) {
      const orderService = this.state.orderServices[serviceId];
      totalPriceWithDiscount = orderService.getTotalWithDiscount();
      totalPrice = orderService.getTotal();
      serviceCost = orderService.getCost();
      count = orderService.getCount();
      photoUrls = orderService.getPhotoUrls();

      storagePointId = orderService.getStoragePointId();
      directionId = orderService.getDirectionId(); //TODO Запросить направление
      addressTo = orderService.getAddressTo();
      addressFrom = orderService.getAddressFrom();
      duration = orderService.getDuration();
    }

    return (
      <div className="order-service-list__cell">
        <div className="order-service-list__cell__info">
          <p className="order-service-list__cell__info__title">{serviceName}</p>
          <p className="order-service-list__cell__info__prop">
            Тип: {getServiceTypeTitle(service.getType())}
          </p>

          {!storagePointId ? (
            <></>
          ) : (
            <p className="order-service-list__cell__info__prop">
              Пункт хранения:{" "}
              {this.state.orderStoragePoints &&
              this.state.orderStoragePoints[storagePointId]
                ? this.state.orderStoragePoints[storagePointId].getName()
                : storagePointId}
            </p>
          )}

          {!directionId ? (
            <></>
          ) : (
            <p className="order-service-list__cell__info__prop">
              Направление:{" "}
              {this.state.orderDirections &&
              this.state.orderDirections[directionId]
                ? this.state.orderDirections[directionId].getName()
                : directionId}
            </p>
          )}

          {!addressFrom || addressFrom!.getFullAddress() === "" ? (
            <></>
          ) : (
            <p className="order-service-list__cell__info__prop">
              Адрес от: {addressFrom!.getFullAddress()}
            </p>
          )}

          {!addressTo || addressTo!.getFullAddress() === "" ? (
            <></>
          ) : (
            <p className="order-service-list__cell__info__prop">
              Адрес до: {addressTo!.getFullAddress()}
            </p>
          )}

          {!duration ? (
            <></>
          ) : (
            <p className="order-service-list__cell__info__prop">
              Продолжительность: {duration}
            </p>
          )}

          <p className="order-service-list__cell__info__prop">
            Цена за ед: {serviceCost}
          </p>
          <p className="order-service-list__cell__info__prop">
            Количество: {count}
          </p>
          <p className="order-service-list__cell__info__prop">
            Итоговая цена без скидки: {totalPrice}
          </p>
          <div className="order-service-list__cell__info__images">
            {this.renderImagesGalleria(photoUrls)}
          </div>
        </div>
        <div className="order-service-list__cell__price">
          {totalPriceWithDiscount}
        </div>
      </div>
    );
  }
  @Bind()
  renderStoragePointInfo() {
    if (!this.state.currentOrder) {
      return <></>;
    }

    const storagePointFromId = this.state.currentOrder.getStoragePointFromId();
    const storagePointToId = this.state.currentOrder.getStoragePointToId();

    if (
      !this.state.orderStoragePoints ||
      ((!storagePointFromId ||
        !this.state.orderStoragePoints[storagePointFromId]) &&
        (!storagePointToId || !this.state.orderStoragePoints[storagePointToId]))
    ) {
      return <></>;
    }

    const getStoragePointTypeTitle = (type: StoragePointType) => {
      switch (type) {
        case StoragePointType.StoragePoint:
          return "ПВЗ";
        case StoragePointType.Hotel:
          return "Отель";
      }
      return "";
    };

    let fromBlock = <></>;
    if (
      storagePointFromId &&
      this.state.orderStoragePoints &&
      this.state.orderStoragePoints[storagePointFromId]
    ) {
      const storagePoint = this.state.orderStoragePoints[storagePointFromId];
      fromBlock = (
        <>
          <div className="row m-1">
            <label className="col-md-4">
              Пункт хранения откуда сделан заказ:
            </label>
            <label className="col-md-4">{`${storagePoint.getName()} (${getStoragePointTypeTitle(
              storagePoint.getType()
            )})`}</label>
          </div>
          <div className="row m-1">
            <label className="col-md-4">Адрес пункта:</label>
            <label className="col-md-5">{`${storagePoint
              .getAddress()
              .getFullAddress()}`}</label>
          </div>
        </>
      );
    }

    let toBlock = <></>;
    if (
      storagePointToId &&
      this.state.orderStoragePoints &&
      this.state.orderStoragePoints[storagePointToId]
    ) {
      const storagePoint = this.state.orderStoragePoints[storagePointToId];
      toBlock = (
        <>
          <div className="row m-1">
            <label className="col-md-4">
              Пункт хранения куда сделан заказ:
            </label>
            <label className="col-md-4">{`${storagePoint.getName()} (${getStoragePointTypeTitle(
              storagePoint.getType()
            )})`}</label>
          </div>
          <div className="row m-1">
            <label className="col-md-4">Адрес пункта:</label>
            <label className="col-md-5">{`${storagePoint
              .getAddress()
              .getFullAddress()}`}</label>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="row mt-2">
          <div className="col-lg-12">
            <h5>ПВЗ</h5>
          </div>
        </div>
        <form
          className="card col-12 mt-2 p-2"
          onSubmit={(e: any) => {
            e.preventDefault();
          }}
        >
          <div className="row m-1" />
          {fromBlock}
          {toBlock}
          <div className="row m-1" />
        </form>
      </>
    );
  }
  @Bind()
  renderUserInfo() {
    if (!this.state.client) {
      return <></>;
    }
    const client = this.state.client;

    let fullnameBlock = <></>;
    let phoneNumberBlock = <></>;
    let emailBlock = <></>;
    let addressBlock = <></>;

    if (
      client.getFirstName().trim() !== "" ||
      client.getSecondName().trim() !== "" ||
      client.getThirdName().trim() !== ""
    ) {
      fullnameBlock = (
        <div className="row m-1">
          <label className="col-md-4">ФИО:</label>
          <label className="col-md-4">{`${client.getFirstName()} ${client.getSecondName()} ${client.getThirdName()}`}</label>
        </div>
      );
    }

    if (client.getPhone().trim() !== "") {
      phoneNumberBlock = (
        <div className="row m-1">
          <label className="col-md-4">Номер телефона:</label>
          <label className="col-md-4">{`${client.getPhone()}`}</label>
        </div>
      );
    }

    if (client.getEmail().trim() !== "") {
      emailBlock = (
        <div className="row m-1">
          <label className="col-md-4">Email:</label>
          <label className="col-md-4">{`${client.getEmail()}`}</label>
        </div>
      );
    }

    if (client.getAddress().getFullAddress().trim() !== "") {
      addressBlock = (
        <div className="row m-1">
          <label className="col-md-4">Адрес:</label>
          <label className="col-md-4">{`${client
            .getAddress()
            .getFullAddress()}`}</label>
        </div>
      );
    }

    return (
      <>
        {fullnameBlock}
        {phoneNumberBlock}
        {emailBlock}
        {addressBlock}
      </>
    );
  }
  @Bind()
  renderAddressFrom() {
    return !this.state.currentOrder ||
      !this.state.currentOrder.getAddressFrom() ||
      this.state.currentOrder.getAddressFrom()!.getFullAddress() === "" ? (
      <></>
    ) : (
      <div className="row m-1">
        <label className="col-md-4">Адрес от:</label>
        <label className="col-md-4">
          {this.state.currentOrder.getAddressFrom()!.getFullAddress()}
        </label>
      </div>
    );
  }
  @Bind()
  renderAddressTo() {
    return !this.state.currentOrder ||
      !this.state.currentOrder.getAddressTo() ||
      this.state.currentOrder.getAddressTo()!.getFullAddress() === "" ? (
      <></>
    ) : (
      <div className="row m-1">
        <label className="col-md-4">Адрес до:</label>
        <label className="col-md-4">
          {this.state.currentOrder.getAddressTo()!.getFullAddress()}
        </label>
      </div>
    );
  }
  @Bind()
  renderChangeStatusDialog() {
    return (
      <Dialog
        header="Изменение статуса"
        footer={this.renderDialogFooter()}
        visible={this.state.completeOrderDialog}
        style={{ width: "50vw" }}
        contentStyle={{ overflow: "visible" }}
        modal={true}
        onHide={() => {
          this.hideChangeStatusDialog();
        }}
      >
        Новый статус:{" "}
        <StatusPicker
          onChangeHandler={(status) => {
            this.setState({ currentDialogStatus: status });
          }}
          currentStatus={this.state.currentDialogStatus}
          availableStatus={this.state.availableStatus}
          style={{ width: "100%" }}
        />
      </Dialog>
    );
  }
  @Bind()
  renderDialogFooter() {
    return (
      <div>
        <Button
          label="Да"
          icon="pi pi-check"
          onClick={async () => {
            await this.sendCompleteOrderRequest();
            this.hideChangeStatusDialog();
          }}
        />
        <Button
          label="Отмена"
          icon="pi pi-times"
          onClick={() => this.hideChangeStatusDialog()}
        />
      </div>
    );
  }
  @Bind()
  hideChangeStatusDialog() {
    this.setState({ completeOrderDialog: false });
  }
  @Bind()
  showChangeStatusDialog() {
    this.setState({ completeOrderDialog: true });
  }
  @Bind()
  async sendCompleteOrderRequest() {
    if (this.state.currentOrder && this.state.currentOrder.getId()) {
      const orderId = this.state.currentOrder.getId();
      const token = await this.props.getToken();
      const requestData = new AuthorizedRequestData(token);
      const newStatus = this.state.currentDialogStatus;

      const isUpdated = await updateOrderStatus(
        orderId,
        newStatus,
        requestData
      );
      if (isUpdated) {
        this.tryToReloadContent();
        this.growl.show({
          severity: "success",
          summary: "Успех",
          detail: "Статус заказа обновлен",
        });
      } else {
        this.growl.show({
          severity: "error",
          summary: "Ошибка",
          detail: "Ошибка обновления заказа",
        });
      }
    } else {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Ошибка обновления заказа",
      });
    }
  }
  @Bind()
  tryToReloadContent() {
    if (this.loaderRef !== undefined) {
      this.loaderRef.tryToLoading();
    }
  }
  @Bind()
  renderDiscount() {
    if (!this.state.orderDiscount) {
      return <></>;
    }

    return (
      <div className="row m-1">
        <label className="col-md-4">Использованный промокод:</label>
        <label className="col-md-4">{this.state.orderDiscount.getCode()}</label>
      </div>
    );
  }

  @Bind()
  renderOrderRatings() {
    if (this.state.currentOrder && this.state.currentOrder.getRatings()) {
      if (
        this.state.currentOrder
          .getRatings()!
          .hasOwnProperty("serviceEvaluation") ||
        this.state.currentOrder.getRatings()!.hasOwnProperty("confirm")
      ) {
        return (
          <div className="row m-1">
            <label className="col-md-4">Пользовательская оценка:</label>
            <label
              className="col-md-4"
              style={{ display: "flex", alignItems: "center" }}
            >
              {this.state.currentOrder
                .getRatings()!
                .hasOwnProperty("serviceEvaluation")
                ? this.state.currentOrder.getRatings()!["serviceEvaluation"]
                : this.state.currentOrder.getRatings()!["confirm"]}
              <i className="pi pi-star-o" />
            </label>
          </div>
        );
      }
    }

    return <></>;
  }
  backButtonClick = () => {
    //@ts-ignore
    this.props.history.goBack()
  };
  render() {
    return (
      <>
        <Growl ref={(el) => (this.growl = el)} />
        {this.renderChangeStatusDialog()}
        <ContentLoaderComponent<IOrder | undefined>
          contentRequest={this.orderRequest}
          resultCallback={this.orderRequestResult}
          ref={(el) => {
            this.loaderRef = el;
          }}
        >
          <div className="row">
            <div className="col-lg-12">
              <h3>
                <span className="back-btn" onClick={this.backButtonClick}>
                  &#x2190;
                </span>
                {/* <BackButtonComponent/> Информация о заказе */}
                Информация о заказе
              </h3>
            </div>
          </div>
          <form
            className="card col-12 mt-2 p-2"
            onSubmit={(e: any) => {
              e.preventDefault();
            }}
          >
            <div className="row m-1">
              <label className="col-md-4">Статус</label>
              <label className="col-md-4">
                {this.state.currentOrder
                  ? getOrderStatusTitle(
                      this.state.currentOrder.getOrderStatus()
                    )
                  : ""}
                <span
                  className="order-detail__change_status_button"
                  onClick={this.completeOrderButtonHandler}
                >
                  {" "}
                  изменить статус
                </span>
              </label>
            </div>
            <div className="row m-1">
              <label className="col-md-4">Номер заказа</label>
              <label className="col-md-4">
                {this.state.currentOrder
                  ? this.state.currentOrder.getShortId()
                    ? this.state.currentOrder.getShortId()
                    : this.state.currentOrder.getId()
                  : ""}
              </label>
            </div>
            <div className="row m-1">
              <label className="col-md-4">Комментарий к заказу</label>
              <label className="col-md-4">
                {this.state.currentOrder
                  ? this.state.currentOrder.getDescription()
                  : ""}
              </label>
            </div>
          </form>
          <div className="row mt-2">
            <div className="col-lg-12">
              <h5>Данные пользователя</h5>
            </div>
          </div>
          <form
            className="card col-12 mt-2 p-2"
            onSubmit={(e: any) => {
              e.preventDefault();
            }}
          >
            {this.renderUserInfo()}
          </form>
          <div className="row mt-2">
            <div className="col-lg-12">
              <h5>Общая информация</h5>
            </div>
          </div>
          <form
            className="card col-12 mt-2 p-2"
            onSubmit={(e: any) => {
              e.preventDefault();
            }}
          >
            <div className="row m-1">
              <label className="col-md-4">Дата забора:</label>
              <label className="col-md-4">
                {this.state.currentOrder &&
                this.state.currentOrder.getPickupDate() !== undefined
                  ? moment(this.state.currentOrder.getPickupDate()).format('L г., HH:mm'): "нет данных"}
              </label>
            </div>
            <div className="row m-1">
              <label className="col-md-4">
                Ожидаемая дата вручения клиенту:
              </label>
              <label className="col-md-4">
                {this.state.currentOrder &&
                this.state.currentOrder.getEtaDate() !== undefined
                  ?moment(this.state.currentOrder.getEtaDate()).format('L г., HH:mm')
                  : "нет данных15"}
              </label>
            </div>
            {this.renderAddressFrom()}
            {this.renderAddressTo()}
            {this.renderOrderRatings()}
            {this.renderDiscount()}

            <div className="row m-1">
              <label className="col-md-4">Цена без скидки:</label>
              <label className="col-md-4">
                {this.state.currentOrder
                  ? this.state.currentOrder.getTotalPrice()
                  : ""}
              </label>
            </div>
            <div className="row m-1">
              <label className="col-md-4">Итоговая цена:</label>
              <label className="col-md-4">
                <b>
                  {this.state.currentOrder
                    ? this.state.currentOrder.getTotalPriceWithDiscount()
                    : ""}
                </b>
              </label>
            </div>
          </form>
          {this.renderStoragePointInfo()}
          <div className="row mt-2">
            <div className="col-lg-12">
              <h5>Услуги</h5>
            </div>
          </div>
          <form
            className="card col-12 mt-2 p-2"
            onSubmit={(e: any) => {
              e.preventDefault();
            }}
          >
            {!this.state.currentOrder ? (
              <></>
            ) : (
              <div className="row m-1">
                <ContentLoaderComponent<IService[]>
                  contentRequest={this.servicesRequest}
                  resultCallback={this.servicesRequestResult}
                >
                  <DataTable
                    className="order-service-list"
                    value={this.state.selectedServiceModels}
                  >
                    <Column body={this.serviceCellTemplate} />
                  </DataTable>
                </ContentLoaderComponent>
              </div>
            )}
          </form>
        </ContentLoaderComponent>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getToken: () => dispatch(getAccessToken()),
  };
};

export default withRouter( connect(null, mapDispatchToProps)(OrderDetailComponent));
