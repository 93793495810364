import React, {ChangeEvent} from "react";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import "./SearchComponentStyle.scss";
import {Bind} from "@shift-mono/common";

interface ISearchComponentProps extends React.HTMLProps<HTMLDivElement> {
    searchValue: string | undefined;
    searchCallback: (searchVal: string | undefined) => void;
    onChangeHandler: (searchVal: string | undefined) => void;
}

interface ISearchComponentState {
}

export class SearchComponent extends React.Component<ISearchComponentProps, ISearchComponentState> {
    @Bind()
    onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.onChangeHandler(event.target.value);
    }

    @Bind()
    onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.keyCode === 13) {
            this.search();
        }
    }

    @Bind()
    onSearchHandler() {
        this.search();
    }
//TODO: test
    @Bind()
    search() {
        this.props.searchCallback(this.props.searchValue);
    }

    render() {
        const {searchCallback, onChangeHandler, searchValue, ...otherProps} = this.props;
        return <div
            {...otherProps}
        >
            <div
                className="search_container"
            >
                <InputText
                    className="search_container__input"
                    placeholder={"Поиск"}
                    value={this.props.searchValue? this.props.searchValue: ""}
                    onChange={this.onChangeHandler}
                    onKeyDown={this.onKeyDownHandler}
                />
                <Button
                    type="button"
                    icon="pi pi-search"
                    className="p-button-info mr-3 search_container__button"
                    onClick={this.onSearchHandler}
                />
            </div>
        </div>
    }
}