import React from "react";
import {Growl} from "primereact/growl";
import ContentLoaderComponent from "../../../common/ContentLoaderComponent";
import {
    AuthorizedRequestData,
    Bind,
    IClient,
    putUserRequest,
    requestClientById, UserRequestData
} from "@shift-mono/common";
import BackButtonComponent from "../../../common/BackButtonComponent";
import {getAccessToken} from "../../../../helpers/RequestHelpers";
import {connect} from "react-redux";
import {Button} from "primereact/button";
import ClientStatusDropdown from "./partials/ClientStatusDropdown";
import ClientWithdrawalList from "./partials/ClientWithdrawalList";
import DeleteClientsTableComponent from "./DeleteClientsTableComponent";

interface IClientDetailComponentProps {
    getToken: () => Promise<string>;
    match: any;
}

interface IClientDetailComponentState {
    currentClient: IClient | undefined;
    isAgent: boolean;
}


class ClientDetailComponent extends React.Component<IClientDetailComponentProps, IClientDetailComponentState> {
    private growl: any = undefined;
    private loaderRef: any = undefined;

    constructor(props: IClientDetailComponentProps) {
        super(props);

        this.state = {
            currentClient: undefined,
            isAgent: false,
        }
    }

    @Bind()
    async clientRequest(): Promise<IClient | undefined> {
        const token = await this.props.getToken();
        const id = this.props.match.params.id;
        const requestData = new AuthorizedRequestData(token);

        try {
            return (await requestClientById(id, requestData)).getData();
        } catch (err) {
        }
    }

    @Bind()
    clientRequestResult(client: IClient | undefined) {
        const isAgent = client ? client.isAgent() : false;

        this.setState({
            currentClient: client,
            isAgent
        })
    }

    @Bind()
    saveButtonHandler() {
        this.updateUserAgentStatusRequest();
    }

    @Bind()
    async updateUserAgentStatusRequest() {
        try {
            const token = await this.props.getToken();
            const requestData = new UserRequestData({
                accessToken: token,
                isAgent: this.state.isAgent,
                id: this.state.currentClient!.getId()
            });
            if (await putUserRequest(requestData)) {
                this.growl.show({
                    severity: "success",
                    summary: "Успешно",
                    detail: "Статус обновлен"
                });
                this.reloadContent();
            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Ошибка обновления статуса"
                });
            }
        } catch (err) {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка обновления статуса"
            });
        }
    }

    @Bind()
    reloadContent() {
        if (this.loaderRef && this.loaderRef.tryToLoading) {
            this.loaderRef.tryToLoading();
        }
    }

    @Bind()
    renderUserInfo() {
        return (<>
                <div className="row m-1">
                    <label className="col-md-4">Телефон</label>
                    <label className="col-md-4">
                        {this.state.currentClient
                            ? this.getDefaultTitleIfEmpty(this.state.currentClient.getPhone())
                            : ""}
                    </label>
                </div>
                <div className="row m-1">
                    <label className="col-md-4">ФИО</label>
                    <label className="col-md-4">
                        {this.state.currentClient
                            ? this.getDefaultTitleIfEmpty(this.state.currentClient.getFullName())
                            : ""}
                    </label>
                </div>
                <div className="row m-1">
                    <label className="col-md-4">Email</label>
                    <label className="col-md-4">
                        {this.state.currentClient
                            ? this.getDefaultTitleIfEmpty(this.state.currentClient.getEmail())
                            : ""}
                    </label>
                </div>
            </>
        )
    }

    getDefaultTitleIfEmpty(value: string) {
        return value.trim() === "" ? "нет данных" : value;
    }

    @Bind()
    renderWithdrawalList() {
        if (!this.state.currentClient || !this.state.currentClient!.isAgent()) {
            return <></>;
        }

        const clientId = this.state.currentClient.getId();

        return (<>
                <div className="row m-1 mt-3">
                    <div className="col-12">
                        <h5>Заявки на вывод средств</h5>
                    </div>
                </div>
                <div className="row m-1">
                    <div className="col-md-12">
                        <ClientWithdrawalList
                            clientId={clientId}
                        />
                    </div>
                </div>
            </>
        )
    }

    render() {
        return (<>
            <Growl ref={el => (this.growl = el)}/>
            <ContentLoaderComponent<IClient | undefined>
                contentRequest={this.clientRequest}
                resultCallback={this.clientRequestResult}
                ref={el => {
                    this.loaderRef = el;
                }}
            >
                <div className="row">
                    <div className="col-lg-12">
                        <h3>
                            <BackButtonComponent/> Информация о клиенте
                        </h3>
                    </div>
                </div>
                <form
                    className="card col-12 mt-2 p-2"
                    onSubmit={(e: any) => {
                        e.preventDefault();
                    }}
                >
                    <div className="row m-1">
                        <label className="col-md-4">Статус</label>
                        <div className="col-md-5">
                            <ClientStatusDropdown
                                statusValue={this.state.isAgent}
                                changeStatusHandler={(agentStatus) => {
                                    this.setState({isAgent: agentStatus ? agentStatus : false})
                                }}
                                strictMode={true}
                            />
                        </div>
                    </div>
                    {this.renderUserInfo()}
                    {this.renderWithdrawalList()}
                    <div className="row m-1 ml-2">
                        <Button
                            label={"Сохранить"}
                            onClick={() => this.saveButtonHandler()}
                        />
                    </div>
                </form>
                <>
                <DeleteClientsTableComponent 
                title={'История удаления'}
                phoneNumber={this.state.currentClient?this.state.currentClient.getPhone():""} />
                </>
            </ContentLoaderComponent>
        </>)
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(ClientDetailComponent);