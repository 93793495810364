import React, { useState, useEffect, useMemo } from "react";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { getAccessToken } from "../../../helpers/RequestHelpers";
import { connect } from "react-redux";
import useQuery from "../../../hooks/useQuery";
import {
  FormGroup,
  FormLabel,
  TextField,
} from "@material-ui/core";

const SPACED_DATE_FORMAT = "DD.MM.YYYY";

function WithdrawTableComponent(props: any) {
  const { url, title } = props;
  const [data, setData] = useState<any>([]);
  const query = useQuery();
  const queryObject: any = query.getObject();
  const checkSearch = (object: { searchText: any }) => {
    return object?.searchText ? object?.searchText : "";
  };
  const searchText = useMemo(() => checkSearch(queryObject), [queryObject]);
  const checkPageSize = (object: { pageSize: any }) => {
    return object?.pageSize;
  };
  const pageSize = useMemo(() => checkPageSize(queryObject), [queryObject]);
  useEffect(() => {
    props.getToken();

    var url = "/v1/withdrawal";
    const accessToken = localStorage.getItem("accessToken");
    var headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    fetch(process.env.REACT_APP_API_DOMAIN + url + "?fromAdminPanel=true", {
      headers: headers,
    })
      .then((res) => res.json())
      .then((data) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].user || data[i].user !== undefined) {
            if (data[i].user.firstName !== undefined) {
              data[i].userName =
                data[i].user.firstName +
                " " +
                data[i].user.secondName +
                " " +
                data[i].user.thirdName;
              data[i].phoneNumber = data[i].user.phoneNumber;
              data[i].userId = data[i].user.id;
            } else {
              data[i].userName = "Нет данных";
              data[i].phoneNumber = "Нет данных";
            }
          } else {
            data[i].userName = "Нет данных";
            data[i].phoneNumber = "Нет данных";
          }
          let orderStatusRus: string = "";
          switch (data[i].complitted) {
            case false:
              orderStatusRus = "Нужно вывести";
              break;
            case true:
              orderStatusRus = "Завершенно";
              break;
          }
          var date = new Date(data[i].createdOn);
          const onlyDate = moment(date).format("MM.DD.YYYY");
          const onlyTime = moment(date).format("HH:mm");

          data[i].createdOn = onlyDate;
          data[i].onlyTime = onlyTime;
          data[i].orderStatus = orderStatusRus;

          //setImage("https://via.placeholder.com/150")
        }
        const gridData = data
          .map((row: { createdOn: any }) => {
            const updatedRow = {
              ...row,
              createdOn: moment(row.createdOn, "MM.DD.YYYY").toDate().getTime(),
            };
            return updatedRow;
          })
          .sort((a: any, b: any) => {
            return b.createdOn - a.createdOn;
          });

        setData(gridData);
      });
  }, [props]);

  function WithdrawAction(withdrawData: any) {
    props.getToken();

    var url = "/v1/withdrawal/";
    const accessToken = localStorage.getItem("accessToken");

    var headers = {
      "Content-Type": "application/json-patch+json",
      Authorization: `Bearer ${accessToken}`,
    };

    const params = {
      userId: withdrawData[7],
      amount: withdrawData[3],
      complitted: true,
      id: withdrawData[6],
    };
    const options = {
      method: "PATCH",
      body: JSON.stringify(params),
      headers: headers,
    };
    const api: string = process.env.REACT_APP_API_DOMAIN + url;
    fetch(api, options)
      .then((response) => response.json())
      .then((response) => {
        document.location.reload();
      });
  }

  function prepareData(url: string) {
    var columns = [
      {
        name: "orderStatus",
        label: "Статус Заявки",
        options: {
          filter: true,
          sort: true,
          filterList: queryObject.orderStatus ? [queryObject.orderStatus] : [],
        },
      },
      {
        name: "createdOn",
        label: "Дата заявки",
        options: {
          filter: true,
          sort: true,
          filterList: (() => {
            const list: any = [];
            if (queryObject.createdOnStart) {
              list[0] = queryObject.createdOnStart;
            }
            if (queryObject.createdOnEnd) {
              list[1] = queryObject.createdOnEnd;
            }
            return list;
          })(),
          //sortDirection: 'desc',
          customBodyRender: (value: string | number | Date) =>
            moment(new Date(value)).format(SPACED_DATE_FORMAT),
          filterType: "custom" as any,
          customFilterListOptions: {
            render: (v: any) => {
              if (v[0] && v[1]) {
                return [`Oт: ${v[0]}`, `До: ${v[1]}`];
              } else if (v[0]) {
                return `Oт: ${v[0]}`;
              } else if (v[1]) {
                return `До: ${v[1]}`;
              }
              return false;
            },
            update: (filterList: any, filterPos: any, index: any) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, "");
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
          filterOptions: {
            names: [],
            logic(date: moment.MomentInput, filters: any[]): boolean {
              const check: any = moment(date, "DD.MM.YYYY HH:mm");
              const from: any = moment(filters[0], "YYYY-MM-DD");
              const to: any = moment(filters[1], "YYYY-MM-DD");
              if (
                filters[0] &&
                filters[1] &&
                check.diff(to, "days") > 0 &&
                check.diff(from, "days") < 0
              ) {
                return true;
              } else if (filters[1] && check.diff(to, "days") > 0) {
                return true;
              } else if (filters[0] && check.diff(from, "days") < 0) {
                return true;
              }
              return false;
            },
            display: (
              filterList: { [x: string]: any },
              onChange: (arg0: any, arg1: any, arg2: any) => void,
              index: string | number,
              column: any
            ): JSX.Element => (
              <div>
                <FormLabel style={{ fontSize: 15 }}>Дата создания</FormLabel>
                <FormGroup row>
                  <TextField
                    id="startDate"
                    label="Дата начала"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][0] || ""}
                    onChange={(event) => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: "45%", marginRight: "5%", marginTop: "3%" }}
                  />
                  <TextField
                    id="endDate"
                    label="Дата окончания:"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][1] || ""}
                    onChange={(event) => {
                      filterList[index][1] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: "45%", marginRight: "5%", marginTop: "3%" }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "onlyTime",
        label: "Время заявки",
        options: {
          filter: true,
          sort: true,
          filterType: "custom" as any,
          filterList: (() => {
            const list: any = [];
            if (queryObject.onlyTimeStart) {
              list[0] = queryObject.onlyTimeStart;
            }
            if (queryObject.onlyTimeEnd) {
              list[1] = queryObject.onlyTimeEnd;
            }
            return list;
          })(),
          customFilterListOptions: {
            render: (v: any[]) => {
              if (v[0] && v[1]) {
                return [`Oт: ${v[0]}`, `До: ${v[1]}`];
              } else if (v[0]) {
                return `Oт: ${v[0]}`;
              } else if (v[1]) {
                return `До: ${v[1]}`;
              }
              return false;
            },
            update: (filterList: any, filterPos: any, index: any) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, "");
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
          filterOptions: {
            names: [],
            logic(date: moment.MomentInput, filters: any[]): boolean {
              const check: any = moment(date, "HH:mm");
              const from: any = moment(filters[0], "HH:mm");
              const to: any = moment(filters[1], "HH:mm");
              if (
                filters[0] &&
                filters[1] &&
                check.diff(to, "date") > 0 &&
                check.diff(from, "date") < 0
              ) {
                return true;
              } else if (filters[1] && check.diff(to, "date") > 0) {
                return true;
              } else if (filters[0] && check.diff(from, "date") < 0) {
                return true;
              }
              return false;
            },
            display: (
              filterList: { [x: string]: any },
              onChange: (arg0: any, arg1: any, arg2: any) => void,
              index: string | number,
              column: any
            ): JSX.Element => (
              <div>
                <FormLabel style={{ fontSize: 15 }}>Время заявки</FormLabel>
                <FormGroup row>
                  <TextField
                    id="startDate"
                    label="Время начала"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][0] || ""}
                    onChange={(event) => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: "45%", marginRight: "5%", marginTop: "3%" }}
                  />
                  <TextField
                    id="endDate"
                    label="Время окончания:"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][1] || ""}
                    onChange={(event) => {
                      filterList[index][1] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: "45%", marginRight: "5%", marginTop: "3%" }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "amount",
        label: "Сумма",
        options: {
          filter: true,
          sort: true,
          filterType: "custom" as any,
          filterList: (() => {
            const list: any = [];
            if (queryObject.amountMin) {
              list[0] = queryObject.amountMin;
            }
            if (queryObject.amountMax) {
              list[1] = queryObject.amountMax;
            }
            return list;
          })(),
          // filterList: [25, 50],
          customFilterListOptions: {
            render: (v: any[]) => {
              if (v[0] && v[1]) {
                return [`Oт: ${v[0]}`, `До: ${v[1]}`];
              } else if (v[0]) {
                return `Oт: ${v[0]}`;
              } else if (v[1]) {
                return `До: ${v[1]}`;
              }
              return false;
            },
            update: (filterList: any, filterPos: any, index: any) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, "");
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
          filterOptions: {
            names: [],
            logic(age: number, filters: number[]) {
              if (filters[0] && filters[1]) {
                return age < filters[0] || age > filters[1];
              } else if (filters[0]) {
                return age < filters[0];
              } else if (filters[1]) {
                return age > filters[1];
              }
              return false;
            },
            display: (
              filterList: { [x: string]: any },
              onChange: (arg0: any, arg1: any, arg2: any) => void,
              index: string | number,
              column: any
            ) => (
              <div>
                <FormLabel style={{ fontSize: 15 }}>Сумма</FormLabel>
                <FormGroup row>
                  <TextField
                    label="Oт какой"
                    type={"number"}
                    value={filterList[index][0] || ""}
                    onChange={(event) => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: "45%", marginRight: "5%" }}
                  />
                  <TextField
                    label="До какой"
                    type={"number"}
                    value={filterList[index][1] || ""}
                    onChange={(event) => {
                      filterList[index][1] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: "45%", marginRight: "5%" }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "phoneNumber",
        label: "Номер пользователя",
        options: {
          filter: true,
          sort: true,
          filterType: "textField",
          filterList: queryObject.phoneNumber ? [queryObject.phoneNumber] : [],
        },
      },
      {
        name: "userName",
        label: "Имя пользователя",
        options: {
          filter: true,
          sort: true,
          filterType: "textField",
          filterList: queryObject.userName ? [queryObject.userName] : [],
        },
      },
      {
        name: "id",
        label: "Id заявки",
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },
      {
        name: "userId",
        label: "Id пользователя",
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },
      {
        name: "actions",
        label: "Вывод",
        options: {
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
            return tableMeta.rowData[0] === "Завершенно" ? (
              "Завершенно"
            ) : (
              <button
                className="p-button p-component p-button-success p-button-text-only"
                onClick={() => WithdrawAction(tableMeta.rowData)}
              >
                <span className="p-button-text p-c">Подтвердить</span>
              </button>
            );
          },
        },
      },
    ];

    return columns;
  }

  var columns: any = prepareData(url);

  const options: any = {
    filter: true,
    //selectableRows: "multiple",
    filterType: "dropdown",
    responsive: "vertical",
    selectableRows: false,
    draggableColumns: {
      enabled: true,
    },
    searchText: searchText,

    rowsPerPage: pageSize || 100,
    // rowsPerPageOptions: [1, 10, 100],
    downloadOptions: {
      filename: "excel-format.csv",
      separator: ";",
      // utf8WithBom: true,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: (buildHead: (arg0: any) => string, buildBody: (arg0: any) => string, columns: any, data: any) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    onRowSelectionChange: () => {},
    onSearchChange: (searchText: any) => {
      if (searchText) {
        query.set("searchText", searchText.toString());
      } else {
        query.delete("searchText");
      }
      window.history.pushState(null, "", `?${query.toString()}`);
    },
    onChangePage: (numberRows: any) => {
      query.set("page", (numberRows + 1).toString());
      window.history.pushState(null, "", `?${query.toString()}`);
    },
    onChangeRowsPerPage: (num: any) => {
      query.set("pageSize", num.toString());
      window.history.pushState(null, "", `?${query.toString()}`);
    },

    onFilterChange(
      changedColumn: string,
      filterList: { [x: string]: string[] },
      type: any,
      changedColumnIndex: string | number
    ) {
      switch (changedColumn) {
        case "createdOn": {
          query.delete("createdOnStart");
          query.delete("createdOnEnd");

          if (filterList[changedColumnIndex][0]) {
            query.set("createdOnStart", filterList[changedColumnIndex][0]);
          }

          if (filterList[changedColumnIndex][1]) {
            query.set("createdOnEnd", filterList[changedColumnIndex][1]);
          }
          break;
        }
        case "onlyTime": {
          query.delete("onlyTimeStart");
          query.delete("onlyTimeOnEnd");

          if (filterList[changedColumnIndex][0]) {
            query.set("onlyTimeStart", filterList[changedColumnIndex][0]);
          }

          if (filterList[changedColumnIndex][1]) {
            query.set("onlyTimeEnd", filterList[changedColumnIndex][1]);
          }
          break;
        }
        case "amount": {
          query.delete("amountMin");
          query.delete("amountMax");

          if (filterList[changedColumnIndex][0]) {
            query.set("amountMin", filterList[changedColumnIndex][0]);
          }

          if (filterList[changedColumnIndex][1]) {
            query.set("amountMax", filterList[changedColumnIndex][1]);
          }
          break;
        }
        default:
          query.delete(changedColumn);
          if (filterList[changedColumnIndex][0]) {
            query.set(changedColumn, filterList[changedColumnIndex][0]);
          }
          break;
      }
      window.history.pushState(null, "", `?${query.toString()}`);
    },
    textLabels: {
      body: {
        noMatch: "К сожалению, подходящие записи не найдены",
        toolTip: 'Сортировать',
        columnHeaderTooltip: (column:any) => `Сортировать по ${column.label}`
      },
      pagination: {
        next: 'Следующая Страница',
        previous: 'предыдущий',
        rowsPerPage: 'Рядов на странице:',
        displayRows: 'из'
      },
      toolbar: {
        search: 'Поиск',
        downloadCsv: 'Скачать CSV',
        print: 'Распечатать',
        viewColumns: 'Просмотр столбцов',
        filterTable: 'Таблица фильтров'
      },
      filter: {
        all: 'Все',
        title: 'ФИЛЬТРЫ',
        reset: 'Сброс'
      },
      viewColumns: {
        title: 'Показать столбцы',
        titleAria: 'Показать/скрыть столбцы таблицы'
      },
      selectedRows: {
        text: 'строк(и) выбраны',
        delete: 'Удалить',
        deleteAria: 'Удалить выбранные строки'
      }
    }
  };

  return (
    <React.Fragment>
      {/* <img src = {image} alt='something'/>  */}
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={options}
      />
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getToken: () => dispatch(getAccessToken()),
  };
};

export default connect(null, mapDispatchToProps)(WithdrawTableComponent);
