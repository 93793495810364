"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NewsRequestData = void 0;
var AuthorizedRequestData_1 = require("./AuthorizedRequestData");
var NewsRequestData = /** @class */function (_super) {
  __extends(NewsRequestData, _super);
  function NewsRequestData(args) {
    var _this = _super.call(this, args.accessToken) || this;
    _this.arguments = args;
    return _this;
  }
  NewsRequestData.prototype.getData = function () {
    var result = {
      thumbnailImage: this.arguments.imageUrl,
      title: this.arguments.newsTitle,
      content: this.arguments.newsContent,
      tags: this.arguments.tags
    };
    if (this.arguments.newsId) {
      result = Object.assign({}, result, {
        id: this.arguments.newsId
      });
    }
    return Object.assign({}, _super.prototype.getData.call(this), result);
  };
  NewsRequestData.prototype.getId = function () {
    return this.arguments.newsId;
  };
  return NewsRequestData;
}(AuthorizedRequestData_1.AuthorizedRequestData);
exports.NewsRequestData = NewsRequestData;