import React from "react";
import PropTypes from "prop-types";

const MessageComponent = props => {
  const getTypeClass = type => {
    switch (type) {
      case "primary":
        return "text-primary";
      case "secondary":
        return "text-secondary";
      case "success":
        return "text-success";
      case "warning":
        return "text-warning";
      case "info":
        return "text-info";
      default:
        return "text-danger";
    }
  };

  return (
    <div className="msg-block w-100 text-center mt-4">
      <h4 className={getTypeClass(props.type)}>
        {props.message !== undefined ? props.message : "Error"}
      </h4>
    </div>
  );
};

MessageComponent.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string
};

export default MessageComponent;
