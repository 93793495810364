class OrderModel {
  readonly orderStatus: string;
  readonly createdOn: string;
  readonly onlyTime: string;
  readonly totalWithDiscount: string;
  readonly phoneNumber: string;
  readonly userName: string;
  readonly id: number;

  constructor({
    orderStatus,
    createdOn,
    onlyTime,
    totalWithDiscount,
    phoneNumber,
    userName,
    id,
  }:
  {
    orderStatus:string,
    createdOn:string,
    onlyTime:string,
    totalWithDiscount:string,
    phoneNumber:string,
    userName:string,
    id:number,
  }
  ) {
    this.orderStatus = orderStatus;
    this.createdOn = createdOn;
    this.onlyTime = onlyTime;
    this.totalWithDiscount = totalWithDiscount;
    this.phoneNumber = phoneNumber;
    this.userName = userName;
    this.phoneNumber = phoneNumber;
    this.id = id;
  }
}

export default OrderModel;
