import React from "react";
import {Growl} from "primereact/growl";
import {connect} from "react-redux";
import {getAccessToken} from "../../../helpers/RequestHelpers";
import {DiscountFormComponent, IDiscountFormData} from "./form_partials/DiscountFormComponent";
import {
    AuthorizedRequestData,
    Bind,
    DiscountRequestData,
    ICountableResponse,
    IUser,
    postDiscountRequest, requestAgents
} from "@shift-mono/common";
import ContentLoaderComponent from "../../common/ContentLoaderComponent";

interface IAddDiscountFormComponentProps {
    getToken: () => Promise<string>;
}

interface IAddDiscountFormComponentState {
    agents: IUser[];
}

class AddDiscountFormComponent extends React.Component<IAddDiscountFormComponentProps,
    IAddDiscountFormComponentState> {
    growl: any = undefined;
    formRef: any = undefined;
    defaultDate: Date = new Date();

    constructor(props: any) {
        super(props);
        this.defaultDate.setHours(0, 0, 0, 0);

        this.state = {
            agents: [],
        }
    }

    @Bind()
    clearForm() {
        if (this.formRef) {
            this.formRef.clearForm();
        }
    }

    @Bind()
    async sendDiscount(formData: IDiscountFormData) {
        const token = await this.props.getToken();
        const request_data = new DiscountRequestData({
            accessToken: token,
            promoCode: formData.promoCode,
            currencyValue: formData.currencyValue,
            currency: formData.currency,

            isLimited: formData.isLimited,
            limitPerAccount: formData.limitPerAccount,
            isFinite: formData.isFinite,
            startDate: formData.startDate,
            endDate: formData.endDate,
            referralId: formData.referralId,
            comment:formData.comment
        });
        try {
            const discountAdded = await postDiscountRequest(request_data);
            if (discountAdded) {
                this.clearForm();
                this.growl.show({
                    severity: "success",
                    summary: "Успешно",
                    detail: "Направление добавлено"
                });
            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Ошибка добавления"
                });
            }
        } catch (err) {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка добавления"
            });
        }
    }

    @Bind()
    async agentsRequest(): Promise<ICountableResponse<IUser[]>> {
        const token = await this.props.getToken();
        const requestData = new AuthorizedRequestData(token);

        return requestAgents(requestData);
    }

    @Bind()
    agentsRequestResult(agents: ICountableResponse<IUser[]>) {
        this.setState({
            agents: agents.getData()
        });
    }

    render() {
        return (
            <>
                <Growl ref={el => (this.growl = el)}/>
                <ContentLoaderComponent<ICountableResponse<IUser[]>>
                    contentRequest={this.agentsRequest}
                    resultCallback={this.agentsRequestResult}
                >
                    <DiscountFormComponent
                        availableAgents={this.state.agents}
                        saveHandler={(formData) => {
                            this.sendDiscount(formData);
                        }}
                        ref={el => {
                            this.formRef = el
                        }}
                    />
                </ContentLoaderComponent>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(AddDiscountFormComponent);
