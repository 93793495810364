import {combineReducers} from "redux";
import {entities} from "./entities";
import {component_state} from "./partitions/componentStateReducer";
import {user} from "./partitions/userReducer"
import {notification} from "./partitions/notificationReducer";

const RootReducer = combineReducers({
    entities,
    user,
    notification,
    component_state
});
export default RootReducer;
