import React, { useMemo, useState } from "react";
import { getAccessToken } from "../../../../helpers/RequestHelpers";
import { connect } from "react-redux";
import ContentLoaderComponent from "../../../common/ContentLoaderComponent";
import {
  AuthorizedRequestData,
  CountableResponse,
  ICountableResponse,
  IPartner,
  Logger,
  requestPartners,
} from "@shift-mono/common";
import { calcSkipAndLimit } from "../../../../helpers/PaginationHelpers";
import useQuery from "../../../../hooks/useQuery";
import PartnersTableComponent from "./PartnersTableComponent";

interface IPartnersListComponentProps {
  getToken: () => Promise<string>;
}

function PartnersListComponent(props: IPartnersListComponentProps) {
  const query = useQuery();
  const queryObject: any = query.getObject();
  const checkSearchText = (object: { searchText: any }) => {
    return object?.searchText;
  };
  const querySearchText = useMemo(() => checkSearchText(queryObject), [queryObject]);
  const [partnersCount, setPartnersCount] = useState<number>(0);
  const paginationListRows:number = 20;
  const searchVal :string | undefined= querySearchText;

  const getPartnersRequest = async () => {
    try {
      const token = await props.getToken();
      let currentPartnersCount = partnersCount;

      currentPartnersCount = await getPartnersCount();
      // }

      const startIndex = 0;
      let params = calcSkipAndLimit(
        startIndex,
        currentPartnersCount,
        paginationListRows
      );
      if (composeQueryRequestParams()) {
        let params = calcSkipAndLimit(0, 0, paginationListRows);
        params = Object.assign({}, params, composeQueryRequestParams());

        const requestData = new AuthorizedRequestData(token, {}, params);

        return requestPartners(requestData);
      }
      params = Object.assign({}, params, composeQueryRequestParams());

      const requestData = new AuthorizedRequestData(token, {}, params);

      return requestPartners(requestData);
    } catch (err) {
      return new CountableResponse<IPartner[]>([], 0);
    }
  };
  const composeQueryRequestParams = () => {
    const queryObjs = [];

    //   if (this.props.filterParams) {
    //     switch (this.props.filterParams!.agentStatus) {
    //       case true:
    //         queryObjs.push({ isAgent: true });
    //         break;
    //       case false:
    //         queryObjs.push({ $or: [{ isAgent: false }, { isAgent: null }] });
    //         break;
    //     }
    //   }

    if (searchVal) {
      const searchReg = searchVal
        .trim()
        .replace("+", "\\+")
        .split(" ")
        .join("|");
      queryObjs.push({
        $or: [
          { title: { $regex: searchReg, $options: "i" } },
          // { firstName: { $regex: searchReg, $options: "i" } },
          // { secondName: { $regex: searchReg, $options: "i" } },
          // { thirdName: { $regex: searchReg, $options: "i" } },
        ],
      });
    }

    const result = queryObjs.length > 0 ? { $and: queryObjs } : {};
    return { query: JSON.stringify(result) };
  };

  const partnersRequestResult = (partners: ICountableResponse<IPartner[]>) => {
    setPartnersCount(partners.getObjectsCount());
  };
  const getPartnersCount = async () => {
    const token = await props.getToken();
    const mockRequestData = new AuthorizedRequestData(
      token,
      {},
      {
        skip: 0,
        limit: 1,
      }
    );

    try {
      const partnersResponse = await requestPartners(mockRequestData);
      return partnersResponse.getObjectsCount();
    } catch (err) {
      return 0;
    }
  };

  return (
    <ContentLoaderComponent<ICountableResponse<IPartner[]>>
      contentRequest={getPartnersRequest}
      resultCallback={partnersRequestResult}
      errorCallback={(err) => {
        Logger.d(err);
      }}
      ref={(el) => {
       
      }}
    >
      <div className="row">
        <div className="col-lg-12 ">
          <div className="row">
            <div className="col-lg-12">
              <h3>Список партнеров</h3>
            </div>
          </div>
          <>
            <PartnersTableComponent />
          </>
          {/* <div className="row">
            <div className="col-lg-12">
              <SearchComponent
                style={{ marginBottom: "10px" }}
                searchValue={searchVal}
                onChangeHandler={(searchVal) => {
                  setSearchVal(searchVal);
                }}
                searchCallback={(searchVal) => {
                  setSearchVal(searchVal);
                  searchVal
                    ? query.set("searchText", searchVal)
                    : query.delete("searchText");
                  window.history.pushState(null, "", `?${query.toString()}`);
                  reloadContent();
                }}
              />
              <DataTable
                className="main_list_table mb-3"
                value={partners}
                paginator={true}
                totalRecords={partnersCount}
                rows={paginationListRows}
                first={firstPaginationIndex}
                lazy={true}
                loading={loading}
                onPage={onPage}
              >
                <Column
                  body={(client: IPartner) => {
                    return client.getTitle();
                  }}
                  header="Партнер"
                  // style={{width: "150px", textAlign: "center"}}
                />
                <Column
                  body={(client: IPartner) => {
                    return client.getId();
                  }}
                  header="Идентификатор"
                  // style={{width: "150px", textAlign: "center"}}
                />
                <Column body={actionTemplate} style={{ width: "4em" }} />
              </DataTable>
            </div>
          </div> */}
        </div>
      </div>
    </ContentLoaderComponent>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getToken: () => dispatch(getAccessToken()),
  };
};

export default connect(null, mapDispatchToProps)(PartnersListComponent);
