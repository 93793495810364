import React from "react";
import {connect} from "react-redux";
import {getAccessToken} from "../../../helpers/RequestHelpers";
import {Button} from "primereact/button";
import "./DiscountListComponentStyle.scss";
import {Link} from "react-router-dom";
import RouteURLs from "../../../actions/routesURL";
import {
    AuthorizedRequestData,
    requestDiscounts,
    deleteDiscountRequest,
    IDiscount,
    Currency, Bind
} from "@shift-mono/common";
import DiscountTableComponent from "./DiscountTableComponent";

interface IDiscountListComponentProps {
    getToken: () => Promise<string>;
}

interface IDiscountListComponentState {
    token: string | undefined;
    discounts: IDiscount[];
    delete_dialog_visible: boolean;
    deleting_discount_id: string | undefined;
}

class DiscountListComponent extends React.Component<IDiscountListComponentProps,
    IDiscountListComponentState> {
    private growl: any = undefined;
    private loaderRef: any = undefined;

    constructor(props: IDiscountListComponentProps) {
        super(props);
        this.state = {
            token: undefined,
            discounts: [],
            delete_dialog_visible: false,
            deleting_discount_id: undefined
        };
    }
    @Bind()
    formatDate(date: Date) {
        return new Date(date).toLocaleDateString();
    }
    @Bind()
    createdDateFormat(rowData: IDiscount) {
        return this.formatDate(rowData.getCreatedDt());
    }
    @Bind()
    async contentRequest() {
        const token = await this.props.getToken();
        this.setState({token: token});
        const request_data = new AuthorizedRequestData(token);
        try {
            return (await requestDiscounts(request_data)).getData();
        } catch (err) {
            return [];
        }
    }
    @Bind()
    requestResult(result: IDiscount[]) {
        this.setState({discounts: result});
    }

    showDeleteDialog(discount_id: string) {
        this.setState({
            delete_dialog_visible: true,
            deleting_discount_id: discount_id
        });
    }

    hideDeleteDialog() {
        this.setState({delete_dialog_visible: false});
    }
    @Bind()
    actionTemplate(rowData: IDiscount, column: any) {
        return (
            <>
                <Link to={RouteURLs.discounts + "/" + rowData.getId()}>
                    <Button
                        type="button"
                        icon="pi pi-pencil"
                        className="p-button-info mr-3"
                    />
                </Link>
                <Button
                    type="button"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={() => {
                        this.showDeleteDialog(rowData.getId());
                    }}
                />
            </>
        );
    }

    deleteDialogFooterTemplate = (
        //TODO Обновлять список скидок после удаления
        <div>
            <Button
                label="Да"
                icon="pi pi-check"
                onClick={async () => {
                    if (this.state.deleting_discount_id) {
                        await this.deleteDiscountRequest(this.state.deleting_discount_id);
                        this.setState({deleting_discount_id: undefined});
                    } else {
                        this.growl.show({
                            severity: "error",
                            summary: "Ошибка",
                            detail: "Ошибка удаления"
                        });
                    }

                    this.hideDeleteDialog();
                }}
            />
            <Button
                label="Нет"
                icon="pi pi-times"
                onClick={() => this.hideDeleteDialog()}
            />
        </div>
    );
    @Bind()
    async deleteDiscountRequest(discount_id: string) {
        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token);

        try {
            const resultStatus = await deleteDiscountRequest(discount_id, request_data);
            if (resultStatus) {
                this.growl.show({
                    severity: "success",
                    summary: "Успешно",
                    detail: "Скидка удалена"
                });

                if (this.loaderRef !== undefined) {
                    this.loaderRef.tryToLoading();
                }
            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Ошибка удаления"
                });
            }
        } catch (err) {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка удаления"
            });
        }
    }

    discountValueFieldTemplate(rowData: IDiscount) {
        const value = rowData.getValue();
        let currencySymbol = "";
        switch (rowData.getCurrency()) {
            case Currency.Rub:
                currencySymbol = "р";
                break;
            case Currency.Percent:
                currencySymbol = "%";
                break;
        }
        return value + "" + currencySymbol;
    }

    render() {
        return (
            <>
            <DiscountTableComponent/>
            </>
            
            // <div className="row">
            //     <ContentLoaderComponent<IDiscount[]>
            //         contentRequest={this.contentRequest}
            //         resultCallback={this.requestResult}
            //         ref={el => {
            //             this.loaderRef = el;
            //         }}
            //     >
            //         <Growl ref={el => (this.growl = el)}/>
            //         <Dialog
            //             header="Внимание!"
            //             footer={this.deleteDialogFooterTemplate}
            //             visible={this.state.delete_dialog_visible}
            //             style={{width: "50vw"}}
            //             modal={true}
            //             onHide={() => {
            //                 this.hideDeleteDialog();
            //             }}
            //         >
            //             Вы уверенны, что хотите удалить скидку?
            //         </Dialog>
            //         <div className="col-lg-12">
            //             <div className="row">
            //                 <div className="col-lg-12">
            //                     <h3>Список промо-кодов</h3>
            //                 </div>
            //             </div>
                        
            //             {/* <DataTable
            //                 className="main_list_table"
            //                 value={this.state.discounts}
            //                 paginator={true}
            //                 rows={10}
            //             >
            //                 <Column
            //                     header="Код"
            //                     body={(discount: IDiscount) => (discount.getCode())}
            //                 />
            //                 <Column
            //                     header="Дата создания"
            //                     body={this.createdDateFormat}
            //                 />
            //                 <Column body={this.discountValueFieldTemplate} header="Скидка" style={{width: "8em"}}/>
            //                 <Column body={this.actionTemplate} style={{width: "8em"}}/>
            //             </DataTable> */}
            //         </div>
            //     </ContentLoaderComponent>
            // </div>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(DiscountListComponent);
