import React, {Fragment} from "react";
import OrderListComponent from "./OrderListComponent";
import YaKassaOrderComponent from "./YaKassaOrderComponent";
import TabMenuComponent, {ITabItem} from "../../common/TabMenu/TabMenuComponent";

interface IOrderComponentProps {
}

interface IOrderComponentState {
    items: ITabItem[];
}

export default class OrderComponent extends React.Component<IOrderComponentProps,
    IOrderComponentState> {
    constructor(props: IOrderComponentProps) {
        super(props);

        this.state = {
            items: [
                {
                    id: 1,
                    label: "Список",
                    component: <OrderListComponent/>,
                },
                // {
                //     id: 2,
                //     label: "Добавить заказ",
                //     component: <AddOrderFormComponent/>,
                // },
                {
                    id: 3,
                    label: "Детали заказа Yandex кассы",
                    component: <YaKassaOrderComponent/>,
                }
            ],
        };

    }

    render() {
        return (
            <Fragment>
                <TabMenuComponent
                    items={this.state.items}
                />
            </Fragment>
        );
    }
}
