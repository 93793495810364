import React from "react";
import ContentLoaderComponent from "../../common/ContentLoaderComponent";
import {Growl} from "primereact/growl";
import {connect} from "react-redux";
import {getAccessToken} from "../../../helpers/RequestHelpers";
import {DiscountFormComponent, IDiscountFormData} from "./form_partials/DiscountFormComponent";
import {
    IDiscount,
    AuthorizedRequestData,
    requestDiscountById,
    DiscountRequestData,
    putDiscountRequest, Bind, IUser, ICountableResponse, requestAgents
} from "@shift-mono/common";

interface IDiscountDetailComponentProps {
    getToken: () => Promise<string>;
    match: any;
}

interface IDiscountDetailComponentState {
    currentDiscount: IDiscount | undefined;
    agents: IUser[];
}

class DiscountDetailComponent extends React.Component<IDiscountDetailComponentProps,
    IDiscountDetailComponentState> {
    growl: any = undefined;

    constructor(props: IDiscountDetailComponentProps) {
        super(props);

        this.state = {
            currentDiscount: undefined,
            agents: [],
        };
    }

    @Bind()
    async discountRequest(): Promise<IDiscount | undefined> {
        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token);
        const id = this.props.match.params.id;

        return (await requestDiscountById(id, request_data)).getData();
    }

    @Bind()
    discountRequestResult(discount: IDiscount | undefined) {
        this.setState({currentDiscount: discount});
    }

    @Bind()
    async sendDiscount(formData: IDiscountFormData) {
        const token = await this.props.getToken();
        if (
            this.state.currentDiscount === undefined
        ) {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка добавления"
            });
            return;
        }
        const id = this.state.currentDiscount.getId();
        const request_data = new DiscountRequestData({
            accessToken: token,
            promoCode: formData.promoCode,
            currencyValue: formData.currencyValue,
            currency: formData.currency,

            isLimited: formData.isLimited,
            limitPerAccount: formData.limitPerAccount,
            isFinite: formData.isFinite,
            startDate: formData.startDate,
            endDate: formData.endDate,
            referralId: formData.referralId,

            discountId: id,
            comment:formData.comment
        });
        try {
            const discountUpdated = await putDiscountRequest(request_data);
            if (discountUpdated) {
                this.growl.show({
                    severity: "success",
                    summary: "Успешно",
                    detail: "Скидка обновленна"
                });
            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Ошибка добавления"
                });
            }
        } catch (err) {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка добавления"
            });
        }
    }

    @Bind()
    async agentsRequest(): Promise<ICountableResponse<IUser[]>> {
        const token = await this.props.getToken();
        const requestData = new AuthorizedRequestData(token);

        return requestAgents(requestData);
    }

    @Bind()
    agentsRequestResult(agents: ICountableResponse<IUser[]>){
        this.setState({
            agents: agents.getData()
        });
    }
    backButtonClick = () => {
        //@ts-ignore
        this.props.history.goBack()
      }
    render() {
        return (
            <>
                <Growl ref={el => (this.growl = el)}/>
                <ContentLoaderComponent<IDiscount | undefined>
                    contentRequest={this.discountRequest}
                    resultCallback={this.discountRequestResult}
                >
                    <ContentLoaderComponent<ICountableResponse<IUser[]>>
                        contentRequest={this.agentsRequest}
                        resultCallback={this.agentsRequestResult}
                    >
                        <div className="row">
                            <div className="col-lg-12">
                            <h3>
                            <span className="back-btn" onClick={this.backButtonClick}>&#x2190;</span>
                                {/* <BackButtonComponent/> Информация о заказе */}
                                Информация о заказе
                            </h3>
                            </div>
                        </div>

                        <DiscountFormComponent
                            saveHandler={(formData) => {
                                this.sendDiscount(formData);
                            }}
                            currentDiscount={this.state.currentDiscount}
                            availableAgents={this.state.agents}
                        />
                    </ContentLoaderComponent>
                </ContentLoaderComponent>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(DiscountDetailComponent);
