import React, {Fragment} from "react";
import TabMenuComponent, {ITabItem} from "../../common/TabMenu/TabMenuComponent";
import PartnersListComponent from "./partners/PartnersListComponent";
import AddPartnerComponent from "./partners/AddPartnerComponent";
import ClientsComponent from "./clients/ClientsComponent";
import AccountsComponent from "./accounts/AccountsComponent";
import AddAccountComponent from "./accounts/AddAccountComponent";
import {AccountsListType} from "./accounts/AccountsListComponent";

interface IUsersComponentProps {
}

interface IUsersComponentState {
    items: ITabItem[];
}

export default class UsersComponent extends React.Component<IUsersComponentProps,
    IUsersComponentState> {
    constructor(props: IUsersComponentProps) {
        super(props);
        this.state = {
            items: [
                {
                    id: 1,
                    label: "Список клиентов",
                    component: <ClientsComponent/>,
                },
                {
                    id: 2,
                    label: "Список партнеров",
                    component: <PartnersListComponent/>,
                },
                {
                    id: 3,
                    label: "Добавить партнера",
                    component: <AddPartnerComponent/>,
                },
                {
                    id: 4,
                    label: "Аккаунты клиентов",
                    component: <AccountsComponent listType={AccountsListType.Clients} key={1}/>,
                },
                {
                    id: 5,
                    label: "B2b аккаунты",
                    component: <AccountsComponent listType={AccountsListType.B2b} key={2}/>,
                },
                {
                    id: 6,
                    label: "Добавить аккаунт",
                    component: <AddAccountComponent/>,
                },
            ],
        };
    }

    render() {
        return (
            <Fragment>
                <TabMenuComponent
                    items={this.state.items}
                />
            </Fragment>
        );
    }
}

