"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Partner = void 0;
var Model_1 = require("./Model");
var errors_1 = require("../errors");
var Partner = /** @class */function (_super) {
  __extends(Partner, _super);
  function Partner(args) {
    var _this = _super.call(this) || this;
    _this.id = args.id;
    _this.title = args.title;
    return _this;
  }
  Partner.fromJson = function (data) {
    var requiredProps = ["id", "title"];
    var verifyResult = Partner.verifyRequiredProperties(data, requiredProps);
    if (!verifyResult.isAllPropsExist) {
      throw new errors_1.MissingPropertiesError("Missing props: ".concat(verifyResult.missingProps.toString(), " in ").concat(this.constructor.name, " json data."));
    }
    return new Partner({
      id: data.id,
      title: data.title
    });
  };
  Partner.prototype.getId = function () {
    return this.id;
  };
  Partner.prototype.getTitle = function () {
    return this.title;
  };
  return Partner;
}(Model_1.Model);
exports.Partner = Partner;