export const STATUS_LIST = [
  {
    label: "Все",
    value: "",
  },
  {
    label: "Оплачен",
    value: "2",
  },

  {
    label: "Заказ ожидает оплаты",
    value: "1",
  },
  {
    label: "Ожидает сдачи багажа",
    value: "7",
  },
  {
    label: "Передано в доставку",
    value: "8",
  },
  {
    label: "Ожидает вручения",
    value: "6",
  },
  {
    label: "Завершен",
    value: "4",
  },
  {
    label: "Неизвестен",
    value: "9",
  },
  {
    label: "Отменен",
    value: "5",
  },
  {
    label: "Ошибка платежа",
    value: "11",
  },
  {
    label: "Хранение",
    value: "10",
  },
  {
    label: "Обрабатывается",
    value: "3",
  },
];
export const SORTED_LIST = [
  {
    label: "Датa создания",
    value: "0",
  },
  {
    label: "Cтатус",
    value: "1",
  },
  {
    label: "Цена",
    value: "2",
  },
];
