import React from "react";
import PageTemplate from "./PageTemplate";
import RouteURLs from "../actions/routesURL";
import { Route } from "react-router-dom";
import MobileAppSettingsComponent from "../components/content/mobile_app_settings/MobileAppSettingsComponent";

const MobileAppSettingsPage = () => {
  return (
    <PageTemplate>
      <Route
        exact
        path={RouteURLs.mobileAppSettings}
        component={MobileAppSettingsComponent}
      />
    </PageTemplate>
  );
};

export default MobileAppSettingsPage;
