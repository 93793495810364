import React from "react";
import {Dropdown} from 'primereact/dropdown';
import {IDestination} from "@shift-mono/common";

interface IDestinationPickerComponentProps extends React.HTMLAttributes<HTMLDivElement>{
    availableDestinations: IDestination[],
    changeHandler: (selectedDestination: IDestination) => void,
    currentDestinationId?: string,
}

interface IDestinationPickerComponentState {
}

class DestinationPickerComponent extends React.Component<IDestinationPickerComponentProps, IDestinationPickerComponentState> {
    render() {
        const current = this.props.availableDestinations
            .filter((destination) => {return destination.getId() === this.props.currentDestinationId})
            .pop()

        return <Dropdown
            className={this.props.className? this.props.className: ""}
            optionLabel={"title"}
            value={current}
            options={this.props.availableDestinations}
            onChange={(e) => {
                this.props.changeHandler(e.value);
            }}
            placeholder="Выберите город"/>
    }
}

export default DestinationPickerComponent;