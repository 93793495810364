import React from "react";
import {Dropdown} from "primereact/dropdown";
import {Bind, IUser} from "@shift-mono/common";

interface IAgentsDropdownProps extends React.HTMLAttributes<HTMLDivElement> {
    availableAgents: IUser[];
    changeHandler: (selectedAgent: any) => void;
    currentAgentId?: string;
    currentAgent: IUser | undefined
}
interface IAgentsDropdownState {
    currentAgent:any
}

interface DropdownItem {
    title: string,
    value: IUser | undefined,
    id: string,
}

export default class AgentsDropdown extends React.Component<IAgentsDropdownProps, IAgentsDropdownState> {
    constructor(props: IAgentsDropdownProps) {
        super(props)
        this.state = {
            currentAgent: props.currentAgent
        }
    }
    @Bind()
    getOptions(): DropdownItem[] {
        return [...this.props.availableAgents, undefined]
            .map((item) => {
                return this.getOptionItemFor(item)
            })
    }

    getOptionItemFor(agent: IUser | undefined) {
        return agent
            ? {title: this.getFullnameOrPhone(agent), value: agent, id: agent.getId()}
            : {title: "Нет Агента", value: agent, id: "-1"}
    }

    getFullnameOrPhone(agent: IUser): string {
        return agent.getFullName() !== "" ? agent.getFullName() : agent.getPhone();
    }
    componentWillReceiveProps(nextProps: Readonly<IAgentsDropdownProps>, nextContext: any): void {
        if (nextProps.currentAgent !== this.state.currentAgent) {
            this.setState({ currentAgent: nextProps.currentAgent })
        }
    }

    render() {
        return <Dropdown
            className={this.props.className ? this.props.className : ""}
            optionLabel={"title"}
            value={this.state.currentAgent??{title: "Нет Агента", value: this.state.currentAgent, id: "-1"}}
            options={this.getOptions()}
            onChange={(e) => {
                console.log("eeee",e.value)
                this.props.changeHandler(e.value);
            }}
            placeholder="Выберите партнера"/>
    }
}