import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { logoutUser } from "../../../actions/actionCreators";
import LogoutIcon from './logout.svg'

const LogoutComponent = (props: any) => {
  const [isLoggedOut, setLoggedOut] = useState(false);
  const logoutAction = () => {
    if (window.confirm("Точно выйти?")) {
      props.logoutDispatch();
      setLoggedOut(true);
    }
  };

  return (
    <div>
      {isLoggedOut ? <Redirect to="/auth" /> : ""}
      <img
        src={LogoutIcon}
        alt="Logout"
        className="inner-header__logout-logo"
        onClick={logoutAction}
      />
    </div>
  );
};

LogoutComponent.propTypes = {
  logoutDispatch: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logoutDispatch: () => {
      dispatch(logoutUser());
    }
  };
};

export default connect(null, mapDispatchToProps)(LogoutComponent);
