"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrderRequestData = exports.OrderServiceRequestData = void 0;
var AuthorizedRequestData_1 = require("./AuthorizedRequestData");
var OrderServiceRequestData = /** @class */function () {
  function OrderServiceRequestData(serviceId, count, storagePointId, directionId, addressFrom, addressTo, duration) {
    this.serviceId = serviceId;
    this.count = count;
    this.storagePointId = storagePointId;
    this.directionId = directionId;
    this.addressFrom = addressFrom;
    this.addressTo = addressTo;
    this.duration = duration;
  }
  return OrderServiceRequestData;
}();
exports.OrderServiceRequestData = OrderServiceRequestData;
var OrderRequestData = /** @class */function (_super) {
  __extends(OrderRequestData, _super);
  function OrderRequestData(accessToken, discount_id, storage_point_id, services) {
    var _this = _super.call(this, accessToken) || this;
    _this.accessToken = accessToken;
    _this.discount_id = discount_id;
    _this.storage_point_id = storage_point_id;
    _this.services = services;
    return _this;
  }
  OrderRequestData.prototype.getData = function () {
    return Object.assign({}, _super.prototype.getData.call(this), {
      services: this.services.map(function (item) {
        var data = {
          serviceId: item.serviceId,
          count: item.count,
          storagePointId: item.storagePointId
        };
        if (item.duration) {
          data = Object.assign({}, data, {
            duration: item.duration
          });
        }
        if (item.directionId) {
          data = Object.assign({}, data, {
            directionId: item.directionId
          });
        }
        if (item.addressTo) {
          data = Object.assign({}, data, {
            addressTo: {
              country: item.addressTo.getCountry(),
              city: item.addressTo.getCity(),
              street: item.addressTo.getStreet(),
              house: item.addressTo.getHouse(),
              flat: item.addressTo.getFlat(),
              zipCode: item.addressTo.getZipCode(),
              additional: item.addressTo.getAdditional()
            }
          });
        }
        if (item.addressFrom) {
          data = Object.assign({}, data, {
            addressFrom: {
              country: item.addressFrom.getCountry(),
              city: item.addressFrom.getCity(),
              street: item.addressFrom.getStreet(),
              house: item.addressFrom.getHouse(),
              flat: item.addressFrom.getFlat(),
              zipCode: item.addressFrom.getZipCode(),
              additional: item.addressFrom.getAdditional()
            }
          });
        }
        return data;
      }),
      discountId: this.discount_id
    });
  };
  return OrderRequestData;
}(AuthorizedRequestData_1.AuthorizedRequestData);
exports.OrderRequestData = OrderRequestData;