import React, {Fragment} from "react";
import AddStoragePointComponent from "./AddStoragePointComponent";
import StoragePointListComponent from "./StoragePointListComponent";
import TabMenuComponent, {ITabItem} from "../../common/TabMenu/TabMenuComponent";

interface IStoragePointComponentProps {
}

interface IStoragePointComponentState {
    items: ITabItem[];
}

export default class StoragePointComponent extends React.Component<IStoragePointComponentProps,
    IStoragePointComponentState> {
    constructor(props: IStoragePointComponentProps) {
        super(props);
        this.state = {
            items: [
                {
                    id: 1,
                    label: "Список",
                    component: <StoragePointListComponent/>,
                },
                {
                    id: 2,
                    label: "Добавить пункт хранения",
                    component: <AddStoragePointComponent/>,
                }
            ],
        };
    }

    render() {
        return (
            <Fragment>
                <TabMenuComponent
                    items={this.state.items}
                />
            </Fragment>
        );
    }
}
