import React from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

interface INewsFilterComponentProps {
  title: string;
  createdOnTo: string | null;
  createdOnFrom: string | null;
  sortDate:string
  callbackHandler: (value: {
    createdOnTo: string | null;
    createdOnFrom: string | null;
    title: string;
    sortDate:string
  }) => void;
}

interface INewsFilterComponentState {
  title: string;
  createdOnTo: Date | null;
  createdOnFrom: Date | null;
  sortDate:string
}

export default class NewsFilterComponent extends React.Component<
  INewsFilterComponentProps,
  INewsFilterComponentState
> {
  constructor(props: INewsFilterComponentProps) {
    super(props);

    this.state = {
      title: props.title,
      sortDate: props.sortDate,

      createdOnTo: props.createdOnTo ? new Date(props.createdOnTo) : null,
      createdOnFrom: props.createdOnFrom ? new Date(props.createdOnFrom) : null,
    };
  }

  render() {
    return (
      <>
        <form
          className="card mt-2 p-2"
          onSubmit={(e: any) => {
            e.preventDefault();
          }}
        >
          <div className="row m-1">
            <label className={"col-lg-5"}>
              <b>Фильтр</b>
            </label>
          </div>
          <div className="row m-1">
            <label className={"col-lg-3"}>Заглавие</label>
            <InputText
              value={this.state.title}
              className="col-lg-6"
              placeholder="Заглавие"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                this.setState({ title: e.target.value });
              }}
            />
          </div>
          <div className="row m-1">
            <label className={"col-lg-3"}>Дата создания</label>
          </div>
          <div className="row m-1">
            <label className={"col-lg-3"}>Oт</label>
            <div style={{ width: "50%" }}>
              <DatePicker
                selected={this.state.createdOnFrom}
                dateFormat="dd.MM.yyyy"
                onChange={(date: Date) =>
                  this.setState({ createdOnFrom: date })
                }
              />
            </div>
          </div>
          <div className="row m-1">
            <label className={"col-lg-3"}>До</label>
            <div style={{ width: "50%" }}>
              <DatePicker
                dateFormat="dd.MM.yyyy"
                selected={this.state.createdOnTo}
                onChange={(date: Date) => this.setState({ createdOnTo: date })}
              />
            </div>
          </div>
          <div className="row m-1">
            <label className={"col-lg-3"}>Сортировать по дате создания</label>
            <Button
              label=""
              icon={this.state.sortDate==="asc"? "pi pi-arrow-up":"pi pi-arrow-down"}
              className="cogIcon"
              onClick={(e) => {this.setState({sortDate:this.state.sortDate==="asc"?"desc":"asc"})}}
              style={{
                marginTop: ".25em",
                marginRight: ".25em",
                fontSize: "1em",
              }}
            />
          </div>
          <div className="row m-1 ml-2">
            <Button
              label={"Отфильтровать"}
              //disabled={!this.state.title && !this.state.createdOnFrom && !this.state.createdOnTo}
              onClick={() => {
                const value: {
                  createdOnTo: string | null;
                  createdOnFrom: string | null;
                  title: string;
                  sortDate:string;
                } = {
                  createdOnTo: this.state.createdOnTo
                    ? moment(this.state.createdOnTo).format("YYYY-MM-DD")
                    : null,
                  createdOnFrom: this.state.createdOnFrom
                    ? moment(this.state.createdOnFrom).format("YYYY-MM-DD")
                    : null,
                  title: this.state.title,
                  sortDate:this.state.sortDate
                };
                this.props.callbackHandler(value);
              }}
            />
          </div>
        </form>
      </>
    );
  }
}
