"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WithdrawalRequestData = void 0;
var AuthorizedRequestData_1 = require("./AuthorizedRequestData");
var WithdrawalRequestData = /** @class */function (_super) {
  __extends(WithdrawalRequestData, _super);
  function WithdrawalRequestData(args) {
    var _this = _super.call(this, args.accessToken) || this;
    _this.arguments = args;
    return _this;
  }
  WithdrawalRequestData.prototype.getData = function () {
    var result = {
      userId: this.arguments.userId,
      amount: this.arguments.amount,
      complitted: this.arguments.complitted
    };
    if (this.arguments.id) {
      result = Object.assign({}, result, {
        id: this.arguments.id
      });
    }
    return Object.assign({}, _super.prototype.getData.call(this), result);
  };
  WithdrawalRequestData.prototype.getId = function () {
    return this.arguments.id;
  };
  return WithdrawalRequestData;
}(AuthorizedRequestData_1.AuthorizedRequestData);
exports.WithdrawalRequestData = WithdrawalRequestData;