"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MonthStorageCost = void 0;
//TODO Наследовать от Model
//TODO Добавить конвертацию из json
var MonthStorageCost = /** @class */function () {
  function MonthStorageCost(monthCount, cost) {
    this.monthCount = monthCount;
    this.cost = cost;
  }
  MonthStorageCost.prototype.getCost = function () {
    return this.cost;
  };
  MonthStorageCost.prototype.getMonthCount = function () {
    return this.monthCount;
  };
  return MonthStorageCost;
}();
exports.MonthStorageCost = MonthStorageCost;