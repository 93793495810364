import C from "../../actions/actionsTypes";
import {IToken} from "@shift-mono/common";

export const user = (state: any = {}, action: any) => {
    const clearTokenDataFromLocalStorage = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("expiresDate");
    }

    switch (action.type) {
        case C.USER.LOGIN:
            const token: IToken = action.data.token;
            if (!token) {
                clearTokenDataFromLocalStorage()
                return {
                    is_authorized: false
                };
            }
            const expiresDate = Date.now() + (token.getExpiresIn() * 1000);
            localStorage.setItem("accessToken", token!.getAccessToken());
            localStorage.setItem("refreshToken", token!.getRefreshToken());
            localStorage.setItem("expiresDate", `${expiresDate}`);

            return {
                ...state,
                is_authorized: true,
                tokenType: token.getTokenType(),
                scope: token.getScope(),
            };
        case C.USER.LOGOUT:
            clearTokenDataFromLocalStorage();
            return {
                is_authorized: false
            };
        default:
            return state;
    }
};