import React from "react";
import PageTemplate from "./PageTemplate";
import { Route } from "react-router-dom";
import RouteURLs from "../actions/routesURL"
import StoragePointComponent from "../components/content/storage_point/StoragePointComponent";
import StoragePointDetailComponent from "../components/content/storage_point/StoragePointDetailComponent";

const StoragePointsPage = () => {
  return (
    <PageTemplate>
      <Route exact path={RouteURLs.storages} component={StoragePointComponent} />
      <Route path={RouteURLs.storages + "/:id"} component={StoragePointDetailComponent} />
    </PageTemplate>
  );
};

export default StoragePointsPage;
