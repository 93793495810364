import React from "react";
import {Growl} from "primereact/growl";
import {connect} from "react-redux";
import {isDevMode} from "../../helpers/AppHelpers";
import {addNotification} from "../../actions/creators/notificationCreators";
import {HubConnectionBuilder} from "@microsoft/signalr";
import RouteURLs from "../../actions/routesURL";

enum MessageTypes {
    Info = "info",
    Success = "success",
    Warning = "warn",
    Error = "error"
}

enum SignalMessageObjectType {
    Order = "order",
    Withdrawal = "withdrawal",
    None = "none",
}

interface IEventMessagesComponentProps {
    addNotification: (data: any) => void
}

interface IEventMessagesComponentState {
}

interface IMessageData {
    title: string,
    description: string,
    objectId: string,
    objectType: string
}

class EventMessagesComponent extends React.Component<IEventMessagesComponentProps,
    IEventMessagesComponentState> {
    private growl: any = undefined;
    private connection: any;

    constructor(props: IEventMessagesComponentProps) {
        super(props);
        this.connection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_DOMAIN + "/sgnl")
            .build();

        this.connection.start().then(() => {
            this.showSuccessConnectionMessage();
        });

        this.connection.on("Notify", (message: any) => {
            if (isDevMode()) {
                console.log("New SignalR Message");
                console.log(message)
            }
            if (this.isNewObjectOrderMessage(message) || this.isUpdateObjectOrderMessage(message)) {
                return;
            }

            this.changeTitleForWithdrawalMessage(message)

            const title = message.title ? message.title : "";
            const description = message.description ? message.description : "";
            const objectType = message.objectType ? message.objectType : "";
            const objectId = message.objectId ? message.objectId : undefined;

            const orderId = (this.getSignalMessageObjectType(objectType) === SignalMessageObjectType.Order) ? objectId : undefined

            this.showMessage(
                title,
                message.notificationType === 1 ? MessageTypes.Warning : MessageTypes.Info,
                orderId,
            );
            this.showNotification(title, description, orderId);
            this.props.addNotification(message);
        });

        document.addEventListener(EventMessagesComponent.AddMessageEventName, (e: any) => {
            this.props.addNotification(e.detail);
        })

        this.showMessage = this.showMessage.bind(this);
        this.showSuccessConnectionMessage = this.showSuccessConnectionMessage.bind(this);
    }

    getSignalMessageObjectType(typeStr: string): SignalMessageObjectType {
        switch (typeStr.toLowerCase()) {
            case SignalMessageObjectType.Order:
                return SignalMessageObjectType.Order;
            case SignalMessageObjectType.Withdrawal:
                return SignalMessageObjectType.Withdrawal;
            default:
                return SignalMessageObjectType.None;
        }
    }

    changeTitleForWithdrawalMessage(message: any) {
        const objectType = message.objectType ? message.objectType : "";
        if (this.getSignalMessageObjectType(objectType) === SignalMessageObjectType.Withdrawal) {
            message.title = "Новый запрос на списание средств";
        }
        return message;
    }

    isNewObjectOrderMessage(message: any): boolean {
        const objectType = message.objectType ? message.objectType : "";
        if (this.getSignalMessageObjectType(objectType) === SignalMessageObjectType.Order) {
            if (message.title && message.title.toLowerCase() === "объект создан") {
                return true;
            }
        }
        return false;
    }

    isUpdateObjectOrderMessage(message: any): boolean {
        const objectType = message.objectType ? message.objectType : "";
        if (this.getSignalMessageObjectType(objectType) === SignalMessageObjectType.Order) {
            if (message.title && message.title.toLowerCase() === "объект обновлен") {
                return true;
            }
        }
        return false;
    }

    static AddMessageEventName = "addMessage";
    static generateAddMessageEvent = (messageData: IMessageData): CustomEvent => {
        return new CustomEvent(EventMessagesComponent.AddMessageEventName, {"detail": messageData});
    }

    showSuccessConnectionMessage() {
        if (isDevMode()) {
            console.log("Successfully connected to SignalR");
        }
        // this.showMessage("Успешно", "Подключение к эвентам осуществлено", MessageTypes.Success);
    }

    showMessage(title: string = "", type: MessageTypes = MessageTypes.Info, orderId?: string) {

        this.growl.show({
            severity: type,
            summary: title,
            detail: orderId,
        });
    };

    requestNotificationPermission() {
        if (!('Notification' in window)) {
            console.log("This browser does not support notifications.");
        } else {
            if (Notification.permission === "default") {
                try {
                    Notification.requestPermission()
                } catch (e) {
                }
            }
        }
    }

    showNotification(notificationText: string, notificationDescription: string, orderId?: string) {
        if (Notification.permission === "granted") {
            const img = process.env.PUBLIC_URL + "/images/logo_128.png";
            const notification = new Notification(notificationText, {body: notificationDescription, icon: img})
            if (orderId) {
                notification.onclick = (event) => {
                    event.preventDefault();
                    window.open(RouteURLs.orders + "/" + orderId)
                }
            }
        }
    }

    render() {
        this.requestNotificationPermission()
        return (
            <>
                <Growl ref={el => (this.growl = el)} onClick={(message => {
                    if (!message.detail || typeof message.detail !== "string") {
                        return;
                    }

                    const orderId = message.detail;
                    const v4 = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
                    if (orderId.match(v4)) {
                        window.open(RouteURLs.orders + "/" + orderId)
                    }
                })
                }/>
            </>
        );
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        addNotification: (data: any) => {
            dispatch(addNotification(data))
        }
    }
};

export default connect(null, mapDispatchToProps)(EventMessagesComponent);