"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Shipment = void 0;
var Model_1 = require("./Model");
var Address_1 = require("./Address");
var ShipmentItem_1 = require("./ShipmentItem");
var errors_1 = require("../errors");
var Shipment = /** @class */function (_super) {
  __extends(Shipment, _super);
  function Shipment(id, orderId, shipmentItems, type, status, addressFrom, addressTo) {
    var _this = _super.call(this) || this;
    _this.id = id;
    _this.orderId = orderId;
    _this.shipmentItems = shipmentItems;
    _this.type = type;
    _this.status = status;
    _this.addressFrom = addressFrom;
    _this.addressTo = addressTo;
    return _this;
  }
  Shipment.fromJson = function (data) {
    var requiredProps = ["id"];
    var verifyResult = Shipment.verifyRequiredProperties(data, requiredProps);
    if (!verifyResult.isAllPropsExist) {
      throw new errors_1.MissingPropertiesError("Missing props: ".concat(verifyResult.missingProps.toString(), " in ").concat(this.constructor.name, " json data."));
    }
    var addressFrom = Address_1.Address.fromJson(data.hasOwnProperty("addressFrom") ? data.addressFrom : {});
    var addressTo = Address_1.Address.fromJson(data.hasOwnProperty("addressTo") ? data.addressTo : {});
    var shipmentItems = [];
    if (data.shipmentItems) {
      data.shipmentItems.map(function (item) {
        return new ShipmentItem_1.ShipmentItem(item.id ? item.id : "", item.bagId ? item.bagId : "");
      });
    }
    return new Shipment(data.id ? data.id : "", data.orderId ? data.orderId : "", shipmentItems, data.shipmentType ? data.shipmentType : 0, data.shipmentStatus ? data.shipmentStatus : 0, addressFrom, addressTo);
  };
  Shipment.prototype.getId = function () {
    return this.id;
  };
  Shipment.prototype.getOrderId = function () {
    return this.orderId;
  };
  Shipment.prototype.getShipmentItems = function () {
    return this.shipmentItems;
  };
  Shipment.prototype.getType = function () {
    return this.type;
  };
  Shipment.prototype.getStatus = function () {
    return this.status;
  };
  Shipment.prototype.getAddressFrom = function () {
    return this.addressFrom;
  };
  Shipment.prototype.getAddressTo = function () {
    return this.addressTo;
  };
  return Shipment;
}(Model_1.Model);
exports.Shipment = Shipment;