import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { getAccessToken } from "../../../../helpers/RequestHelpers";
import { connect } from "react-redux";
import useQuery from "../../../../hooks/useQuery";

function StoragePointTableComponent(props: any) {
  const { url, title } = props;
  const [data, setData] = useState<any>([]);
  const StoragePointIdsForDelete: any = [];
  const query = useQuery();
  const queryObject: any = query.getObject();
  const history = useHistory();

  const checkPageSize = (object: { pageSize: any }) => {
    return object?.pageSize;
  };
  const pageSize = useMemo(() => checkPageSize(queryObject), [queryObject]);
  const checkSearch = (object: { searchText: any }) => {
    return object?.searchText ? object?.searchText : "";
  };
  const searchText = useMemo(() => checkSearch(queryObject), [queryObject]);
  useEffect(() => {
    props.getToken();

    var url = "/v1/storagepoint";
    const accessToken = localStorage.getItem("accessToken");
    var headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    fetch(process.env.REACT_APP_API_DOMAIN + url + "?fromAdminPanel=true", {
      headers: headers,
    })
      .then((res) => {
        if (res.status === 401) {
          localStorage.clear();
          window.location.replace("/auth");
          throw(res);
        }
        return res
      })
      .then((res) => res.json())
      .then((data) => {
        for (let i = 0; i < data.length; i++) {
          const onlyDate = moment(data[i].createdOn).format("MM.DD.YYYY");
          data[i].createdOn = onlyDate;
          data[i].image = data[i].images[0];
          data[i].address =
            data[i].address.city +
            " " +
            data[i].address.street +
            " " +
            data[i].address.house;
          data[
            i
          ].cell = `Свободно / Всего ${data[i].freeCells} /${data[i].cells}`;
        }

        setData(data);
      })
        }, [props]);

  function prepareData(url: string) {
    var columns = [
      {
        name: "image",
        label: "Фото",

        options: {
          filter: false,
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
            return (
              <>
                <img
                  src={tableMeta.rowData[0]}
                  alt="пункт хранения"
                  style={{ height: "140px", width: "100px" }}
                ></img>
              </>
            );
          },
        },
      },
      {
        name: "name",
        label: "Название",
        options: {
          filter: true,
          sort: true,
          filterType: "textField",
          filterList: queryObject.name ? [queryObject.name] : [],
        },
      },
      {
        name: "address",
        label: "Адрес",
        options: {
          filter: true,
          sort: true,
          filterType: "textField",
          filterList: queryObject.address ? [queryObject.address] : [],
        },
      },
      {
        name: "cell",
        label: "Ячейки",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "createdOn",
        label: "Создан",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "id",
        label: "Id ПВЗ",
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },
    ];

    return columns;
  }

  var columns: any = prepareData(url);

  const options: any = {
    filter: true,
    selectableRows: "multiple",
    filterType: "dropdown",
    responsive: "vertical",
    searchText: searchText,
    page: parseInt(queryObject.page) - 1 || 0,

    draggableColumns: {
      enabled: true,
    },
    rowsPerPage: pageSize || 10,
    // rowsPerPageOptions: [1, 10, 100],
    downloadOptions: {
      filename: "excel-format.csv",
      separator: ";",
      // utf8WithBom: true,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: (
      buildHead: (arg0: any) => string,
      buildBody: (arg0: any) => string,
      columns: any,
      data: any
    ) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    onRowSelectionChange: (
      currentRowsSelected: any,
      allRows: any,
      rowsSelected: any
    ) => {
      rowsSelected.forEach((element: string | number) => {
        StoragePointIdsForDelete.push(data[element].id);
      });
    },
    onRowsDelete: (rowsDeleted: any) => {
      const uniqueStoragePointIds = StoragePointIdsForDelete.filter(
        (x: any, i: any, a: any) => a.indexOf(x) === i
      );
      const accessToken = localStorage.getItem("accessToken");
      var headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };
      const params = {
        storagePointIds: uniqueStoragePointIds,
      };
      const options = {
        method: "DELETE",
        body: JSON.stringify(params),
        headers: headers,
      };
      var url = "/v1/storagepoint";
      const api: string = process.env.REACT_APP_API_DOMAIN + url;
      fetch(api, options)
        .then((response) => response.json())
        .then((response) => {
          StoragePointIdsForDelete.splice(0, StoragePointIdsForDelete.length);
        });
    },
    onChangeRowsPerPage: (num: any) => {
      query.set("pageSize", num.toString());
      window.history.pushState(null, "", `?${query.toString()}`);
    },
    onChangePage: (numberRows: any) => {
      query.set("page", (numberRows + 1).toString());
      window.history.pushState(null, "", `?${query.toString()}`);
      //var element = document.querySelector("#pagination-rows")?.innerHTML
    },

    onFilterChange(
      changedColumn: string,
      filterList: { [x: string]: string[] },
      type: any,
      changedColumnIndex: string | number
    ) {
      query.delete(changedColumn);
      if (filterList[changedColumnIndex][0]) {
        query.set(changedColumn, filterList[changedColumnIndex][0]);
      }
      window.history.pushState(null, "", `?${query.toString()}`);
    },

    onRowClick: (rowData: any, rowState: any) => {
      const storagePointId = rowData[5];
      //const url = 'http://localhost:3000/spoints/'+storagePointId
      // const url = 'https://panel.skishift.com/spoints/'+storagePointId
      // const url = "http://testpanel.skishift.com/spoints/" + storagePointId;
      // let otherWindow: any = window.open();
      // otherWindow!.opener = null;
      // otherWindow!.location = url;
      //window.location.href = url
      history.push(`/spoints/${storagePointId}`);
    },
    onSearchChange: (searchText: any) => {
      if (searchText) {
        query.set("searchText", searchText.toString());
      } else {
        query.delete("searchText");
      }
      window.history.pushState(null, "", `?${query.toString()}`);
    },
  };

  return (
    <React.Fragment>
      {/* <img src = {image} alt='something'/>  */}
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={options}
      />
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getToken: () => dispatch(getAccessToken()),
  };
};

export default connect(null, mapDispatchToProps)(StoragePointTableComponent);
