import React from "react";
import {connect} from "react-redux";
import PartnerFormComponent, {IPartnerFormData} from "./form_partials/PartnerFormComponent";
import {
    postPartnerRequest,
    PartnerRequestData,
    Logger,
    Bind,
    ICountableResponse,
    IUser, AuthorizedRequestData, CountableResponse, requestAccounts
} from "@shift-mono/common";
import {Growl} from "primereact/growl";
import {getAccessToken} from "../../../../helpers/RequestHelpers";
import ContentLoaderComponent from "../../../common/ContentLoaderComponent";

interface IAddPartnerComponentProps {
    getToken: () => Promise<string>;
}

interface IAddPartnerComponentState {
    accounts: IUser[];
}

class AddPartnerComponent extends React.Component<IAddPartnerComponentProps, IAddPartnerComponentState> {
    private growl: any = undefined;
    private formRef: any = undefined;

    constructor(props: IAddPartnerComponentProps) {
        super(props);

        this.state = {
            accounts: []
        }
    }

    @Bind()
    validateForm(formData: IPartnerFormData): boolean {
        if(formData.title.trim() === ""){return false;}

        return true;
    }

    @Bind()
    async sendPartner(data: IPartnerFormData) {
        try {
            const token = await this.props.getToken();

            if (!this.validateForm(data)) {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Не все поля заполнены!"
                });
                return;
            }
            const requestData = new PartnerRequestData({
                accessToken: token,
                partnerTitle: data.title
            });

            const partnerAdded = await postPartnerRequest(requestData);
            if (partnerAdded) {

                this.growl.show({
                    severity: "success",
                    summary: "Успешно",
                    detail: "Партнер добавлено"
                });
                if (this.formRef) {
                    this.formRef.clearForm();
                }
            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Ошибка добавления"
                });
            }
        } catch (err) {
            Logger.d(err);
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка добавления"
            });
        }
    }

    @Bind()
    async getAccountsRequest(): Promise<ICountableResponse<IUser[]>> {
        try {
            const token = await this.props.getToken();
            const requestData = new AuthorizedRequestData(token);
            return requestAccounts(requestData);
        } catch (err) {
            return new CountableResponse<IUser[]>([], 0);
        }
    }

    @Bind()
    accountsRequestResult(result: ICountableResponse<IUser[]>) {
        this.setState({
            accounts: result.getData()
        })
    }

    render() {
        return <>
            <Growl ref={el => (this.growl = el)}/>
            <ContentLoaderComponent<ICountableResponse<IUser[]>>
                contentRequest={this.getAccountsRequest}
                resultCallback={this.accountsRequestResult}
                errorCallback={(err) => {
                    Logger.d(err);
                }}
            >
                <PartnerFormComponent
                    ref={el => (this.formRef = el)}
                    saveCallback={this.sendPartner}
                    availableAccounts={this.state.accounts}
                />
            </ContentLoaderComponent>
        </>;
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(AddPartnerComponent);